import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getSessionStoreValue} from "../app/useSessionStoreValue";
import {ProjectProductsModel, ProjectProductsExportModel} from "../data/Products";
import {BEACH_LENGTH} from "../data/VisualArea";

export interface ProjectStateExportModel {
    projectID: string;
    products: ProjectProductsExportModel[];
    beachLength: number;
}

export interface ProjectStateModel {
    projectID: string;
    products: ProjectProductsModel[];
    beachLength: number;
}

const defaultState: ProjectStateModel = {
    projectID: "",
    products: [],
    beachLength: BEACH_LENGTH
}

export const getInitState = (): ProjectStateModel => {
    let initState: ProjectStateModel = Object.assign({}, defaultState);
    let svalue = getSessionStoreValue();
    if (svalue === null) {
        return initState;
    }

    initState.projectID = svalue.projectID === undefined ? initState.projectID : svalue.projectID;
    initState.products = svalue.products === undefined ? initState.products : svalue.products;
    initState.beachLength = svalue.beachLength === undefined ? initState.beachLength : svalue.beachLength;

    return initState;
};

const initialState: ProjectStateModel = getInitState();

const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        initNewProject: () => defaultState,
        setProject: (state, action: PayloadAction<ProjectStateModel>) => {
            state.projectID = action.payload.projectID;
            state.products = action.payload.products;
        },
        setProjectID: (state, action: PayloadAction<string>) => {
            state.projectID = action.payload;
        },
        addProduct: (state, action: PayloadAction<ProjectProductsModel>) => {
            state.products.push(action.payload);
        },
        changeProduct: (state, action: PayloadAction<{ index: number, product: ProjectProductsModel }>) => {
            state.products[action.payload.index] = action.payload.product;
        },
        removeProduct: (state, action: PayloadAction<number>) => {
            state.products.splice(action.payload, 1);
        },
        setBeachLength: (state, action: PayloadAction<number>) => {
            state.beachLength = action.payload;
        }
    }
});

export const {
    initNewProject, setProject, setProjectID,
    addProduct, changeProduct, removeProduct,
    setBeachLength
} = projectSlice.actions;
export default projectSlice.reducer;
