import MenuBodyLeft from "./Left/MenuBodyLeft";
import MenuBodyRight from "./Right/MenuBodyRight";

import './MenuBody.scss';

export const MenuBody = () => {

    return (
            <div className="ams-menu-body">
                <MenuBodyLeft/>
                <MenuBodyRight/>
            </div>
            );
};

export default MenuBody;
