import {useEffect, useState, useRef} from 'react';
import {Provider, useDispatch, ReactReduxContext} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {Stage, Layer} from "react-konva";
import Konva from 'konva';
import VisualAreaGroup from "./Group/VisualAreaGroup";
import {
    setStageDimensions, setPaintDimensions, setPaintOffset,
    setScale, setScaleMin, setScaleMax,
    setGroupPosition,
    setBeachLength,
    initNewVisualArea,
    setToImageView, setOrgScale, setOrgGroupPosition
} from "../../features/visual-area-slice";
import {PointModel} from "../../models/Global";
import VisualAreaModalVariant from "./Modales/VisualAreaModalVariant";
import {DimensionsModel} from "../../models/App";
import {DIMENSIONS_DEFAULT} from "../../data/App";
import useTranslate from "../../utils/useTranslate"

import './VisualArea.scss';

export const VisualArea = () => {
    const dispatch = useDispatch();
    const {t} = useTranslate();
    const {dimensions, small, menuDimensions, footerDimensions, minMax} = useAppSelector((state) => state.app);
    const {products} = useAppSelector((state) => state.project);
    const [checkDimensions, setCheckDimensions] = useState<DimensionsModel>(DIMENSIONS_DEFAULT);
    const {
        stageDimensions, paintDimensions, paintOffset, absBGDimensions,
        scale, scaleMin, scaleMax, scaleByWheel,
        beachLength, groupPosition,
        toImageView, orgScale, orgGroupPosition
    } = useAppSelector((state) => state.visualArea);
    const projectBeachLength = useAppSelector((state) => state.project.beachLength);
    const stageRef = useRef<any>(null);
    const [toImageViewStarted, setToImageViewStarted] = useState<boolean>(false);
    const [overlayImage, setOverlayImage] = useState<string | null>(null);

    useEffect(() => {
        if (toImageView) {
            return;
        }
        if (paintDimensions.height <= 0 || scale !== null ||
                (minMax === null && products.length > 0)) {
            return;
        }
        let s = paintDimensions.height / 7500;
        if (minMax !== null && products.length > 0) {
            let sh = (paintDimensions.height / minMax.height) / 2;
            let sw = (paintDimensions.width / minMax.width) / 2;
            s = (sh < sw) ? sh : sw;
        }
        dispatch(setScale(s));
    }, [dispatch, paintDimensions, beachLength, scale, minMax, products, toImageView]);

    useEffect(() => {
        if (paintDimensions.height <= 0 || paintDimensions.width <= 0 || toImageView || toImageViewStarted) {
            return;
        }
        if (JSON.stringify(paintDimensions) !== JSON.stringify(checkDimensions)) {
            setCheckDimensions(paintDimensions);
            if (checkDimensions.height <= 0 || checkDimensions.width <= 0) {
                return;
            }
            setTimeout(function () {
                dispatch(initNewVisualArea());
            }, 200);
        }
    }, [dispatch, paintDimensions, checkDimensions, toImageView, toImageViewStarted]);

    useEffect(() => {
        if (toImageView) {
            if (minMax === null || orgScale !== null || orgGroupPosition !== null || toImageViewStarted || overlayImage === null) {
                return;
            }
            setToImageViewStarted(true);
            let calcOffset = 100;
            let scaleAbs = 0.1;
            let width = (minMax.width * scaleAbs) + (2 * calcOffset);
            let height = (minMax.height * scaleAbs) + (2 * calcOffset);
            if (stageDimensions.width !== width || stageDimensions.height !== height) {
                dispatch(setStageDimensions({width: width, height: height}));
            }
            if (paintDimensions.width !== width || paintDimensions.height !== height) {
                dispatch(setPaintDimensions({width: width, height: height}));
            }
            if (paintOffset.width !== 0 || paintDimensions.height !== 0) {
                dispatch(setPaintOffset({width: 0, height: 0}));
            }

            if (orgScale === null) {
                if (scaleMax < scaleAbs) {
                    dispatch(setScaleMax(scaleAbs));
                }
                dispatch(setOrgScale(scale));
                dispatch(setScale(scaleAbs));
            }

            if (orgGroupPosition === null) {
                dispatch(setOrgGroupPosition(groupPosition));
                dispatch(setGroupPosition(null));
            }

            return;
        }
        let width = dimensions.width;
        let height = dimensions.height;
        if (small) {
            height -= menuDimensions.height;
            if (height < dimensions.height / 2) {
                height = dimensions.height / 2;
            }
        }
        if (!small) {
            height -= footerDimensions.height;
        }
        if (stageDimensions.width !== width || stageDimensions.height !== height) {
            dispatch(setStageDimensions({width: width, height: height}));
        }

        let pwidth = width;
        let pheight = height;
        if (!small) {
            pwidth -= menuDimensions.width;
        }
        if (paintDimensions.width !== pwidth || paintDimensions.height !== pheight) {
            dispatch(setPaintDimensions({width: pwidth, height: pheight}));
        }

        let owidth = width - pwidth;
        let oheight = height - pheight;
        if (paintOffset.width !== owidth || paintDimensions.height !== oheight) {
            dispatch(setPaintOffset({width: owidth, height: oheight}));
        }
    }, [
        dispatch, dimensions, small, menuDimensions, paintDimensions, paintOffset.width,
        stageDimensions, footerDimensions.height, toImageView, minMax, scale, orgScale,
        groupPosition, orgGroupPosition, toImageViewStarted, overlayImage, scaleMax
    ]);

    useEffect(() => {
        if (toImageView) {
            return;
        }
        if (toImageViewStarted) {
            setTimeout(() => {
                setToImageViewStarted(false);
            });
        }
        if (overlayImage !== null && orgScale === null && orgGroupPosition === null) {
            setTimeout(() => {
                setOverlayImage(null);
            });
        }
        if (orgGroupPosition !== null && orgScale === null) {
            setTimeout(() => {
                dispatch(setOrgGroupPosition(null));
                dispatch(setGroupPosition(orgGroupPosition));
            });
        }
        if (orgScale !== null) {
            setTimeout(() => {
                dispatch(setOrgScale(null));
                dispatch(setScale(orgScale));;
            });
        }
    }, [toImageView, overlayImage, orgScale, orgGroupPosition, dispatch, toImageViewStarted]);

    useEffect(() => {
        if (toImageView) {
            return;
        }
        if (paintDimensions.width <= 0 || paintDimensions.height <= 0 ||
                absBGDimensions.width <= 0 || absBGDimensions.height <= 0) {
            return;
        }
        let swidth = paintDimensions.width / absBGDimensions.width;
        let sheight = paintDimensions.height / absBGDimensions.height;

        let newScaleMin = swidth > sheight ? swidth : sheight;
        if (scaleMin !== newScaleMin) {
            dispatch(setScaleMin(newScaleMin));
        }
        let smwidth = paintDimensions.width / 2500;
        let smheight = paintDimensions.height / 2500;

        let newScaleMax = smwidth < smheight ? smwidth : smheight;
        if (scaleMax !== newScaleMax) {
            dispatch(setScaleMax(newScaleMax));
        }
    }, [dispatch, paintDimensions, absBGDimensions, scaleMin, scaleMax, toImageView]);

    useEffect(() => {
        if (scale === null) {
            return;
        }
        let newScale = scale;
        if (scaleMin !== null && newScale < scaleMin) {
            newScale = scaleMin;
        }
        if (newScale > scaleMax) {
            newScale = scaleMax;
        }
        if (scale !== newScale) {
            dispatch(setScale(newScale));
        }
    }, [dispatch, scale, scaleMin, scaleMax]);

    useEffect(() => {
        if (projectBeachLength === beachLength) {
            return;
        }
        dispatch(setBeachLength(projectBeachLength));
    }, [dispatch, projectBeachLength, beachLength]);

    useEffect(() => {
        if (!toImageView || orgScale === null || orgGroupPosition === null) {
            return;
        }

        setTimeout(() => {
            if (stageRef.current === null) {
                let imageEvent1 = new CustomEvent('ams.sp.canvas.send_image', {detail: null});
                window.dispatchEvent(imageEvent1);
                return;
            }
            let dataURL = stageRef.current.toDataURL({ mimeType: 'image/jpeg', quality: 1 });
            let imageEvent2: CustomEvent<null> = new CustomEvent('ams.sp.canvas.send_image', {'detail': dataURL});
            window.dispatchEvent(imageEvent2);

            dispatch(setToImageView(false));
        }, 200);
    }, [toImageView, dispatch, orgGroupPosition, orgScale]);

    useEffect(() => {
        const handleNeedStageImage = () => {
            if (stageRef.current !== null) {
                let dataURL = stageRef.current.toDataURL();
                setOverlayImage(dataURL);
            }
            dispatch(setToImageView(true));
        };

        window.addEventListener('ams.sp.canvas.need_image', handleNeedStageImage);

        // cleanup this component
        return () => {
            window.removeEventListener('ams.sp.canvas.need_image', handleNeedStageImage);
        };
    }, [dispatch]);

    const handleWheel = (e: Konva.KonvaEventObject<WheelEvent>) => {
        if (toImageView) {
            return;
        }
        e.evt.preventDefault();

        const scaleBy = scaleByWheel;
        const stage = e.target.getStage();
        if (stage === null) {
            return;
        }
        const pointer = stage.getPointerPosition();
        if (pointer === null) {
            return;
        }
        if (scale === null) {
            return;
        }
        const oldScale = scale;

        // how to scale? Zoom in? Or zoom out?
        let direction = e.evt.deltaY > 0 ? 1 : -1;

        // when we zoom on trackpad, e.evt.ctrlKey is true
        // in that case lets revert direction
        if (e.evt.ctrlKey) {
          direction = -direction;
        }

        let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        if (scaleMin !== null && newScale < scaleMin) {
            newScale = scaleMin;
        }
        if (newScale > scaleMax) {
            newScale = scaleMax;
        }

        if (scale !== newScale) {
            dispatch(setScale(newScale));
        }

        if (groupPosition !== null) {
            let fullX = pointer.x - groupPosition.x;
            let fullY = pointer.y - groupPosition.y;
            let subPos: PointModel = {
                x: (fullX * newScale / oldScale) - fullX,
                y: (fullY * newScale / oldScale) - fullY
            };
            let newPos: PointModel = {
              x: groupPosition.x - subPos.x,
              y: groupPosition.y - subPos.y
            };
            dispatch(setGroupPosition(newPos));
        }
    };

    return (
            <>
                {
                    overlayImage !== null &&
                    <div className={'ams-visual-area'}>
                        <img src={overlayImage} alt={'overlayImage'} />
                    </div>
                }
                <div className={'ams-visual-area-container'}>
                    <ReactReduxContext.Consumer>
                        {
                            ({store}) => ([
                                    <Stage key="AMSVisualArea_Stage"
                                            width={stageDimensions.width}
                                            height={stageDimensions.height}
                                            onWheel={handleWheel}
                                            id="AMSVisualArea_Stage"
                                            className="ams-visual-area"
                                            style={{display: overlayImage !== null ? 'none' : undefined}}
                                            ref={stageRef}>
                                        <Provider store={store}>
                                            <Layer className="ams-visual-area-layer">
                                                <VisualAreaGroup />
                                            </Layer>
                                        </Provider>
                                    </Stage>
                                ])
                        }
                    </ReactReduxContext.Consumer>
                    {
                        products.length <= 0 &&
                        <div className={'ams-visual-area-start-info'}>
                            { t('Durch Klick auf + fügen Sie hier neue Elemente hinzu.') }
                        </div>
                    }
                </div>
                <VisualAreaModalVariant />
            </>
            );
};

export default VisualArea;
