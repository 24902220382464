import {useEffect} from "react";
import {useAppSelector} from "../../app/hooks";
import {useDispatch} from "react-redux";
import {setProductActive, setProductDraggable} from "../../features/visual-area-slice";

const ProductActiveDraggable = () => {
    const dispatch = useDispatch();
    const {project, visualArea} = useAppSelector((state) => state);

    useEffect(() => {
        if (visualArea.productActive === null) {
            return;
        }
        if (project.products[visualArea.productActive] === undefined) {
            dispatch(setProductActive(null));
        }
    }, [dispatch, project.products, visualArea.productActive]);
    useEffect(() => {
        if (visualArea.productDraggable === null) {
            return;
        }
        if (project.products[visualArea.productDraggable] === undefined) {
            dispatch(setProductDraggable(null));
        }
    }, [dispatch, project.products, visualArea.productDraggable]);

    return (null);
};

export default ProductActiveDraggable;
