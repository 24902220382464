import {useEffect} from "react";
import {useAppSelector} from "../../app/hooks";
import {setSessionStoreValue} from "../../app/useSessionStoreValue";

const SessionStoreValue = () => {
    const {project} = useAppSelector((state) => state);

    useEffect(() => {
        setSessionStoreValue(project);
    }, [project]);

    return (null);
};

export default SessionStoreValue;
