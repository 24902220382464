import React, {useState, useEffect} from 'react';
import {useAppSelector} from "../../../../app/hooks";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import useTranslate from "../../../../utils/useTranslate"

import './MenuItemSend.scss';

//const defaultValues: any = {
//    name: 'Sven Sonntag',
//    email: 'ssonntag@toolboxx.de',
//    tel: '0391 443751',
//    infos: '',
//    privacyPolicy: true
//};
const defaultValues: any = {
    name: '',
    email: '',
    tel: '',
    infos: '',
    privacyPolicy: false
};
const defaultChanged: any = {
    name: false,
    email: false,
    tel: false,
    infos: false,
    privacyPolicy: false
};

export const MenuItemSend = () => {
    const {apiPrefix} = useAppSelector((state) => state.app);
    const {t} = useTranslate();
    const [waitForImage, setWaitForImage] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isSendSuccess, setIsSendSuccess] = useState<boolean>(false);
    const [isSendError, setIsSendError] = useState<boolean>(false);
    const [values, setValues] = useState<any>(defaultValues);
    const [isChanged, setIsChanged] = useState<any>(defaultChanged);

    useEffect(() => {
        const handleSendStageImage = (event: any) => {
            if (!waitForImage) {
                return;
            }
            setWaitForImage(false);

            const recipeUrl = apiPrefix + 'api/send-mail/';

            let formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            if (!!event.detail) {
                formData.append('img_plan', event.detail);
            }

            const request = new XMLHttpRequest();
            request.open("POST", recipeUrl);

            const transferComplete = (event: ProgressEvent) => {
                setIsSending(false);
                try {
                    let response = JSON.parse(request.response);
                    if (response.result) {
                        setIsSendSuccess(true);
                    } else {
                        setIsSendError(true);
                    }
                } catch (e) {
                    setIsSendError(true);
                }
            };
            const transferFailed = (event: ProgressEvent) => {
                setIsSending(false);
                setIsSendError(true);
            };
            const transferCanceled = (event: ProgressEvent) => {
                setIsSending(false);
                setIsSendError(true);
            };
            request.addEventListener("error", transferFailed);
            request.addEventListener("abort", transferCanceled);
            request.addEventListener("loadend", transferComplete);

            request.send(formData);
        };
        window.addEventListener('ams.sp.canvas.send_image', handleSendStageImage);

        // cleanup this component
        return () => {
            window.removeEventListener('ams.sp.canvas.send_image', handleSendStageImage);
        };
    }, [waitForImage, apiPrefix, values]);

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        setIsSubmitted(true);
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setWaitForImage(true);

        setIsSending(true);
        let imageEvent = new Event('ams.sp.canvas.need_image');
        window.dispatchEvent(imageEvent);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.currentTarget;
        let iValue: string | boolean = input.value;
        switch (input.type) {
            case 'radio':
            case 'checkbox':
                iValue = input.checked;
                break;
        }
        if (values[input.name] === undefined || values[input.name] !== iValue) {
            let newValues: any = Object.assign({}, values);
            newValues[input.name] = iValue;
            setValues(newValues);
        }
        if (isChanged[input.name] === undefined || !isChanged[input.name]) {
            let newIsChanged: any = Object.assign({}, isChanged);
            newIsChanged[input.name] = true;
            setIsChanged(newIsChanged);
        }
    };

    const checkEmail = (email: string): boolean => {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            return false;
        }
        return true;
    };
    const checkTel = (tel: string): boolean => {
//        if (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i.test(tel)) {
        if (/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i.test(tel)) {
            return true;
        }
//        if (/^(\(?([\d \-\)\–\+\/\(]+)\)?([ .\-–\/]?)([\d]+))$/i.test(tel)) {
        if (/^(\(?([\d \-)–+/(]+)\)?([ .\-–/]?)([\d]+))$/i.test(tel)) {
            return true;
        }
        return false;
    };

    if (isSending) {
        return (
            <div className={'ams-sp-menu-item-send-result'}>
                <div>{t('Ihre Anfrage wird gesendet.')}</div>
            </div>
            );
    }
    if (isSendSuccess) {
        return (
            <div className={'ams-sp-menu-item-send-result'}>
                <div>{t('Ihre Anfrage wurde erfolgreich versendet.')}</div>
            </div>
            );
    }
    if (isSendError) {
        return (
            <div className={'ams-sp-menu-item-send-result'}>
                <div>{t('Beim Senden Ihrer Anfrage ist ein Fehler aufgetreten!\n\nBitte versuchen Sie es später noch einmal.')}</div>
            </div>
            );
    }
    return (
        <div className={'ams-sp-menu-item-send'}>

            <div className={'ams-sp-menu-item-send-header'}>
                {t('Senden Sie Ihrer Anfrage an uns.')}
            </div>

            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
                <Row className="mb-3">
                    <Form.Group controlId="ams-sp-menu-item-send-name">
                        <Form.Label>
                            {t('Name')}&nbsp;
                            <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip">{t('Bitte tragen Sie Ihren Namen ein.')}</Tooltip>}><sup>*</sup></OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder={t('Name')}
                            name="name"
                            value={values.name}
                            onChange={handleInputChange}
                            isInvalid={(isSubmitted || !!isChanged.name) && !values.name}
                        />
                        <Form.Control.Feedback type="invalid">{t('Bitte tragen Sie Ihren Namen ein.')}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="ams-sp-menu-item-send-email">
                        <Form.Label>
                            {t('E-Mail')}&nbsp;
                            <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip">{t('Bitte tragen Sie Ihre E-Mailadresse ein.')}</Tooltip>}><sup>*</sup></OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                            required
                            type="email"
                            placeholder={t('E-Mail')}
                            name="email"
                            value={values.email}
                            onChange={handleInputChange}
                            isInvalid={(isSubmitted || !!isChanged.email) && (!values.email || !checkEmail(values.email))}
                        />
                        <Form.Control.Feedback type="invalid">{t('Bitte tragen Sie Ihre E-Mailadresse ein.')}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="ams-sp-menu-item-send-email">
                        <Form.Label>
                            {t('Telefonnummer')}&nbsp;
                            <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip">{t('Bitte tragen Sie Ihre Telefonnummer ein.')}</Tooltip>}><sup>*</sup></OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                            required
                            type="tel"
                            placeholder={t('Telefonnummer')}
                            name="tel"
                            value={values.tel}
                            onChange={handleInputChange}
                            isInvalid={(isSubmitted || !!isChanged.tel) && (!values.tel || !checkTel(values.tel))}
                        />
                        <Form.Control.Feedback type="invalid">{t('Bitte tragen Sie Ihre Telefonnummer ein.')}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="ams-sp-menu-item-send-infos">
                        <Form.Label>
                            {t('Ihre Anmerkungen')}
                        </Form.Label>
                        <Form.Control
                            as="textarea" rows={5}
                            name="infos"
                            value={values.infos}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="ams-sp-menu-item-send-privacy-policy">
                        <Form.Check
                            required
                            type={'checkbox'}
                            name="privacyPolicy"
                            value={'1'}
                            onChange={handleInputChange}
                            defaultChecked={values.privacyPolicy}
                            label={
                                <>
                                    {t('Ich habe die Datenschutzbestimmungen gelesen und erkläre meine Zustimmung.')}&nbsp;
                                    <OverlayTrigger overlay={<Tooltip placement="top" className="in" id="tooltip">{t('Bitte lesen Sie die Datenschutzbestimmungen und bestätigen Sie diese.')}</Tooltip>}><sup>*</sup></OverlayTrigger>
                                </>
                            }
                            isInvalid={(isSubmitted || !!isChanged.privacyPolicy) && !values.privacyPolicy}
                            />
                        <Form.Control.Feedback type="invalid">{t('Bitte lesen Sie die Datenschutzbestimmungen und bestätigen Sie diese.')}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group controlId="ams-sp-menu-item-send-button">
                        <Button type="submit">{t('Anfrage senden')}</Button>
                    </Form.Group>
                </Row>
            </Form>
    </div>);
};

export default MenuItemSend;
