export const COLOR_1: string = '#708A93';
export const COLOR_2: string = '#7A8A93';
export const COLOR_3: string = '#B9B9B9';
export const COLOR_FONT_1: string = '#323335';
export const COLOR_EDIT_BORDER: string = '#7A8A93';
export const COLOR_EDIT_BG: string = '#7A8A9333';
export const COLOR_ARROW_HEIGHT: string = '#FFFFFF77';
export const COLOR_ARROW_HEIGHT_FONT: string = '#FFFFFF';
export const COLOR_ARROW_WIDTH: string = '#88888A77';
export const COLOR_ARROW_WIDTH_FONT: string = '#88888A';

interface ColorsModel {
    COLOR_1: string;
    COLOR_2: string;
    COLOR_3: string;
    COLOR_FONT_1: string;
    COLOR_EDIT_BORDER: string;
    COLOR_EDIT_BG: string;
    COLOR_ARROW_HEIGHT: string;
    COLOR_ARROW_HEIGHT_FONT: string;
    COLOR_ARROW_WIDTH: string;
    COLOR_ARROW_WIDTH_FONT: string;
};
export const COLORS: ColorsModel = {
    COLOR_1: COLOR_1,
    COLOR_2: COLOR_2,
    COLOR_3: COLOR_3,
    COLOR_FONT_1: COLOR_FONT_1,
    COLOR_EDIT_BORDER: COLOR_EDIT_BORDER,
    COLOR_EDIT_BG: COLOR_EDIT_BG,
    COLOR_ARROW_HEIGHT: COLOR_ARROW_HEIGHT,
    COLOR_ARROW_HEIGHT_FONT: COLOR_ARROW_HEIGHT_FONT,
    COLOR_ARROW_WIDTH: COLOR_ARROW_WIDTH,
    COLOR_ARROW_WIDTH_FONT: COLOR_ARROW_WIDTH_FONT
};


interface ConstantsModel {
    COLORS: ColorsModel;
};
export const CONSTANTS: ConstantsModel = {
    COLORS: COLORS
};
