import {useState, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {initNewApp, setFooterDimensions, menuSteps, MenuTypes, setCurrentMenu, setPre3dMenu, menuActive} from "../../features/app-slice";
import {initNewProject} from "../../features/project-slice";
import {initNewVisualArea, setScale, setGroupPosition} from "../../features/visual-area-slice";
import useRefDimensions, {UseRefDimensionsModel} from "../../utils/useRefDimensions";
import {PointModel} from "../../models/Global";
import Button from 'react-bootstrap/Button';
import {ZoomOut as BSIconZoomOut, ZoomIn as BSIconZoomIn, Trash as BSIconTrash} from "react-bootstrap-icons";
import useTranslate from "../../utils/useTranslate"
import {ImprintDataProtect} from "../shared/ImprintDataProtect/ImprintDataProtect"

import './Footer.scss';

export const Footer = () => {
    const dispatch = useDispatch();
    const refDimensions: UseRefDimensionsModel = useRefDimensions();
    const {visualArea, app, project} = useAppSelector((state) => state);
    const {t} = useTranslate();
    const[menuBack, setMenuBack] = useState<MenuTypes | null>(null);
    const[menuNext, setMenuNext] = useState<MenuTypes | null>(null);

    useEffect(() => {
        if (refDimensions.dimensions === undefined) {
            return;
        }
        if (refDimensions.dimensions.width === undefined || refDimensions.dimensions.width <= 0 ||
            refDimensions.dimensions.height === undefined || refDimensions.dimensions.height <= 0) {
            return;
        }
        dispatch(setFooterDimensions(refDimensions.dimensions));
    }, [dispatch, app.footerDimensions, refDimensions.dimensions]);

    useEffect(() => {
        let newMenuBack: MenuTypes | null = null;
        let newMenuNext: MenuTypes | null = null;
        let lastMenu: MenuTypes | null = null;
        let menuFound = false;

        menuSteps.forEach(function (menu) {
            if (!menuActive(menu, project)) {
                return null;
            }
            if (menuFound && newMenuNext === null) {
                newMenuNext = menu;
            }
            if (menu === app.currentMenu) {
                newMenuBack = lastMenu;
                menuFound = true;
            }
            lastMenu = menu;
        });

        if (newMenuBack !== menuBack) {
            setMenuBack(newMenuBack);
        }
        if (newMenuNext !== menuNext) {
            setMenuNext(newMenuNext);
        }
    }, [app.currentMenu, menuBack, menuNext, project]);

    const zoomBy = (scaleBy: number) => {

        if (visualArea.scale === null) {
            return;
        }
        const oldScale = visualArea.scale;

        let newScale = oldScale * scaleBy;

        if (visualArea.scaleMin !== null && newScale < visualArea.scaleMin) {
            newScale = visualArea.scaleMin;
        }
        if (newScale > visualArea.scaleMax) {
            newScale = visualArea.scaleMax;
        }

        if (visualArea.scale !== newScale) {
            dispatch(setScale(newScale));
        }

        if (visualArea.groupPosition !== null) {
            let pointer: PointModel = {
                x: visualArea.paintDimensions.width / 2,
                y: visualArea.paintDimensions.height / 2
            };
            let fullX = pointer.x - visualArea.groupPosition.x;
            let fullY = pointer.y - visualArea.groupPosition.y;
            let subPos: PointModel = {
                x: (fullX * newScale / oldScale) - fullX,
                y: (fullY * newScale / oldScale) - fullY
            };
            let newPos: PointModel = {
              x: visualArea.groupPosition.x - subPos.x,
              y: visualArea.groupPosition.y - subPos.y
            };
            dispatch(setGroupPosition(newPos));
        }
    };

    const handleZoomOut = () => {
        zoomBy(1 / visualArea.scaleByButton);
    };

    const handleZoomIn = () => {
        zoomBy(visualArea.scaleByButton);
    };

    const handleReset = () => {
        dispatch(initNewProject());
        dispatch(initNewApp());
        dispatch(initNewVisualArea());
    };

    const handleBack = () => {
        if (menuBack === null) {
            return;
        }
        dispatch(setCurrentMenu(menuBack));
    };

    const handleNext = () => {
        if (menuNext === null) {
            return;
        }
        dispatch(setCurrentMenu(menuNext));
    };

    const handle3DView = () => {
        if (app.currentMenu === MenuTypes.VIEW_3D) {
            dispatch(setCurrentMenu(app.pre3dMenu !== null ? app.pre3dMenu : MenuTypes.MODULE));
            dispatch(setPre3dMenu(null));
            return;
        }

        dispatch(setPre3dMenu(app.currentMenu));
        dispatch(setCurrentMenu(MenuTypes.VIEW_3D));
    };

    return (
            <div ref={refDimensions.ref} className="ams-footer">
                <div className="ams-footer-container">
                    <div className="ams-footer-zoom">
                        <Button variant="primary" onClick={handleZoomOut}>
                            <BSIconZoomOut />
                        </Button>
                        <Button variant="primary" onClick={handleZoomIn}>
                            <BSIconZoomIn />
                        </Button>
                        <Button variant="primary" onClick={handleReset}>
                            <BSIconTrash />
                        </Button>
                    </div>
                    <div className="ams-footer-3d-ansicht">
                        {
                            (menuActive(MenuTypes.VIEW_3D, project)) &&
                            <Button variant="primary" onClick={handle3DView}>
                                { t('3D Ansicht') }
                            </Button>
                        }
                    </div>
                    <div className="ams-footer-back-next" style={{width: app.menuRightDimensions.width}}>
                        <Button variant="primary" onClick={handleBack} disabled={menuBack === null}>
                            {t('Zurück')}
                        </Button>
                        <Button variant="primary" onClick={handleNext} disabled={menuNext === null}>
                            {t('Weiter')}
                        </Button>
                    </div>
                    {
                        app.small &&
                        <div className="ams-footer-imprint" style={{width: app.menuRightDimensions.width}}>
                            <div>
                                <ImprintDataProtect />
                            </div>
                        </div>
                    }
                </div>
            </div>
            );
};

export default Footer;
