import {CSSProperties, useEffect} from 'react';
import {useAppSelector} from "../../../../app/hooks";
import { useDispatch } from "react-redux";
import {afterStartMenu, setCurrentMenu} from "../../../../features/app-slice";
import Button from 'react-bootstrap/Button';
import useTranslate from "../../../../utils/useTranslate"
import amsLogoPng from "../../../../assets/menu/ams_logo.png";
import ankerPng from "../../../../assets/menu/anker.png";

import './MenuItemStart.scss';

export const MenuItemStart = () => {
    const dispatch = useDispatch();
    const {project} = useAppSelector((state) => state);
    const {t} = useTranslate();

    useEffect(() => {
        if (project.products.length <= 0) {
            return;
        }
        dispatch(setCurrentMenu(afterStartMenu));
    }, [dispatch, project.products]);

    const handleClose = () => {
        dispatch(setCurrentMenu(afterStartMenu));
    };

    return (
            <div className={'ams-sp-menu-item-start'}>
                <div className={'ams-sp-menu-item-start-top'}
                        style={{
                            "--image-background": `url(${amsLogoPng})`
                        } as CSSProperties}>
                    <div className={'ams-sp-menu-item-start-top-bg'}
                            style={{
                                "--image-background": `url(${ankerPng})`
                            } as CSSProperties}></div>
                </div>
                <div className={'ams-sp-menu-item-start-bottom'}>
                    <Button variant="primary" onClick={handleClose}>
                        {t('Planung starten')}
                    </Button>
                </div>
            </div>
            );
};

export default MenuItemStart;
