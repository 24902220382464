import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DimensionsModel, MinMaxProps} from "../models/App";
import {DIMENSIONS_DEFAULT} from "../data/App";
import {isTouchDevice} from "../utils/global";
import {ProjectStateModel} from "./project-slice";
import { isWebGLAvailable, isWebGL2Available } from "three-stdlib";

export enum MenuTypes {
    START,
    SETTINGS,
    MODULE,
    BOAT_LIFT,
    ACCESSORIES,
    MATERIAL_LIST,
    HELP,
    SEND,
    VIEW_3D
};

export const afterStartMenu: MenuTypes = MenuTypes.MODULE;

export const menuSteps: Array<MenuTypes> = [
    MenuTypes.MODULE,
    MenuTypes.BOAT_LIFT,
    MenuTypes.ACCESSORIES,
    MenuTypes.MATERIAL_LIST,
    MenuTypes.SEND
];

export const menuActive = (menu: MenuTypes, project: ProjectStateModel): boolean => {
    if ((menusActive as any)[menu] !== undefined) {
        if (!(menusActive as any)[menu](project)) {
            return false;
        }
    }
    if (menusActive[MenuTypes[menu]] !== undefined) {
        if (!menusActive[MenuTypes[menu]](project)) {
            return false;
        }
    }
    return true;
};


export const menusActive: {[key: string]: (project: ProjectStateModel) => boolean} = {
    'START': (project: ProjectStateModel): boolean => {
        if (true) { // Todo: Aktuell nich benötigt
            return false;
        }
        return true;
    },
    'SETTINGS': (project: ProjectStateModel): boolean => {
        if (true) { // Todo: Inhalt fehlt
            return false;
        }
        return true;
    },
    'MODULE': (project: ProjectStateModel): boolean => {
        return true;
    },
    'BOAT_LIFT': (project: ProjectStateModel): boolean => {
        if (true) { // Todo: Inhalt fehlt
//            return false;
        }
        return true;
    },
    'ACCESSORIES': (project: ProjectStateModel): boolean => {
        if (true) { // Todo: Inhalt fehlt
//            return false;
        }
        return true;
    },
    'MATERIAL_LIST': (project: ProjectStateModel): boolean => {
        if (true) { // Todo: Inhalt fehlt
            return false;
        }
        return true;
    },
    'HELP': (project: ProjectStateModel): boolean => {
        if (true) { // Todo: Inhalt fehlt
//            return false;
        }
        return true;
    },
    'SEND': (project: ProjectStateModel): boolean => {
        if (project.products.length === 0) {
            return false;
        }
        return true;
    },
    'VIEW_3D': (project: ProjectStateModel): boolean => {
        if (project.products.length === 0) {
            return false;
        }
        if (!isWebGLAvailable() && !isWebGL2Available()) {
            return false;
        }
        return true;
    }
};

export interface AppStateModel {
    dimensions: DimensionsModel;
    menuDimensions: DimensionsModel;
    menuRightDimensions: DimensionsModel;
    footerDimensions: DimensionsModel;
    currentMenu: MenuTypes;
    pre3dMenu: MenuTypes | null;
    small: boolean;
    isStart: boolean;
    minMax: MinMaxProps | null;
    minMaxModule: MinMaxProps | null;
    isTouch: boolean;
    isDevelopment: boolean;
    apiPrefix: string;
    appVersion: string;
};

const defaultState: AppStateModel = {
    dimensions: DIMENSIONS_DEFAULT,
    menuDimensions: DIMENSIONS_DEFAULT,
    menuRightDimensions: DIMENSIONS_DEFAULT,
    footerDimensions: DIMENSIONS_DEFAULT,
    currentMenu: MenuTypes.MODULE, //START,
    pre3dMenu: null,
    small: false,
    isStart: false, //true,
    minMax: null,
    minMaxModule: null,
    isTouch: isTouchDevice(),
    isDevelopment: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),
    apiPrefix: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:8080/ams-stegplaner/gui/public/' : '',
    appVersion: ''
};

const getInitState = (): AppStateModel => {
    return defaultState;
};

const initialState: AppStateModel = getInitState();

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        initNewApp: () => initialState,
        setDimensions: (state, action: PayloadAction<DimensionsModel>) => {
            state.dimensions = action.payload;
        },
        setMenuDimensions: (state, action: PayloadAction<DimensionsModel>) => {
            state.menuDimensions = action.payload;
        },
        setMenuRightDimensions: (state, action: PayloadAction<DimensionsModel>) => {
            state.menuRightDimensions = action.payload;
        },
        setFooterDimensions: (state, action: PayloadAction<DimensionsModel>) => {
            state.footerDimensions = action.payload;
        },
        setCurrentMenu: (state, action: PayloadAction<MenuTypes>) => {
            state.currentMenu = action.payload;

            let newIsStart = action.payload === MenuTypes.START;
            if (newIsStart !== state.isStart) {
                state.isStart = newIsStart;
            }
        },
        setPre3dMenu: (state, action: PayloadAction<MenuTypes | null>) => {
            state.pre3dMenu = action.payload;
        },
        setSmall: (state, action: PayloadAction<boolean>) => {
            state.small = action.payload;
        },
        setMinMax: (state, action: PayloadAction<MinMaxProps | null>) => {
            state.minMax = action.payload;
        },
        setMinMaxModule: (state, action: PayloadAction<MinMaxProps | null>) => {
            state.minMaxModule = action.payload;
        },
        updateIsTouch: (state) => {
            state.isTouch = isTouchDevice();
        },
        setAppVersion: (state, action: PayloadAction<string>) => {
            state.appVersion = action.payload;
        }
    }
});

export const {
    initNewApp,
    setDimensions,
    setMenuDimensions, setMenuRightDimensions,
    setFooterDimensions,
    setCurrentMenu,
    setPre3dMenu,
    setSmall,
    setMinMax, setMinMaxModule,
    updateIsTouch,
    setAppVersion
} = appSlice.actions;
export default appSlice.reducer;
