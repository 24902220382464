import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../app/hooks";
import {useDispatch} from "react-redux";
import {ProjectProductsModel, ProductsModel, ProductsVariantsModel} from "../../../data/Products";
import {setProductActive, setProductDraggable, setModalProductVariant} from "../../../features/visual-area-slice";
import {changeProduct, removeProduct} from "../../../features/project-slice";
import {rotateAngle} from "../../../utils/global";
import {Group, Rect, Image} from "react-konva";
import Konva from 'konva';
import {CONSTANTS} from "../../../data/Constants";
import useImage from 'use-image';
import {MinMaxProps} from "../../../models/App";

import iconStift from "../../../assets/visual/icons/icon_stift.png";
import iconTrash from "../../../assets/visual/icons/icon_trash.png";
import iconTurn from "../../../assets/visual/icons/icon_turn.png";

const DEFAULT_OFFSET = 0;
const EDIT_OFFSET = 20;
const DEFAULT_DASH = [5, 5];
const DEFAULT_CONRER_RADIUS = 5;
const ICONS_MAX_HEIGHT = 20;

export interface vaProductEditProps {
    productKey: number;
    product: ProjectProductsModel;
    pmodel: ProductsModel;
    pvmodel: ProductsVariantsModel | null;
    offset?: number;
    offsetX?: number;
    offsetXStart?: number;
    offsetXEnd?: number;
    offsetY?: number;
    offsetYStart?: number;
    offsetYEnd?: number;
};

export const VisualAreaProductEdit = ({productKey, product, pmodel, pvmodel, ...props}:vaProductEditProps) => {
    const dispatch = useDispatch();
    const {scale, toImageView} = useAppSelector((state) => state.visualArea);
    const [offset, setOffset] = useState<number>(0);
    const [offsetX, setOffsetX] = useState<number>(0);
    const [offsetXStart, setOffsetXStart] = useState<number>(0);
    const [offsetXEnd, setOffsetXEnd] = useState<number>(0);
    const [offsetY, setOffsetY] = useState<number>(0);
    const [offsetYStart, setOffsetYStart] = useState<number>(0);
    const [offsetYEnd, setOffsetYEnd] = useState<number>(0);
    const [rectX, setRectX] = useState<number>(0);
    const [rectY, setRectY] = useState<number>(0);
    const [rectWidth, setRectWidth] = useState<number>(0);
    const [rectHeight, setRectHeight] = useState<number>(0);
    const [strokeWidth, setStrokeWidth] = useState<number>(1);
    const [minMax, setMinMax] = useState<MinMaxProps>({xMin: 0, xMax: 0, yMin: 0, yMax: 0, width: 0, height: 0});
    const [dash, setDash] = useState<number[]>([]);
    const [cornerRadius, setCornerRadius] = useState<number>(0);
    const [iconHeight, setIconHeight] = useState<number>(0);
    const [imageIconStift] = useImage(iconStift);
    const [iconWidthStift, setIconWidthStift] = useState<number>(0);
    const [imageIconTrash] = useImage(iconTrash);
    const [iconWidthTrash, setIconWidthTrash] = useState<number>(0);
    const [imageIconTurn] = useImage(iconTurn);
    const [iconWidthTurn, setIconWidthTurn] = useState<number>(0);

    useEffect(() => {
        let newOffset = props.offset ?? DEFAULT_OFFSET;
        if (scale !== null) {
            newOffset += EDIT_OFFSET / scale;
        }
        if (newOffset !== offset) {
            setOffset(newOffset);
        }
    }, [offset, props.offset, scale]);

    useEffect(() => {
        let newOffsetX = props.offsetX ?? offset;
        if (!!props.offsetX && scale !== null) {
            newOffsetX += EDIT_OFFSET / scale;
        }
        if (newOffsetX !== offsetX) {
            setOffsetX(newOffsetX);
        }
    }, [offset, offsetX, props.offsetX, scale]);

    useEffect(() => {
        let newOffsetXStart = props.offsetXStart ?? offsetX;
        if (!!props.offsetXStart && scale !== null) {
            newOffsetXStart += EDIT_OFFSET / scale;
        }
        if (newOffsetXStart !== offsetXStart) {
            setOffsetXStart(newOffsetXStart);
        }
    }, [offsetX, offsetXStart, props.offsetXStart, scale]);

    useEffect(() => {
        let newOffsetXEnd = props.offsetXEnd ?? offsetX;
        if (!!props.offsetXEnd && scale !== null) {
            newOffsetXEnd += EDIT_OFFSET / scale;
        }
        if (newOffsetXEnd !== offsetXEnd) {
            setOffsetXEnd(newOffsetXEnd);
        }
    }, [offsetX, offsetXEnd, props.offsetXEnd, scale]);

    useEffect(() => {
        let newOffsetY = props.offsetY ?? offset;
        if (!!props.offsetY && scale !== null) {
            newOffsetY += EDIT_OFFSET / scale;
        }
        if (newOffsetY !== offsetY) {
            setOffsetY(newOffsetY);
        }
    }, [offset, offsetY, props.offsetY, scale]);

    useEffect(() => {
        let newOffsetYStart = props.offsetYStart ?? offsetY;
        if (!!props.offsetYStart && scale !== null) {
            newOffsetYStart += EDIT_OFFSET / scale;
        }
        if (newOffsetYStart !== offsetYStart) {
            setOffsetYStart(newOffsetYStart);
        }
    }, [offsetY, offsetYStart, props.offsetYStart, scale]);

    useEffect(() => {
        let newOffsetYEnd = props.offsetYEnd ?? offsetY;
        if (!!props.offsetYEnd && scale !== null) {
            newOffsetYEnd += EDIT_OFFSET / scale;
        }
        if (newOffsetYEnd !== offsetYEnd) {
            setOffsetYEnd(newOffsetYEnd);
        }
    }, [offsetY, offsetYEnd, props.offsetYEnd, scale]);

    useEffect(() => {
        let xMin: number | null = null;
        let xMax: number | null = null;
        let yMin: number | null = null;
        let yMax: number | null = null;

        const ppoints = pmodel.getCoordinates(product, false);
        ppoints.forEach(function (ppoint) {
                if (xMin === null || xMin > ppoint.x) {
                    xMin = ppoint.x;
                }
                if (xMax === null || xMax < ppoint.x) {
                    xMax = ppoint.x;
                }
                if (yMin === null || yMin > ppoint.y) {
                    yMin = ppoint.y;
                }
                if (yMax === null || yMax < ppoint.y) {
                    yMax = ppoint.y;
                }
        });
        if (xMin === null || xMax === null || yMin === null || yMax === null) {
            return;
        }
        let newMinMax: MinMaxProps = {xMin: xMin, xMax: xMax, yMin: yMin, yMax: yMax, width: xMax - xMin, height: yMax - yMin};
        if (JSON.stringify(newMinMax) !== JSON.stringify(minMax)) {
            setMinMax(newMinMax);
        }
    }, [pmodel, product, minMax, pmodel.widthDraw, pmodel.heightDraw]);

    useEffect(() => {
        let newRectX = minMax.xMin;
        newRectX -= offsetXStart;
        if (newRectX !== rectX) {
            setRectX(newRectX);
        }
    }, [rectX, minMax.xMin, offsetXStart]);

    useEffect(() => {
        let newRectY = minMax.yMin;
        newRectY -= offsetYStart;
        if (newRectY !== rectY) {
            setRectY(newRectY);
        }
    }, [rectY, minMax.yMin, offsetYStart]);

    useEffect(() => {
        let newRectWidth = minMax.width + offsetXStart + offsetXEnd;
        if (newRectWidth !== rectWidth) {
            setRectWidth(newRectWidth);
        }
    }, [rectWidth, minMax.width, offsetXStart, offsetXEnd]);

    useEffect(() => {
        let newStrokeWidth = 3;
        if (scale !== null) {
            newStrokeWidth /= scale;
        }
        if (newStrokeWidth !== strokeWidth) {
            setStrokeWidth(newStrokeWidth);
        }
    }, [strokeWidth, scale]);

    useEffect(() => {
        if (scale === null) {
            return;
        }
        let newDash: number[] = [];
        DEFAULT_DASH.forEach((nd) => {
            newDash.push(nd / scale);
        });

        if (JSON.stringify(newDash) !== JSON.stringify(dash)) {
            setDash(newDash);
        }
    }, [scale, dash]);

    useEffect(() => {
        if (scale === null) {
            return;
        }

        let newCornerRadius: number = DEFAULT_CONRER_RADIUS / scale;

        if (newCornerRadius !== cornerRadius) {
            setCornerRadius(newCornerRadius);
        }
    }, [scale, cornerRadius]);

    useEffect(() => {
        let newRectHeight = minMax.height + offsetYStart + offsetYEnd;
        if (newRectHeight !== rectHeight) {
            setRectHeight(newRectHeight);
        }
        if (scale === null) {
            return;
        }
        let newRectHeightReal = (newRectHeight * scale) - (2 * DEFAULT_CONRER_RADIUS);
        let newIconHeightReal = ICONS_MAX_HEIGHT;
        if (newRectHeightReal < (3 * ICONS_MAX_HEIGHT)) {
            newIconHeightReal = newRectHeightReal / 5;
        }
        let newIconHeight = newIconHeightReal / scale;
        if (iconHeight !== newIconHeight) {
            setIconHeight(newIconHeight);
        }
    }, [rectHeight, minMax.height, offsetYStart, offsetYEnd, scale, iconHeight]);

    useEffect(() => {
        if (imageIconStift === undefined) {
            return;
        }
        let naturalHeight: number = imageIconStift.naturalHeight;
        let naturalWidth: number = imageIconStift.naturalWidth;
        let newIconWidthStift: number = iconHeight * naturalWidth / naturalHeight;
        if (iconWidthStift !== newIconWidthStift) {
            setIconWidthStift(newIconWidthStift);
        }
    }, [iconHeight, imageIconStift, iconWidthStift]);
    useEffect(() => {
        if (imageIconTrash === undefined) {
            return;
        }
        let naturalHeight: number = imageIconTrash.naturalHeight;
        let naturalWidth: number = imageIconTrash.naturalWidth;
        let newIconWidthTrash: number = iconHeight * naturalWidth / naturalHeight;
        if (iconWidthTrash !== newIconWidthTrash) {
            setIconWidthTrash(newIconWidthTrash);
        }
    }, [iconHeight, imageIconTrash, iconWidthTrash]);
    useEffect(() => {
        if (imageIconTurn === undefined) {
            return;
        }
        let naturalHeight: number = imageIconTurn.naturalHeight;
        let naturalWidth: number = imageIconTurn.naturalWidth;
        let newIconWidthTurn: number = iconHeight * naturalWidth / naturalHeight;
        if (iconWidthTurn !== newIconWidthTurn) {
            setIconWidthTurn(newIconWidthTurn);
        }
    }, [iconHeight, imageIconTurn, iconWidthTurn]);

    const handleMouseenterIcons = (data: any): void => {
        document.body.style.cursor = 'pointer';
    };
    const handleMouseleaveIcons = (data: any): void => {
        document.body.style.cursor = 'default';
    };

    const handleClickRotate = (e: Konva.KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        e.evt.preventDefault();
        if (pmodel.rotate === null || product.rotate === null) {
            return;
        }
        let cProduct: ProjectProductsModel = Object.assign({}, product);
        if (cProduct.rotate === null) {
            return;
        }
        cProduct.rotate = Math.round(rotateAngle(cProduct.rotate, pmodel.rotate));
        dispatch(changeProduct({index: productKey, product: cProduct}));
    };

    const handleClickDelete = (e: Konva.KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        e.evt.preventDefault();

        dispatch(setProductActive(null));
        dispatch(setProductDraggable(null));
        dispatch(removeProduct(productKey));
    };

    const handleClickChangeVariant = (e: Konva.KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        e.evt.preventDefault();

        dispatch(setModalProductVariant(true));
    };

    if (scale === null || toImageView) {
        return (null);
    }

    return (
            <Group>
                    <Rect
                        x={rectX} y={rectY}
                        width={rectWidth} height={rectHeight}
                        fill={CONSTANTS.COLORS.COLOR_EDIT_BG}
                        stroke={CONSTANTS.COLORS.COLOR_EDIT_BORDER}
                        strokeWidth={strokeWidth}
                        dash={dash} cornerRadius={cornerRadius} />
                    {
                        pmodel.rotate !== null && product.rotate !== null &&
                        <Image
                            x={-rectX + (strokeWidth / 2)} y={rectY + cornerRadius}
                            width={iconHeight} height={iconWidthTurn}
                            image={imageIconTurn}
                            onClick={handleClickRotate}
                            onTouchstart={handleClickRotate}
                            onMouseenter={handleMouseenterIcons}
                            onMouseleave={handleMouseleaveIcons} />
                    }
                    {
                        pmodel.variants.length > 1 &&
                        <Image
                            x={-rectX + (strokeWidth / 2)} y={-iconHeight / 2}
                            width={iconHeight} height={iconWidthStift}
                            image={imageIconStift}
                            onClick={handleClickChangeVariant}
                            onTouchstart={handleClickChangeVariant}
                            onMouseenter={handleMouseenterIcons}
                            onMouseleave={handleMouseleaveIcons} />
                    }
                    <Image
                        x={-rectX + (strokeWidth / 2)} y={-rectY - cornerRadius - iconHeight}
                        width={iconHeight} height={iconHeight}
                        image={imageIconTrash}
                        onClick={handleClickDelete}
                        onTouchstart={handleClickDelete}
                        onMouseenter={handleMouseenterIcons}
                        onMouseleave={handleMouseleaveIcons} />
            </Group>
            );
};

export default VisualAreaProductEdit;
