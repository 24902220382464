import {useEffect, useState} from 'react';
import {getStageModulesFromProduct, StageModuleModel, StageModuleVariantsModel} from "../../../../data/products/StageModule";
import {Group, Image} from "react-konva";
import useImage from 'use-image';
import {vaProductProps} from "../VisualAreaProduct";
import VisualAreaProductEdit from "../VisualAreaProductEdit";

const vaModuleBarLayer = 5;
const vaModulePoleLayer = 4;
const vaModuleBarLayerActive = 8;
const vaModulePoleLayerActive = 4;

export const showVisualAreaModule = (productLayer: number, isActive: boolean): boolean => {
    if (isActive) {
        if (productLayer !== vaModuleBarLayerActive && productLayer !== vaModulePoleLayerActive) {
            return false;
        }
        return true;
    }
    if (productLayer !== vaModuleBarLayer && productLayer !== vaModulePoleLayer) {
        return false;
    }
    return true;
};

export const VisualAreaModule = ({product, productKey, productLayer, productUtils}: vaProductProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [smodule, setSmodule] = useState<StageModuleModel | null>(null);
    const [svmodule, setSvmodule] = useState<StageModuleVariantsModel | null>(null);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [image] = useImage(imageSrc);
    const [imagePoleSrc, setImagePoleSrc] = useState<string>('');
    const [imagePole] = useImage(imagePoleSrc);
    const [barLayer, setBarLayer] = useState<number>(vaModuleBarLayer);
    const [poleLayer, setPoleLayer] = useState<number>(vaModulePoleLayer);
    const [rectX, setRectX] = useState<number>(0);
    const [rectY, setRectY] = useState<number>(0);
    const [rectWidth, setRectWidth] = useState<number>(0);
    const [rectHeight, setRectHeight] = useState<number>(0);
    const [poleWidth, setPoleWidth] = useState<number>(0);
    const [poleWidthHalf, setPoleWidthHalf] = useState<number>(0);
    const [poleHeight, setPoleHeight] = useState<number>(0);
    const [poleHeightHalf, setPoleHeightHalf] = useState<number>(0);

    useEffect(() => {
        let newBarLayer = productUtils.isActive ? vaModuleBarLayerActive : vaModuleBarLayer;
        if (barLayer !== newBarLayer) {
            setBarLayer(newBarLayer);
        }
        let newPoleLayer = productUtils.isActive ? vaModulePoleLayerActive : vaModulePoleLayer;
        if (poleLayer !== newPoleLayer) {
            setPoleLayer(newPoleLayer);
        }
    }, [productUtils.isActive, barLayer, poleLayer]);

    useEffect(() => {
        let newShow = (productLayer === barLayer || productLayer === poleLayer);
        if (newShow !== show) {
            setShow(newShow);
        }
    }, [show, productLayer, barLayer, poleLayer]);

    useEffect(() => {
        if (!show) {
            return;
        }
        const stageModules = getStageModulesFromProduct(product);
        if (stageModules === null) {
            setSmodule(null);
            setSvmodule(null);
            return;
        }
        const [nsmodule, nsvmodule] = stageModules;
        setSmodule(nsmodule);
        setSvmodule(nsvmodule);
    }, [show, product, productKey]);

    useEffect(() => {
        if (!show || productLayer !== barLayer) {
            return;
        }
        if (svmodule === null) {
            if (imageSrc !== '') {
                setImageSrc('');
            }
            return;
        }
        if (imageSrc !== svmodule.imageVisual) {
            setImageSrc(svmodule.imageVisual);
        }
    }, [show, svmodule, imageSrc, imagePoleSrc, barLayer, productLayer]);

    useEffect(() => {
        if (!show || productLayer !== poleLayer) {
            return;
        }
        if (svmodule === null) {
            if (imagePoleSrc !== '') {
                setImagePoleSrc('');
            }
            return;
        }
        if (imagePoleSrc !== svmodule.imagePoleVisual) {
            setImagePoleSrc(svmodule.imagePoleVisual);
        }
    }, [show, svmodule, imageSrc, imagePoleSrc, poleLayer, productLayer]);

    useEffect(() => {
        if (!show || smodule === null) {
            return;
        }
        let newRectX = -smodule.widthDraw / 2;
        if (newRectX !== rectX) {
            setRectX(newRectX);
        }
    }, [show, rectX, smodule]);

    useEffect(() => {
        if (!show || smodule === null) {
            return;
        }
        let newRectY = -smodule.heightDraw / 2;
        if (newRectY !== rectY) {
            setRectY(newRectY);
        }
    }, [show, rectY, smodule]);

    useEffect(() => {
        if (!show || smodule === null) {
            return;
        }
        let newRectWidth = smodule.widthDraw;
        if (newRectWidth !== rectWidth) {
            setRectWidth(newRectWidth);
        }
    }, [show, rectWidth, smodule]);

    useEffect(() => {
        if (!show || smodule === null) {
            return;
        }
        let newRectHeight = smodule.heightDraw;
        if (newRectHeight !== rectHeight) {
            setRectHeight(newRectHeight);
        }
    }, [show, rectHeight, smodule]);

    useEffect(() => {
        if (!show || smodule === null) {
            return;
        }
        let newPoleWidth = smodule.widthPole;
        if (newPoleWidth !== poleWidth) {
            setPoleWidth(newPoleWidth);
        }
        let newPoleWidthHalf = smodule.widthPole / 2;
        if (newPoleWidthHalf !== poleWidthHalf) {
            setPoleWidthHalf(newPoleWidthHalf);
        }
    }, [show, poleWidth, poleWidthHalf, smodule]);

    useEffect(() => {
        if (!show || smodule === null) {
            return;
        }
        let newPoleHeight = smodule.heightPole;
        if (newPoleHeight !== poleHeight) {
            setPoleHeight(newPoleHeight);
        }
        let newPoleHeightHalf = smodule.widthPole / 2;
        if (newPoleHeightHalf !== poleHeightHalf) {
            setPoleHeightHalf(newPoleHeightHalf);
        }
    }, [show, poleHeight, poleHeightHalf, smodule]);

    if (!show || smodule === null || svmodule === null) {
        return (null);
    }

    return (
            <>
                <Group x={0} y={0} rotation={productUtils.rotate}>
                    {
                        productLayer === barLayer &&
                        <Image
                            x={rectX} y={rectY}
                            width={rectWidth} height={rectHeight}
                            image={image} />
                    }
                    {
                        productLayer === poleLayer &&
                        <>
                            <Image
                                x={rectX - poleWidthHalf} y={rectY - poleHeightHalf}
                                width={poleWidth} height={poleHeight}
                                image={imagePole} />
                            <Image
                                x={rectX - poleWidthHalf} y={rectHeight + rectY - poleHeightHalf}
                                width={poleWidth} height={poleHeight}
                                image={imagePole} />
                            <Image
                                x={rectWidth + rectX - poleWidthHalf} y={rectY - poleHeightHalf}
                                width={poleWidth} height={poleHeight}
                                image={imagePole} />
                            <Image
                                x={rectWidth + rectX - poleWidthHalf} y={rectHeight + rectY - poleHeightHalf}
                                width={poleWidth} height={poleHeight}
                                image={imagePole} />
                        </>
                    }
                </Group>
                {
                    productLayer !== poleLayer && productUtils.isActive &&
                    <VisualAreaProductEdit
                        productKey={productKey} product={product}
                        pmodel={smodule} pvmodel={svmodule} offset={70} />
                }
            </>
    );
};

export default VisualAreaModule;
