import Button from 'react-bootstrap/Button';
import useTranslate from "../../../utils/useTranslate"

import './ImprintDataProtect.scss';

export const ImprintDataProtect = () => {
    const {t} = useTranslate();

    return (
        <div className={"ams-sp-imprint-data-protect"}>
            <a href={"https://www.waterfront-systems.com/impressum/"}>
                <Button variant="info" size="sm" onClick={() => {console.log('Click / Impressum')}}>{t('Impressum')}</Button>
            </a>
            <a href={"https://www.waterfront-systems.com/datenschutz/"}>
                <Button variant="info" size="sm" onClick={() => {console.log('Click / Datenschutz')}}>{t('Datenschutz')}</Button>
            </a>
        </div>
    );
};

export default ImprintDataProtect;
