import {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../app/hooks";
import { useDispatch } from "react-redux";
import { ProjectBoatLiftModel } from "../../../../data/products/BoatLift";
import { ProductsModel, ProductsVariantsModel } from "../../../../data/Products";
import {addProduct} from "../../../../features/project-slice";
import {setProductActive, setProductDraggable} from "../../../../features/visual-area-slice";
import {gridPoint} from "../../../../utils/global";
import ProductBoatLift from "../../../shared/Products/BoatLift/ProductBoatLift";
import MenuItemExampleBoats from "../ExampleBoats/MenuItemExampleBoats";
import Infos, {InfosProps} from "../Infos/Infos"

import './MenuItemBoatLift.scss';

export const MenuItemBoatLift = () => {
    const dispatch = useDispatch();
    const {visualArea, project} = useAppSelector((state) => state);
    const [addedProduct, setAddedProduct] = useState<number | null>(null);
    const [showInfoForProduct, setShowInfoForProduct] = useState<InfosProps | null>(null);

    useEffect(() => {
        if (addedProduct === null) {
            return;
        }
        dispatch(setProductActive(addedProduct));
        dispatch(setProductDraggable(addedProduct));
        setAddedProduct(null);
    }, [dispatch, addedProduct]);

    const addBoatLift = (iBoatLift: ProductsModel, isBoatLift: ProductsVariantsModel) => {
        if (visualArea.groupPosition === null || visualArea.scale === null) {
            return;
        }
        let x = ((visualArea.paintDimensions.width / 2) - visualArea.groupPosition.x) / visualArea.scale;
        let y = ((visualArea.paintDimensions.height / 2) - visualArea.groupPosition.y) / visualArea.scale;
        let newProduct: ProjectBoatLiftModel = {
            group: iBoatLift.group,
            typ: iBoatLift.typ,
            variant: isBoatLift.typ,
            x: x,
            y: y,
            rotate: 0
        };
        gridPoint(newProduct, iBoatLift.grid);

        dispatch(addProduct(newProduct));
        setAddedProduct(project.products.length);
    };

    const setInfoForProduct = (pmodel: ProductsModel) => {
        let nt: InfosProps = {
            title: pmodel.title,
            info: pmodel.info
        };
        if (JSON.stringify(nt) !== JSON.stringify(showInfoForProduct)) {
            setShowInfoForProduct(nt);
        }
    };

    return (
        <>
            {
                showInfoForProduct === null &&
                <>
                    <ProductBoatLift selectProductVariant={addBoatLift} infoForProduct={setInfoForProduct} />
                    <MenuItemExampleBoats />
                </>
            }
            {
                showInfoForProduct !== null &&
                <Infos title={showInfoForProduct.title} info={showInfoForProduct.info} onClose={() => {setShowInfoForProduct(null);}} />
            }
        </>
    );
};

export default MenuItemBoatLift;
