import {useEffect} from "react";
import {useAppSelector} from "../../app/hooks";
import {setAppVersion} from "../../features/app-slice";
import {useDispatch} from "react-redux";

const AppRandKey = (): string => {
    let base = 36;
    let keyspp = 4;
    let maxLeys = 24;
    let text = '';

    while (text.length < maxLeys) {
        let ks = (maxLeys - text.length) < keyspp ? (maxLeys - text.length) : keyspp;
        let max = Math.pow(36, ks) - 1;
        let n = Math.floor(Math.random() * max);
        let t = n.toString(base);
        text += t;
    }

    text += '_' + Date.now().toString(base);

    return text;
};
const isJsonString = (str: any): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

function reqListener(this: XMLHttpRequest, ev: ProgressEvent<EventTarget>) {
    if (!isJsonString(this.responseText)) {
        return;
    }
    let data = JSON.parse(this.responseText);
    if (typeof data.version !== 'string' || data.version === '') {
        return;
    }
    let versionEvent: CustomEvent<null> = new CustomEvent('ams.sp.have.new.version', {'detail': data.version});
    window.dispatchEvent(versionEvent);
};

const CheckVersionApp = () => {
    const {app, project} = useAppSelector((state) => state);
    const dispatch = useDispatch();

    const loadVersion = () => {
        var oReq = new XMLHttpRequest();
        oReq.onload = reqListener;
        oReq.open("get", window.location.pathname + "/version.json?rk=" + AppRandKey(), true);
        oReq.send();
    };

    useEffect(() => {
        const handleNewVersion = (event: any) => {
            if (typeof event.detail !== 'string' || event.detail === '') {
                return;
            }
            if (app.appVersion === '') {
                dispatch(setAppVersion(event.detail));
                return;
            }
            if (app.appVersion !== event.detail) {
                window.location.reload();
            }
        };

        window.addEventListener('ams.sp.have.new.version', handleNewVersion);

        // cleanup this component
        return () => {
            window.removeEventListener('ams.sp.have.new.version', handleNewVersion);
        };
    }, [app.appVersion, dispatch]);

    useEffect(() => {
        loadVersion();

        window.addEventListener('focus', loadVersion);
        window.addEventListener('ams.sp.check.new.version', loadVersion);

        // cleanup this component
        return () => {
            window.removeEventListener('focus', loadVersion);
            window.removeEventListener('ams.sp.check.new.version', loadVersion);
        };
    }, [app, app.appVersion, project, dispatch]);

    useEffect(() => {
        const interval = setInterval(() => {
            let checkVersionEvent = new Event('ams.sp.check.new.version');
            window.dispatchEvent(checkVersionEvent);
        }, 90000);

        return () => clearInterval(interval);
    }, []);

    return (null);
};

export default CheckVersionApp;
