import {useEffect, useState} from 'react';
import {getBathingLadderFromProduct} from "../../../../data/products/BathingLadder";
import {ProductsModel, ProductsVariantsModel} from "../../../../data/Products";
import {Group, Image} from "react-konva";
import useImage from 'use-image';
import {vaProductProps} from "../VisualAreaProduct";
import VisualAreaProductEdit from "../VisualAreaProductEdit";

const vaBathingLadderLayer = 6;
const vaBathingLadderLayerActive = 8;

export const showVisualAreaBathingLadder = (productLayer: number, isActive: boolean): boolean => {
    if (isActive) {
        if (productLayer !== vaBathingLadderLayerActive) {
            return false;
        }
        return true;
    }
    if (productLayer !== vaBathingLadderLayer) {
        return false;
    }
    return true;
};

export const VisualAreaBathingLadder = ({product, productKey, productLayer, productUtils}: vaProductProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [sladder, setSladder] = useState<ProductsModel | null>(null);
    const [svladder, setSvladder] = useState<ProductsVariantsModel | null>(null);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [image] = useImage(imageSrc);
    const [barLayer, setBarLayer] = useState<number>(vaBathingLadderLayer);
    const [rectX, setRectX] = useState<number>(0);
    const [rectY, setRectY] = useState<number>(0);
    const [rectWidth, setRectWidth] = useState<number>(0);
    const [rectHeight, setRectHeight] = useState<number>(0);

    useEffect(() => {
        let newBarLayer = productUtils.isActive ? vaBathingLadderLayerActive : vaBathingLadderLayer;
        if (barLayer !== newBarLayer) {
            setBarLayer(newBarLayer);
        }
    }, [productUtils.isActive, barLayer]);

    useEffect(() => {
        let newShow = (productLayer === barLayer);
        if (newShow !== show) {
            setShow(newShow);
        }
    }, [show, productLayer, barLayer]);

    useEffect(() => {
        if (!show) {
            return;
        }
        const bathingLadders = getBathingLadderFromProduct(product);
        if (bathingLadders === null) {
            setSladder(null);
            setSvladder(null);
            return;
        }
        const [nsladder, nsvladder] = bathingLadders;
        setSladder(nsladder);
        setSvladder(nsvladder);
    }, [show, product, productKey]);

    useEffect(() => {
        if (!show || productLayer !== barLayer) {
            return;
        }
        if (svladder === null) {
            if (imageSrc !== '') {
                setImageSrc('');
            }
            return;
        }
        if (imageSrc !== svladder.imageVisual) {
            setImageSrc(svladder.imageVisual);
        }
    }, [show, svladder, imageSrc, barLayer, productLayer]);

    useEffect(() => {
        if (!show || sladder === null) {
            return;
        }
        let newRectX = -sladder.widthDraw / 2;
        if (newRectX !== rectX) {
            setRectX(newRectX);
        }
    }, [show, rectX, sladder]);

    useEffect(() => {
        if (!show || sladder === null) {
            return;
        }
        let newRectY = -sladder.heightDraw / 2;
        if (newRectY !== rectY) {
            setRectY(newRectY);
        }
    }, [show, rectY, sladder]);

    useEffect(() => {
        if (!show || sladder === null) {
            return;
        }
        let newRectWidth = sladder.widthDraw;
        if (newRectWidth !== rectWidth) {
            setRectWidth(newRectWidth);
        }
    }, [show, rectWidth, sladder]);

    useEffect(() => {
        if (!show || sladder === null) {
            return;
        }
        let newRectHeight = sladder.heightDraw;
        if (newRectHeight !== rectHeight) {
            setRectHeight(newRectHeight);
        }
    }, [show, rectHeight, sladder]);

    if (!show || sladder === null || svladder === null) {
        return (null);
    }

    return (
            <>
                <Group x={0} y={0} rotation={productUtils.rotate}>
                    {
                        productLayer === barLayer &&
                        <Image
                            x={rectX} y={rectY}
                            width={rectWidth} height={rectHeight}
                            image={image} />
                    }
                </Group>
                {
                    productUtils.isActive &&
                    <VisualAreaProductEdit
                        productKey={productKey} product={product}
                        pmodel={sladder} pvmodel={svladder} offset={70} />
                }
            </>
    );
};

export default VisualAreaBathingLadder;
