import {useState, useEffect} from 'react';
import useTranslate from "../../../../utils/useTranslate"
import {
    ExampleBoats
} from "../../../../data/products/ExampleBoats";
import { ProductsProps, selectProductVariantType, checkProductType, checkProductVariantType, infoForProductType } from "../Products";
import {InfoCircle as BSIconInfoCircle} from "react-bootstrap-icons";
import { ProductsModel } from "../../../../data/Products";
import ProductsPlus from "../ProductsPlus";

import './ProductExampleBoats.scss';

export interface ProductExampleBoatProps {
    iExampleBoats: ProductsModel;
    iindex: number;
    haveInfoForProduct: boolean;
    selectProductVariant: selectProductVariantType;
    checkProduct?: checkProductType;
    checkProductVariant?: checkProductVariantType;
    infoForProduct?: infoForProductType;
};

const ProductExampleBoat = ({iExampleBoats, iindex, haveInfoForProduct, selectProductVariant, ...props}: ProductExampleBoatProps) => {
    const {t} = useTranslate();

    let title = t(iExampleBoats.title);
    let info = t(iExampleBoats.info);
    return (
        <>
            {
                iExampleBoats.variants.map((isExampleBoats, isindex) => {
                    if (props.checkProductVariant !== undefined && !props.checkProductVariant(iExampleBoats, isExampleBoats)) {
                        return (null);
                    }
                    let stitle = t(isExampleBoats.title);
                    return (
                        <div key={"ams-shared-item-example_boats-key-" + iindex + "-" + isindex}
                                className="ams-shared-item-example_boats-sub">
                            <div className="ams-shared-item-example_boats-scontainer">
                                {
                                    !!title && isindex === 0 &&
                                    <div className="ams-shared-item-example_boats-title">
                                        <span>{title}</span>
                                        {
                                            haveInfoForProduct &&
                                            <BSIconInfoCircle onClick={() => {
                                                if (props.infoForProduct !== undefined) {
                                                    props.infoForProduct(iExampleBoats);
                                                }
                                            }} />
                                        }
                                    </div>
                                }
                                <div className="ams-shared-item-example_boats-messures">{iExampleBoats.width} x {iExampleBoats.height} {t('mm')}</div>
                                <div className="ams-shared-item-example_boats-images" onClick={() => {selectProductVariant(iExampleBoats, isExampleBoats);}}>
                                    <img src={isExampleBoats.imageMenu} alt={"Example boat " + (iindex + 1) + "." + (isindex + 1)} title={"Example boat " + (iindex + 1) + "." + (isindex + 1)} />
                                    {
                                        !!stitle &&
                                        <div className="ams-shared-item-example_boats-sub-title">{stitle}</div>
                                    }
                                    {
                                        props.checkProduct === undefined && props.checkProductVariant === undefined &&
                                        <ProductsPlus />
                                    }
                                </div>
                                {
                                    !haveInfoForProduct && !!info && isindex === 0 &&
                                    <div className="ams-shared-item-example_boats-info">
                                        <div dangerouslySetInnerHTML={{__html: info}}></div>
                                    </div>
                                }
                            </div>
                        </div>
                    );
                })
            }
        </>
    );
};

export const ProductExampleBoats = ({selectProductVariant, ...props}: ProductsProps) => {
    const[haveInfoForProduct, setHaveInfoForProduct] = useState<boolean>(false);

    useEffect(() => {
        let h = (props.infoForProduct !== undefined);
        if (haveInfoForProduct !== h) {
            setHaveInfoForProduct(h);
        }
    }, [props, haveInfoForProduct]);

    return (
            <div className="ams-shared-item-example_boats">
                <div className="ams-shared-item-example_boats-subs">
                {
                    ExampleBoats.map((iExampleBoats, iindex) => {
                        if (props.checkProduct !== undefined && !props.checkProduct(iExampleBoats)) {
                            return (null);
                        }
                        return (
                            <ProductExampleBoat
                                key={"ProductExampleBoat_" + iindex}
                                iExampleBoats={iExampleBoats}
                                iindex={iindex}
                                haveInfoForProduct={haveInfoForProduct}
                                selectProductVariant={selectProductVariant}
                                checkProduct={props.checkProduct}
                                checkProductVariant={props.checkProductVariant}
                                infoForProduct={props.infoForProduct} />
                        );
                    })
                }
                </div>
            </div>
            );
};

export default ProductExampleBoats;
