import {useEffect, useState, useRef} from "react";
import Button from 'react-bootstrap/Button';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {MenuTypes, setCurrentMenu, setPre3dMenu} from "../../features/app-slice";
import encodeProject from '../../app/encodeProject';
import useTranslate from "../../utils/useTranslate"

import './VisualArea3D.scss';

const VisualArea3DRand = (): string => {
    let base = 36;
    let keyspp = 4;
    let maxLeys = 24;
    let text = '';

    while (text.length < maxLeys) {
        let ks = (maxLeys - text.length) < keyspp ? (maxLeys - text.length) : keyspp;
        let max = Math.pow(36, ks) - 1;
        let n = Math.floor(Math.random() * max);
        let t = n.toString(base);
        text += t;
    }

    text += '_' + Date.now().toString(base);

    return text;
};
const isJsonString = (str: any): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const VisualArea3D = () => {
    const dispatch = useDispatch();
    const {pre3dMenu, currentMenu} = useAppSelector((state) => state.app);
    const {project} = useAppSelector((state) => state);
    const [iframeSrc, setIframeSrc] = useState<string>(window.location.pathname + 'viewer-3d');
    const [randomKey] = useState<string>(VisualArea3DRand());
    const iframeRef = useRef<any>(null);
    const {t} = useTranslate();

    useEffect(() => {
        if (window.location.hostname !== 'localhost') {
            return;
        }
        setIframeSrc('http://localhost:3001/ams-stegplaner/viewer-3d');
    }, []);
    useEffect(() => {
        const handler = (event: any) => {
            if (!iframeRef.current || !iframeRef.current.contentWindow) {
                return;
            }
            if (event.source === window.self || event.source.top !== window.self) {
                return;
            }
            let data: any = event.data;
            if (typeof data === 'string') {
                if (!isJsonString(data)) {
                    return;
                }
                data = JSON.parse(data)
            }
            if (typeof data !== 'object' || data === null) {
                return;
            }
            if (typeof data.rkey !== 'string' || data.rkey !== randomKey) {
                return;
            }
            if (typeof data.irkey !== 'string' || data.irkey.length <= 0 ||
                typeof data.message !== 'string' || data.irkey.message <= 0 ||
                typeof data.error !== 'boolean' || data.error !== false) {
                return;
            }

            if (data.message === 'getData') {
                iframeRef.current.contentWindow.postMessage(
                    JSON.stringify({
                        error: false,
                        message: "setData",
                        data: encodeProject(project),
                        rkey: randomKey,
                        irkey: data.irkey
                    }),
                    '*'
                );
            }
        }

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }) // empty array => run only once

    const handle3DView = () => {
        if (currentMenu === MenuTypes.VIEW_3D) {
            dispatch(setCurrentMenu(pre3dMenu !== null ? pre3dMenu : MenuTypes.MODULE));
            dispatch(setPre3dMenu(null));
            return;
        }

        dispatch(setPre3dMenu(currentMenu));
        dispatch(setCurrentMenu(MenuTypes.VIEW_3D));
    };

    return (
        <div className="ams-visual3d-area">
            <iframe src={iframeSrc + '?rkey=' + randomKey} title="3d Viewer" ref={iframeRef}></iframe>
            <Button variant="primary" onClick={handle3DView} className={"ams-visual3d-area-close"}>
                { t('zur 2D Planung') }
            </Button>
        </div>
    );
};

export default VisualArea3D;
