import {useEffect} from "react";
import {useAppSelector} from "../../app/hooks";
import {useDispatch} from "react-redux";
import {MinMaxProps} from "../../models/App";
import {ProductGroupsTypes, ProjectProductsModel} from "../../data/Products";
import {getModelFromProduct} from "../../utils/global";
import {setMinMax, setMinMaxModule} from "../../features/app-slice";
import {LAND_LENGTH, MIN_OFFSET_LAND_LENGTH_SCALED, MIN_OFFSET_LAND_LENGTH, WATTER_LENGTH, OFFSET_WATTER_LENGTH} from "../../data/VisualArea";
import {setLandLength, setWaterLength} from "../../features/visual-area-slice";

const UpdateAppGetMinMax = (products: ProjectProductsModel[],group: ProductGroupsTypes | null = null): MinMaxProps | null => {
    let newMinMax: MinMaxProps = {
        xMin: 0,
        xMax: 0,
        yMin: 0,
        yMax: 0,
        width: 0,
        height: 0
    };
    let productFound = false;
    products.forEach((product) => {
        let amodel = getModelFromProduct(product);
        if (amodel === null) {
            return;
        }
        let [model] = amodel;
        if (group !== null && group !== model.group) {
            return;
        }
        let coords = model.getCoordinates(product);
        coords.forEach((coord) => {
            if (!productFound) {
                newMinMax.xMin = coord.x;
                newMinMax.xMax = coord.x;
                newMinMax.yMin = coord.y;
                newMinMax.yMax = coord.y;
                productFound = true;
                return;
            }
            if (newMinMax.xMin > coord.x) {
                newMinMax.xMin = coord.x;
            }
            if (newMinMax.xMax < coord.x) {
                newMinMax.xMax = coord.x;
            }
            if (newMinMax.yMin > coord.y) {
                newMinMax.yMin = coord.y;
            }
            if (newMinMax.yMax < coord.y) {
                newMinMax.yMax = coord.y;
            }
        });
    });
    if (newMinMax === null) {
        return null;
    }
    newMinMax.height = newMinMax.yMax - newMinMax.yMin;
    newMinMax.width = newMinMax.xMax - newMinMax.xMin;
    return newMinMax;
};

const MinMax = () => {
    const dispatch = useDispatch();
    const {app, project, visualArea} = useAppSelector((state) => state);

    useEffect(() => {
        let newMinMax: MinMaxProps | null = UpdateAppGetMinMax(project.products, ProductGroupsTypes.TYP_MODULE);
        if (newMinMax === null) {
            if (app.minMax !== null) {
                dispatch(setMinMax(null));
            }
            return;
        }
        if (JSON.stringify(app.minMax) !== JSON.stringify(newMinMax)) {
            dispatch(setMinMax(newMinMax));
        }
    }, [dispatch, project.products, app.minMax]);

    useEffect(() => {
        let newMinMax: MinMaxProps | null = UpdateAppGetMinMax(project.products);
        if (newMinMax === null) {
            if (app.minMaxModule !== null) {
                dispatch(setMinMaxModule(null));
            }
            return;
        }
        if (JSON.stringify(app.minMaxModule) !== JSON.stringify(newMinMax)) {
            dispatch(setMinMaxModule(newMinMax));
        }
    }, [dispatch, project.products, app.minMaxModule]);

    useEffect(() => {
        let newLength = LAND_LENGTH;
        if (app.minMaxModule !== null && visualArea.scale !== null) {
            let tLength = app.minMaxModule.yMax + (MIN_OFFSET_LAND_LENGTH_SCALED / visualArea.scale);
            if (tLength > newLength) {
                newLength = tLength;
            }
            let tLength2 = app.minMaxModule.yMax + MIN_OFFSET_LAND_LENGTH;
            if (tLength2 > newLength) {
                newLength = tLength2;
            }
        }
        if (visualArea.landLength !== newLength) {
            dispatch(setLandLength(newLength));
        }
    }, [dispatch, app.minMaxModule, visualArea.landLength, visualArea.scale]);

    useEffect(() => {
        let newWater = WATTER_LENGTH;
        if (app.minMaxModule !== null) {
            let tWater = -app.minMaxModule.yMin + OFFSET_WATTER_LENGTH;
            if (tWater > newWater) {
                newWater = tWater;
            }
        }
        if (visualArea.waterLength !== newWater) {
            dispatch(setWaterLength(newWater));
        }
    }, [dispatch, app.minMaxModule, visualArea.waterLength]);

    return (null);
};

export default MinMax;
