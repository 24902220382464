import {ProjectStateModel, ProjectStateExportModel} from '../features/project-slice';
import {ProjectProductsModel, ProductGroupsTypes, ProjectProductsExportModel} from '../data/Products';
import {StageModuleTypes, StageSubModuleTypes} from "../data/products/StageModule";
import {BoatLiftTypes, SubBoatLiftTypes} from "../data/products/BoatLift";
import {BathingLadderTypes, SubBathingLadderTypes} from "../data/products/BathingLadder";
import {ExampleBoatsTypes, SubExampleBoatsTypes} from "../data/products/ExampleBoats";
import {BoardingAidTypes, SubBoardingAidTypes} from "../data/products/BoardingAid";

const decodeProject = (project: ProjectStateExportModel): ProjectStateModel => {
    let decodedProject: ProjectStateModel = {
        projectID: project.projectID,
        products: decodeProjectProducts(project.products),
        beachLength: project.beachLength,
    };

    return decodedProject;
};

const decodeProjectProducts = (products: ProjectProductsExportModel[]): ProjectProductsModel[] => {
    let decodedProducts: ProjectProductsModel[] = [];

    products.map((product) => {

        let typ = decodeProjectProductTyp(product);
        if (typ === null) {
            return null;
        }

        let variant = decodeProjectProductVariant(product);
        if (variant === null && product.variant !== null) {
            return null;
        }

        if ((ProductGroupsTypes as any)[product.group] === undefined) {
            return null;
        }
        let group = (ProductGroupsTypes as any)[product.group];

        let newProduct: ProjectProductsModel = {
            group: group,
            typ: typ,
            variant: variant,
            rotate: product.rotate,
            x: product.x,
            y: product.y
        };

        decodedProducts.push(newProduct);

        return newProduct;
    });

    return decodedProducts;
};

const decodeProjectProductTyp = (product: ProjectProductsExportModel): ProjectProductsModel["typ"] | null => {
    switch (product.group) {
        case ProductGroupsTypes[ProductGroupsTypes.TYP_MODULE]:
            if ((StageModuleTypes as any)[product.typ] === undefined) {
                return null;
            }
            return (StageModuleTypes as any)[product.typ];
        case ProductGroupsTypes[ProductGroupsTypes.TYP_BOAT_LIFT]:
            if ((BoatLiftTypes as any)[product.typ] === undefined) {
                return null;
            }
            return (BoatLiftTypes as any)[product.typ];
        case ProductGroupsTypes[ProductGroupsTypes.TYP_BATHING_LADDER]:
            if ((BathingLadderTypes as any)[product.typ] === undefined) {
                return null;
            }
            return (BathingLadderTypes as any)[product.typ];
        case ProductGroupsTypes[ProductGroupsTypes.TYP_EXAMPLE_BOATS]:
            if ((ExampleBoatsTypes as any)[product.typ] === undefined) {
                return null;
            }
            return (ExampleBoatsTypes as any)[product.typ];
        case ProductGroupsTypes[ProductGroupsTypes.TYP_BOARDING_AID]:
            if ((BoardingAidTypes as any)[product.typ] === undefined) {
                return null;
            }
            return (BoardingAidTypes as any)[product.typ];
    }
    return null;
};

const decodeProjectProductVariant = (product: ProjectProductsExportModel): ProjectProductsModel["variant"] => {
    switch (product.group) {
        case ProductGroupsTypes[ProductGroupsTypes.TYP_MODULE]:
            if (product.variant === null) {
                return null;
            }
            if ((StageSubModuleTypes as any)[product.variant] === undefined) {
                return null;
            }
            return (StageSubModuleTypes as any)[product.variant];
        case ProductGroupsTypes[ProductGroupsTypes.TYP_BOAT_LIFT]:
            if (product.variant === null) {
                return null;
            }
            if ((SubBoatLiftTypes as any)[product.variant] === undefined) {
                return null;
            }
            return (SubBoatLiftTypes as any)[product.variant];
        case ProductGroupsTypes[ProductGroupsTypes.TYP_BATHING_LADDER]:
            if (product.variant === null) {
                return null;
            }
            if ((SubBathingLadderTypes as any)[product.variant] === undefined) {
                return null;
            }
            return (SubBathingLadderTypes as any)[product.variant];
        case ProductGroupsTypes[ProductGroupsTypes.TYP_EXAMPLE_BOATS]:
            if (product.variant === null) {
                return null;
            }
            if ((SubExampleBoatsTypes as any)[product.variant] === undefined) {
                return null;
            }
            return (SubExampleBoatsTypes as any)[product.variant];
        case ProductGroupsTypes[ProductGroupsTypes.TYP_BOARDING_AID]:
            if (product.variant === null) {
                return null;
            }
            if ((SubBoardingAidTypes as any)[product.variant] === undefined) {
                return null;
            }
            return (SubBoardingAidTypes as any)[product.variant];
    }
    return null;
};

export default decodeProject;
