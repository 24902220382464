export const WATTER_LENGTH: number = 20000;
export const OFFSET_WATTER_LENGTH: number = 5000;
export const BEACH_LENGTH: number = 0;
export const LAND_LENGTH: number = 5000;
export const MIN_OFFSET_LAND_LENGTH: number = 1000;
export const MIN_OFFSET_LAND_LENGTH_SCALED: number = 100;
export const SCALE_DEFAULT: number = 1 / 50;
export const SCALE_BY_WHEEL_DEFAULT: number = 1.02;
export const SCALE_BY_BUTTON_DEFAULT: number = Math.pow(SCALE_BY_WHEEL_DEFAULT, 5);
export const SCALE_MAX_DEFAULT: number = 1 / 10;
