import {ProductGroupsTypes, ProductsModel, ProjectProductsModel, ProductsVariantsModel} from "../Products";
import {getCoordinates} from "../../utils/Products";

import lift_topview from "../../assets/products/boat_lift/LIFT-Topview.png";

export enum BoatLiftTypes {
    BLT_STANDARD
};
export enum SubBoatLiftTypes {
    SBLT_STANDARD
};

export interface ProjectBoatLiftModel extends ProjectProductsModel {
    group: ProductGroupsTypes;
//    typ: BoatLiftTypes;
};

let BoatLiftsShortInfoDe = '<div>';
BoatLiftsShortInfoDe += '<p>';
BoatLiftsShortInfoDe += 'Die nächste Generation Lift - <b>für Boote bis 2.2 t Gewicht</b>';
BoatLiftsShortInfoDe += '</p>';
BoatLiftsShortInfoDe += '<p>';
BoatLiftsShortInfoDe += 'Heben Sie Ihr Boot bei Bedarf aus dem Wasser. Direkt an Ihrem Steg!';
BoatLiftsShortInfoDe += '</p>';
BoatLiftsShortInfoDe += '</div>';

let BoatLiftsInfoDe = '<div>';
BoatLiftsInfoDe += '<p>';
BoatLiftsInfoDe += 'Die nächste Generation Lift - <b>für Boote bis 2.2 t Gewicht</b>';
BoatLiftsInfoDe += '</p>';
BoatLiftsInfoDe += '<p>';
BoatLiftsInfoDe += 'Heben Sie Ihr Boot bei Bedarf aus dem Wasser. Direkt an Ihrem Steg!';
BoatLiftsInfoDe += '</p>';
BoatLiftsInfoDe += '<p>';
BoatLiftsInfoDe += 'Schützen Sie Ihr Boot vor Bewuchs und Korrosion. Verschwenden Sie keine Gedanken mehr an Diebstahl, Osmoseschäden, Algen oder Wellenschläge. Sparen Sie Geld und Zeit bei der Wartung und Pflege. Vertrauen Sie dabei auf handgearbeitete, wertige Komponenten aus einem deutschen Meisterbetrieb des Metallbauerhandwerks.';
BoatLiftsInfoDe += '</p>';
BoatLiftsInfoDe += '<p>';
BoatLiftsInfoDe += 'technische Daten:';
BoatLiftsInfoDe += '</p>';
BoatLiftsInfoDe += '<p>';
BoatLiftsInfoDe += '<ul>';
BoatLiftsInfoDe += '<li>Nutzlast: 2200 kg</li>';
BoatLiftsInfoDe += '<li>Hubhöhe: 90 cm</li>';
BoatLiftsInfoDe += '<li>Aufnahmebreite: 1m, Aufnahmelänge: 2,93 m</li>';
BoatLiftsInfoDe += '<li>max. Bootsbreite: 2,5 m</li>';
BoatLiftsInfoDe += '<li>Material: Stahl</li>';
BoatLiftsInfoDe += '<li>Oberfläche: feuerverzinkt</li>';
BoatLiftsInfoDe += '<li>Gelenke: Rotgussbuchsen</li>';
BoatLiftsInfoDe += '<li>Bolzen: Edelstahl</li>';
BoatLiftsInfoDe += '<li>Aufnahmepunkte: Gummipuffer</li>';
BoatLiftsInfoDe += '<li>Diebstahlschutz: Sicherungsbolzen für Vorhängeschloss</li>';
BoatLiftsInfoDe += '</ul>';
BoatLiftsInfoDe += '</p>';
BoatLiftsInfoDe += '<p>';
BoatLiftsInfoDe += '12V Solarsystem Panel<br />Fernsteuerung der Anlage';
BoatLiftsInfoDe += '</p>';
BoatLiftsInfoDe += '</div>';

export const BoatLifts: ProductsModel[] = [
    {
        group: ProductGroupsTypes.TYP_BOAT_LIFT,
        typ: BoatLiftTypes.BLT_STANDARD,
        title: {
            de: 'WFS Lift-232.0'
        },
        info: {
            de: BoatLiftsInfoDe
        },
        width: 4000,
        widthDraw: 3005,
        height: 3000,
        heightDraw: 4000,
        grid: 125,
        rotate: 90,
        variants: [
            {
                typ: SubBoatLiftTypes.SBLT_STANDARD,
                title: {
                    de: ''
                },
                shortInfo: {
                    de: BoatLiftsShortInfoDe
                },
                imageMenu: lift_topview,
                imageVisual: lift_topview
            },
        ],
        getCoordinates: getCoordinates
    }
];

export const getBoatLiftFromProduct = (product: ProjectProductsModel): [ProductsModel, ProductsVariantsModel] | null => {
    let result: [ProductsModel, ProductsVariantsModel] | null = null;

    BoatLifts.forEach(function (slift: ProductsModel) {
        if (result !== null || slift.typ !== product.typ) {
            return;
        }
        slift.variants.forEach(function (svlift: ProductsVariantsModel) {
            if (result !== null || svlift.typ !== product.variant) {
                return;
            }
            result = [slift, svlift];
        });
    });

    return result;
};