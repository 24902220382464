import {useState, useEffect} from 'react';
import {useAppSelector} from "../../../app/hooks";
import {Group, Rect, Arrow, Text} from "react-konva";
import {VisualAreaMeasurementProps, VisualAreaMeasurementConst} from "./VisualAreaMeasurement";
import {CONSTANTS} from "../../../data/Constants";
import useTranslate from "../../../utils/useTranslate";

const multiMulti = (multi: number, org: number): number => {
    return org * multi;
};

export const VisualAreaMeasurementWidth = ({minMaxMeasure}: VisualAreaMeasurementProps) => {
    const {numberFormat} = useTranslate();
    const {scale} = useAppSelector((state) => state.visualArea);
    const [groupX, setGroupX] = useState<number>(0);
    const [groupY, setGroupY] = useState<number>(0);
    const [rectX, setRectX] = useState<number>(0);
    const [rectY, setRectY] = useState<number>(0);
    const [rectWidth, setRectWidth] = useState<number>(0);
    const [rectHeight, setRectHeight] = useState<number>(0);
    const [arrowPoints1, setArrowPoints1] = useState<number[]>([]);
    const [arrowPoints2, setArrowPoints2] = useState<number[]>([]);
    const [textBoxX, setTextBoxX] = useState<number>(0);
    const [textBoxY, setTextBoxY] = useState<number>(0);
    const [textBoxWidth, setTextBoxWidth] = useState<number>(0);
    const [textBoxFontSize, setTextBoxFontSize] = useState<number>(0);
    const [textBoxStroke, setTextBoxStroke] = useState<number>(0);
    const [multi, setMulti] = useState<number>(1);

    const arrowColor = CONSTANTS.COLORS.COLOR_ARROW_WIDTH;
    const textColor = CONSTANTS.COLORS.COLOR_ARROW_WIDTH_FONT;
    const rotation = 0;

    useEffect(() => {
        if (scale == null || minMaxMeasure === null || minMaxMeasure.height <= 0) {
            return;
        }
        let newGroupX = minMaxMeasure.xMin;
        if (newGroupX !== groupX) {
            setGroupX(newGroupX);
        }
        let newGroupY = minMaxMeasure.yMax + (VisualAreaMeasurementConst.offset + (multiMulti(multi, VisualAreaMeasurementConst.width) / 2)) / scale;
        if (newGroupY !== groupY) {
            setGroupY(newGroupY);
        }

        let wabs = minMaxMeasure.width * scale;
        let newMulti = VisualAreaMeasurementConst.multistart <= wabs ? 1 : Math.sqrt(wabs / VisualAreaMeasurementConst.multistart);
        if (newMulti < VisualAreaMeasurementConst.multiMin) {
            newMulti = VisualAreaMeasurementConst.multiMin;
        }
        if (newMulti !== multi) {
            setMulti(newMulti);
        }
    }, [scale, minMaxMeasure, groupX, groupY, multi]);

    useEffect(() => {
        if (scale == null || minMaxMeasure === null || minMaxMeasure.height <= 0) {
            return;
        }
        let arrowOffset = multiMulti(multi, VisualAreaMeasurementConst.arrowLength) - multiMulti(multi, VisualAreaMeasurementConst.arrowInnerOffset);
        let newRectX = arrowOffset / scale;
        if (newRectX !== rectX) {
            setRectX(newRectX);
        }
        let newRectY = (-multiMulti(multi, VisualAreaMeasurementConst.widthRect) / 2) / scale;
        if (newRectY !== rectY) {
            setRectY(newRectY);
        }
        let newRectWidth = minMaxMeasure.width - 2 * arrowOffset / scale;
        if (newRectWidth !== rectWidth) {
            setRectWidth(newRectWidth);
        }
        let newRectHeight = multiMulti(multi, VisualAreaMeasurementConst.widthRect) / scale;
        if (newRectHeight !== rectHeight) {
            setRectHeight(newRectHeight);
        }
    }, [scale, minMaxMeasure, rectX, rectY, rectWidth, rectHeight, multi]);

    useEffect(() => {
        if (scale == null) {
            return;
        }
        let startX = rectX - (multiMulti(multi, VisualAreaMeasurementConst.arrowLength) - multiMulti(multi, VisualAreaMeasurementConst.arrowInnerOffset)) / scale;
        let startY = rectY + (rectHeight / 2);
        let arrowWidthHalft = (multiMulti(multi, VisualAreaMeasurementConst.arrowWidth) / 2) / scale;
        let arrowLength = multiMulti(multi, VisualAreaMeasurementConst.arrowLength) / scale;
        let arrowInnerOffset = multiMulti(multi, VisualAreaMeasurementConst.arrowInnerOffset) / scale;
        let newArrowPoints1:number[] = [
            startX, startY,
            startX + arrowLength, startY + arrowWidthHalft,
            startX + arrowLength - arrowInnerOffset, startY,
            startX + arrowLength, startY - arrowWidthHalft
        ];
        if (JSON.stringify(newArrowPoints1) !== JSON.stringify(arrowPoints1)) {
            setArrowPoints1(newArrowPoints1);
        }
    }, [scale, rectX, rectY, rectWidth, rectHeight, arrowPoints1, multi]);

    useEffect(() => {
        if (scale == null) {
            return;
        }
        let startX = rectX + rectWidth + (multiMulti(multi, VisualAreaMeasurementConst.arrowLength) - multiMulti(multi, VisualAreaMeasurementConst.arrowInnerOffset)) / scale;
        let startY = rectY + (rectHeight / 2);
        let arrowWidthHalft = (multiMulti(multi, VisualAreaMeasurementConst.arrowWidth) / 2) / scale;
        let arrowLength = multiMulti(multi, VisualAreaMeasurementConst.arrowLength) / scale;
        let arrowInnerOffset = multiMulti(multi, VisualAreaMeasurementConst.arrowInnerOffset) / scale;
        let newArrowPoints2:number[] = [
            startX, startY,
            startX - arrowLength, startY + arrowWidthHalft,
            startX - arrowLength + arrowInnerOffset, startY,
            startX - arrowLength, startY - arrowWidthHalft
        ];
        if (JSON.stringify(newArrowPoints2) !== JSON.stringify(arrowPoints2)) {
            setArrowPoints2(newArrowPoints2);
        }
    }, [scale, rectX, rectY, rectWidth, rectHeight, arrowPoints2, multi]);

    useEffect(() => {
        if (scale == null) {
            return;
        }
        let newTextBoxWidth = multiMulti(multi, VisualAreaMeasurementConst.textBoxWidth) / scale;
        if (newTextBoxWidth !== textBoxWidth) {
            setTextBoxWidth(newTextBoxWidth);
        }

        let newTextBoxFontSize = multiMulti(multi, VisualAreaMeasurementConst.textBoxFontSize) / scale;
        if (newTextBoxFontSize !== textBoxFontSize) {
            setTextBoxFontSize(newTextBoxFontSize);
        }

        let newTextBoxX = rectX + (rectWidth / 2);
        if (newTextBoxX !== textBoxX) {
            setTextBoxX(newTextBoxX);
        }

        let newTextBoxY = -(multiMulti(multi, VisualAreaMeasurementConst.textBoxOffset) + (multiMulti(multi, VisualAreaMeasurementConst.textBoxFontSize) / 2)) / scale;
        if (newTextBoxY !== textBoxY) {
            setTextBoxY(newTextBoxY);
        }

        let newTextBoxStroke = multiMulti(multi, VisualAreaMeasurementConst.textBoxStroke) / scale;
        if (newTextBoxStroke !== textBoxStroke) {
            setTextBoxStroke(newTextBoxStroke);
        }
    }, [scale, textBoxWidth, textBoxFontSize, textBoxX, rectX, rectWidth, textBoxY, textBoxStroke, multi]);

    if (minMaxMeasure === null || minMaxMeasure.height <= 0) {
        return (null);
    }

    return (
            <>
                <Group x={groupX} y={groupY}>
                    <Rect
                        x={rectX} y={rectY}
                        width={rectWidth} height={rectHeight}
                        fill={arrowColor}
                        stroke={arrowColor}
                        strokeWidth={1} />
                    <Arrow points={arrowPoints1} fill={arrowColor} stroke={arrowColor} closed />
                    <Arrow points={arrowPoints2} fill={arrowColor} stroke={arrowColor} closed />
                    <Group x={textBoxX} y={textBoxY}>
                        <Text
                            x={0} y={0}
                            width={textBoxWidth} height={textBoxFontSize}
                            offsetX={textBoxWidth / 2}
                            offsetY={textBoxFontSize / 2}
                            text={numberFormat(minMaxMeasure.width) + ' mm'}
                            align={'center'}
                            fontFamily={VisualAreaMeasurementConst.textBoxFontFamily}
                            fontSize={textBoxFontSize}
                            fontWeight={'bold'} fill={textColor}
                            stroke={textColor}
                            strokeWidth={textBoxStroke}
                            rotation={rotation} />
                    </Group>
                </Group>
            </>
            );
};

export default VisualAreaMeasurementWidth;
