import {ProjectStateModel, ProjectStateExportModel} from '../features/project-slice';
import {ProjectProductsModel, ProductGroupsTypes, ProjectProductsExportModel} from '../data/Products';
import {StageModuleTypes, StageSubModuleTypes} from "../data/products/StageModule";
import {BoatLiftTypes, SubBoatLiftTypes} from "../data/products/BoatLift";
import {BathingLadderTypes, SubBathingLadderTypes} from "../data/products/BathingLadder";
import {ExampleBoatsTypes, SubExampleBoatsTypes} from "../data/products/ExampleBoats";
import {BoardingAidTypes, SubBoardingAidTypes} from "../data/products/BoardingAid";

const encodeProject = (project: ProjectStateModel): ProjectStateExportModel => {
    let encodedProject: ProjectStateExportModel = {
        projectID: project.projectID,
        products: encodeProjectProducts(project.products),
        beachLength: project.beachLength,
    };

    return encodedProject;
};

const encodeProjectProducts = (products: ProjectProductsModel[]): ProjectProductsExportModel[] => {
    let encodedProducts: ProjectProductsExportModel[] = products.map((product) => {
        let newProducts: ProjectProductsExportModel = {
            group: ProductGroupsTypes[product.group],
            typ: encodeProjectProductTyp(product),
            variant: encodeProjectProductVariant(product),
            rotate: product.rotate,
            x: product.x,
            y: product.y
        };
        return newProducts;
    });

    return encodedProducts;
};

const encodeProjectProductTyp = (product: ProjectProductsModel): ProjectProductsExportModel["typ"] => {
    switch (product.group) {
        case ProductGroupsTypes.TYP_MODULE:
            return StageModuleTypes[product.typ];
        case ProductGroupsTypes.TYP_BOAT_LIFT:
            return BoatLiftTypes[product.typ];
        case ProductGroupsTypes.TYP_BATHING_LADDER:
            return BathingLadderTypes[product.typ];
        case ProductGroupsTypes.TYP_EXAMPLE_BOATS:
            return ExampleBoatsTypes[product.typ];
        case ProductGroupsTypes.TYP_BOARDING_AID:
            return BoardingAidTypes[product.typ];
    }
    return '';
};

const encodeProjectProductVariant = (product: ProjectProductsModel): ProjectProductsExportModel["variant"] => {
    switch (product.group) {
        case ProductGroupsTypes.TYP_MODULE:
            if (product.variant === null) {
                return null;
            }
            return StageSubModuleTypes[product.variant];
        case ProductGroupsTypes.TYP_BOAT_LIFT:
            if (product.variant === null) {
                return null;
            }
            return SubBoatLiftTypes[product.variant];
        case ProductGroupsTypes.TYP_BATHING_LADDER:
            if (product.variant === null) {
                return null;
            }
            return SubBathingLadderTypes[product.variant];
        case ProductGroupsTypes.TYP_EXAMPLE_BOATS:
            if (product.variant === null) {
                return null;
            }
            return SubExampleBoatsTypes[product.variant];
        case ProductGroupsTypes.TYP_BOARDING_AID:
            if (product.variant === null) {
                return null;
            }
            return SubBoardingAidTypes[product.variant];
    }
    return null;
};

export default encodeProject;
