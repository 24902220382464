import {useAppSelector} from "../../../app/hooks";
import {ProjectProductsModel} from "../../../data/Products";
import VisualAreaProduct from "./VisualAreaProduct";

export interface vaProductsProps {
    product: ProjectProductsModel;
    productKey: number;
    productLayer: number;
};

export const VisualAreaProducts = () => {
    const {products} = useAppSelector((state) => state.project);
    const {productActive, productDraggable} = useAppSelector((state) => state.visualArea);
    const productLayers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    return (
            <>
                {
                    productLayers.map((layer) => {
                        let pr1 =
                                products.map((product, pkey) => {
                                    if (productActive === pkey || productDraggable === pkey) {
                                        return (null);
                                    }
                                    return (
                                        <VisualAreaProduct
                                            key={"VisualAreaProducts-" + layer + "-" + pkey}
                                            product={product}
                                            productKey={pkey} productLayer={layer} />);
                                });
                        let pr2 =
                                products.map((product, pkey) => {
                                    if (productActive !== pkey || productDraggable === pkey) {
                                        return (null);
                                    }
                                    return (
                                        <VisualAreaProduct
                                            key={"VisualAreaProducts-" + layer + "-" + pkey}
                                            product={product}
                                            productKey={pkey} productLayer={layer} />);
                                });
                        let pr3 =
                                products.map((product, pkey) => {
                                    if (productActive === pkey || productDraggable !== pkey) {
                                        return (null);
                                    }
                                    return (
                                        <VisualAreaProduct
                                            key={"VisualAreaProducts-" + layer + "-" + pkey}
                                            product={product}
                                            productKey={pkey} productLayer={layer} />);
                                });
                        let pr4 =
                                products.map((product, pkey) => {
                                    if (productActive !== pkey || productDraggable !== pkey) {
                                        return (null);
                                    }
                                    return (
                                        <VisualAreaProduct
                                            key={"VisualAreaProducts-" + layer + "-" + pkey}
                                            product={product}
                                            productKey={pkey} productLayer={layer} />);
                                });
                        return [pr1, pr2, pr3, pr4];
                    })
                }
            </>
    );
};

export default VisualAreaProducts;
