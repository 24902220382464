import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DimensionsModel} from "../models/App";
import {PointModel} from "../models/Global";
import {
    WATTER_LENGTH, BEACH_LENGTH, LAND_LENGTH,
    SCALE_BY_WHEEL_DEFAULT, SCALE_BY_BUTTON_DEFAULT,
    SCALE_MAX_DEFAULT
} from "../data/VisualArea";
import {DIMENSIONS_DEFAULT} from "../data/App";

export interface VisualAreaStateModel {
    stageDimensions: DimensionsModel;
    paintDimensions: DimensionsModel;
    paintOffset: DimensionsModel;
    absBGDimensions: DimensionsModel;
    scale: number | null;
    scaleByWheel: number;
    scaleByButton: number;
    scaleMin: number | null;
    scaleMax: number;
    waterLength: number;
    beachLength: number;
    landLength: number;
    groupDraggable: boolean;
    groupPosition: PointModel | null;
    productActive: number | null;
    productDraggable: number | null;
    modalProductVariant: boolean;
    toImageView: boolean;
    orgScale: number | null;
    orgGroupPosition: PointModel | null;
};

const defaultState: VisualAreaStateModel = {
    stageDimensions: DIMENSIONS_DEFAULT,
    paintDimensions: DIMENSIONS_DEFAULT,
    paintOffset: DIMENSIONS_DEFAULT,
    absBGDimensions: DIMENSIONS_DEFAULT,
    scale: null,
    scaleByWheel: SCALE_BY_WHEEL_DEFAULT,
    scaleByButton: SCALE_BY_BUTTON_DEFAULT,
    scaleMin: null,
    scaleMax: SCALE_MAX_DEFAULT,
    waterLength: WATTER_LENGTH,
    beachLength: BEACH_LENGTH,
    landLength: LAND_LENGTH,
    groupDraggable: true,
    groupPosition: null,
    productActive: null,
    productDraggable: null,
    modalProductVariant: false,
    toImageView: false,
    orgScale: null,
    orgGroupPosition: null
};

const getInitState = (): VisualAreaStateModel => {
    return defaultState;
};

const initialState: VisualAreaStateModel = getInitState();

const visualAreaSlice = createSlice({
    name: 'visualArea',
    initialState,
    reducers: {
        initNewVisualArea: () => initialState,
        setStageDimensions: (state, action: PayloadAction<DimensionsModel>) => {
            state.stageDimensions = action.payload;
        },
        setPaintDimensions: (state, action: PayloadAction<DimensionsModel>) => {
            state.paintDimensions = action.payload;
        },
        setPaintOffset: (state, action: PayloadAction<DimensionsModel>) => {
            state.paintOffset = action.payload;
        },
        setAbsBGDimensions: (state, action: PayloadAction<DimensionsModel>) => {
            state.absBGDimensions = action.payload;
        },
        setScale: (state, action: PayloadAction<number>) => {
            if (state.scaleMin !== null && action.payload < state.scaleMin) {
                state.scale = state.scaleMin;
                return;
            }
            if (state.scaleMax !== null && action.payload > state.scaleMax) {
                state.scale = state.scaleMax;
                return;
            }
            state.scale = action.payload;
        },
        setScaleMin: (state, action: PayloadAction<number>) => {
            state.scaleMin = action.payload;
            if (state.scale !== null && state.scale < action.payload) {
                state.scale = action.payload;
            }
        },
        setScaleMax: (state, action: PayloadAction<number>) => {
            state.scaleMax = action.payload;
            if (state.scale !== null && state.scale > action.payload) {
                state.scale = action.payload;
            }
        },
        setWaterLength: (state, action: PayloadAction<number>) => {
            state.waterLength = action.payload;
        },
        setBeachLength: (state, action: PayloadAction<number>) => {
            state.beachLength = action.payload;
        },
        setLandLength: (state, action: PayloadAction<number>) => {
            state.landLength = action.payload;
        },
        setGroupDraggable: (state, action: PayloadAction<boolean>) => {
            state.groupDraggable = action.payload;
        },
        setGroupPosition: (state, action: PayloadAction<PointModel | null>) => {
            state.groupPosition = action.payload;
        },
        setProductActive: (state, action: PayloadAction<number | null>) => {
            state.productActive = action.payload;
            if (state.productActive === null && state.modalProductVariant) {
                state.modalProductVariant = false;
            }
        },
        setProductDraggable: (state, action: PayloadAction<number | null>) => {
            state.productDraggable = action.payload;
        },
        setModalProductVariant: (state, action: PayloadAction<boolean>) => {
            state.modalProductVariant = action.payload;
        },
        setToImageView: (state, action: PayloadAction<boolean>) => {
            state.toImageView = action.payload;
        },
        setOrgScale: (state, action: PayloadAction<number | null>) => {
            state.orgScale = action.payload;
        },
        setOrgGroupPosition: (state, action: PayloadAction<PointModel | null>) => {
            state.orgGroupPosition = action.payload;
        }
    }
});

export const {
    initNewVisualArea,
    setStageDimensions, setPaintDimensions, setPaintOffset, setAbsBGDimensions,
    setScale, setScaleMin, setScaleMax,
    setWaterLength, setBeachLength, setLandLength,
    setGroupDraggable, setGroupPosition,
    setProductActive, setProductDraggable, setModalProductVariant,
    setToImageView, setOrgScale, setOrgGroupPosition
} = visualAreaSlice.actions;
export default visualAreaSlice.reducer;
