import {CSSProperties, useEffect, useState} from 'react';
import { useDispatch } from "react-redux";
import {useAppSelector} from "../../../../app/hooks";
import useRefDimensions, {UseRefDimensionsModel} from "../../../../utils/useRefDimensions";
import {Gear as BSIconGear} from "react-bootstrap-icons";
import useTranslate from "../../../../utils/useTranslate"
import {MenuTypes, setCurrentMenu, menuActive} from "../../../../features/app-slice";
import menuPanelPng from "../../../../assets/menu/panel.png";

import './MenuBodyLeft.scss';

interface menuBodyLeftStyle {
    height: number;
    width: number;
    transform: string;
    transformOrigin: string;
};

interface ItemType {
    text: JSX.Element | string;
    className: string;
    menu: MenuTypes | null;
};
const ItemsRight: ItemType[] = [
    {
        text: 'Senden',
        className: 'ams-menu-sidebar-item-first',
        menu: MenuTypes.SEND
    },
    {
        text: 'Materialliste',
        className: 'ams-menu-sidebar-item-first',
        menu: MenuTypes.MATERIAL_LIST
    },
    {
        text: 'Zubehör',
        className: 'ams-menu-sidebar-item-first',
        menu: MenuTypes.ACCESSORIES
    },
    {
        text: 'Bootslift',
        className: 'ams-menu-sidebar-item-first',
        menu: MenuTypes.BOAT_LIFT
    },
    {
        text: 'Module',
        className: 'ams-menu-sidebar-item-first',
        menu: MenuTypes.MODULE
    },
    {
        text: <BSIconGear />,
        className: 'ams-menu-sidebar-item-second',
        menu: MenuTypes.SETTINGS
    }
];
const ItemsLeft: ItemType[] = [
    {
        text: 'Hilfe',
        className: 'ams-menu-sidebar-item-second visibility-hidden',
        menu: MenuTypes.HELP
    }
];

export const MenuBodyLeft = () => {
    const dispatch = useDispatch();
    const {app, project} = useAppSelector((state) => state);
    const {t} = useTranslate();
    const refDimensions: UseRefDimensionsModel = useRefDimensions();
    const refLeftDimensions: UseRefDimensionsModel = useRefDimensions();
    const refRightDimensions: UseRefDimensionsModel = useRefDimensions();
    const [style, setStyle] = useState<menuBodyLeftStyle>({height: -1, width: -1, transform: 'rotate(270deg) scale(1)', transformOrigin: '0px 0px'});
    const [zoom, setZoom] = useState<number>(1);
    const [width, setWidth] = useState<number>(1);
    const [offsetLeft, setOffsetLeft] = useState<number>(0);

    useEffect(() => {
        if (refDimensions.dimensions === undefined) {
            return;
        }
        if (refDimensions.dimensions.width === undefined || refDimensions.dimensions.width <= 0 ||
                refDimensions.dimensions.height === undefined || refDimensions.dimensions.height <= 0) {
            return;
        }
        let height = refDimensions.dimensions.width;
        let width = refDimensions.dimensions.height / zoom;
        let newStyle: menuBodyLeftStyle = {
            height: height,
            width: width,
            transform: 'rotate(270deg) scale(' + zoom +  ')',
            transformOrigin: '0px 0px'
        };
        let newOffsetLeft = Math.floor(height - (height * zoom));
        if (newOffsetLeft !== offsetLeft) {
            setOffsetLeft(newOffsetLeft);
        }
        if (JSON.stringify(newStyle) !== JSON.stringify(style)) {
            setStyle(newStyle);
        }
    }, [style, offsetLeft, zoom, refDimensions.dimensions]);

    useEffect(() => {
        if (refDimensions.dimensions === undefined || refDimensions.dimensions.width === undefined || refDimensions.dimensions.width <= 0) {
            return;
        }
        if (refLeftDimensions.dimensions === undefined || refLeftDimensions.dimensions.width === undefined || refLeftDimensions.dimensions.width <= 0) {
            return;
        }
        if (refRightDimensions.dimensions === undefined || refRightDimensions.dimensions.width === undefined || refRightDimensions.dimensions.width <= 0) {
            return;
        }
        let fullWidth = 0;
        let check = [refRightDimensions, refLeftDimensions];
        check.forEach(function (refD) {
            let refWidth = 0;
            refWidth += refD.dimensions.width;
            let styles = window.getComputedStyle(refD.ref.current);
            let ml = parseFloat(styles.getPropertyValue("margin-left"));
            if (!isNaN(ml)) {
                refWidth += ml;
            }
            let mr = parseFloat(styles.getPropertyValue("margin-right"));
            if (!isNaN(mr)) {
                refWidth += mr;
            }
            fullWidth += refWidth;
        });

        if (refDimensions.dimensions.width !== width) {
            setWidth(refDimensions.dimensions.width);
        }
        let newZoom = refDimensions.dimensions.height / fullWidth;
        if (newZoom > 1) {
            newZoom = 1;
        }
        if (newZoom !== zoom) {
            setZoom(newZoom);
        }
    }, [zoom, width, refDimensions.dimensions, refRightDimensions, refRightDimensions.dimensions, refLeftDimensions, refLeftDimensions.dimensions]);

    const handleItemClick = (item: ItemType) => {
        if (item.menu === null || app.currentMenu === item.menu) {
            return;
        }
        dispatch(setCurrentMenu(item.menu));
    };

    const renderItems = (items: ItemType[]) => {
        return items.map((item, index) => {
            if (item.menu !== null && !menuActive(item.menu, project)) {
                return (null);
            }
            let text = item.text;
            if (typeof text === 'string') {
                text = t(text);
            }
            let cn = 'ams-menu-sidebar-item';
            if (item.className) {
                cn += ' ' + item.className;
            }
            if (app.currentMenu === item.menu) {
                cn += ' ams-item-selected';
            }
            if (cn.indexOf('ams-menu-sidebar-item-first') >= 0) {
                return <div className={cn} onClick={() => handleItemClick(item)} key={"ams-mblb-" + index}
                                style={{
                                    backgroundColor: 'transparent',
                                    "--image-background": `url(${menuPanelPng})`
                                } as CSSProperties}><div>{text}</div></div>
            }
            return <div className={cn} onClick={() => handleItemClick(item)} key={"ams-mblb-" + index}><div>{text}</div></div>
        });
    };

    return (
            <div className="ams-menu-body-left">
                <div ref={refDimensions.ref} className="ams-menu-body-left-body">
                    <div style={{position: "absolute", bottom: -width, left: offsetLeft}}>
                        <div className="ams-mblb-container" style={style}>
                            <div ref={refLeftDimensions.ref} className="ams-mblb-container-left">
                                {renderItems(ItemsLeft)}
                            </div>
                            <div ref={refRightDimensions.ref} className="ams-mblb-container-right">
                                {renderItems(ItemsRight)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
};

export default MenuBodyLeft;
