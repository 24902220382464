import React, {useEffect, useState, useRef} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../app/hooks";
import {Group} from "react-konva";
import Konva from 'konva';
import {VisualAreaBackground} from "../Background/VisualAreaBackground";
import {PointModel} from "../../../models/Global";
import {setGroupPosition, VisualAreaStateModel, setGroupDraggable, setProductActive, setProductDraggable} from "../../../features/visual-area-slice";
import {SCALE_DEFAULT} from "../../../data/VisualArea";
import {VisualAreaProducts} from "../Products/VisualAreaProducts";
import {VisualAreaMeasurement} from "../Measurement/VisualAreaMeasurement";

const checkVAGroupPos = (visualArea: VisualAreaStateModel, posOrg: PointModel): PointModel => {
    if (visualArea.scale === null) {
        return posOrg;
    }
    let pos = {
        x: posOrg.x,
        y: posOrg.y
    };

    if (pos.x > 0) {
        pos.x = 0;
    }

    let minX = visualArea.paintDimensions.width - (visualArea.absBGDimensions.width * visualArea.scale);
    if (pos.x < minX) {
        pos.x = minX;
    }

    let wl = visualArea.waterLength * visualArea.scale;
    if (pos.y > wl) {
        pos.y = wl;
    }

    let all = visualArea.paintDimensions.height - ((visualArea.beachLength + visualArea.landLength) * visualArea.scale);
    if (pos.y < all) {
        pos.y = all;
    }

    return pos;
};

export const VisualAreaGroup = () => {
    const dispatch = useDispatch();
    const {visualArea, app, project} = useAppSelector((state) => state);
    const [position, setPosition] = useState<PointModel>({x: 0, y: 0});
    const [scale, setScale] = useState<number>(SCALE_DEFAULT);
    const groupContainer = useRef(null);

    useEffect(() => {
        if (visualArea.toImageView) {
            return;
        }
        if (visualArea.groupPosition === null || visualArea.scale == null) {
            return;
        }
        let groupPos = checkVAGroupPos(visualArea, {x: visualArea.groupPosition.x, y: visualArea.groupPosition.y});
        if (JSON.stringify(groupPos) !== JSON.stringify(visualArea.groupPosition)) {
            dispatch(setGroupPosition(groupPos));
        }
    }, [dispatch, visualArea, visualArea.scale, visualArea.groupPosition, visualArea.paintDimensions, visualArea.absBGDimensions]);

    useEffect(() => {
        if (visualArea.groupPosition === null) {
            return;
        }
        let groupPos = visualArea.groupPosition;
        if (groupPos.x !== position.x || groupPos.y !== position.y) {
            setPosition(groupPos);
        }
        if (groupContainer.current !== null) {
            const cur: any = groupContainer.current;
            cur.position(groupPos);
        }
    }, [position, visualArea.groupPosition]);

    useEffect(() => {
        if (visualArea.toImageView && visualArea.groupPosition !== null) {
            return;
        }
        if (visualArea.groupPosition === null) {
            if (visualArea.paintDimensions.width <= 0 || visualArea.paintDimensions.height <= 0 ||
                    visualArea.absBGDimensions.width <= 0 || visualArea.absBGDimensions.height <= 0 ||
                    visualArea.scale === null) {
                return;
            }
            let x = (visualArea.paintDimensions.width - (visualArea.absBGDimensions.width * visualArea.scale)) / 2;
            let y = visualArea.paintDimensions.height * 2 / 3;
            if (app.minMax !== null && project.products.length > 0) {
                x = -((app.minMax.xMin + (app.minMax.width / 2)) * visualArea.scale) + (visualArea.paintDimensions.width / 2);
                y = -((app.minMax.yMin + (app.minMax.height / 2)) * visualArea.scale) + (visualArea.paintDimensions.height / 2);
            }
            let groupPos = checkVAGroupPos(visualArea, {x: x, y: y});
            dispatch(setGroupPosition(groupPos));
            return;
        }
        let groupPos = checkVAGroupPos(visualArea, visualArea.groupPosition);
        if (visualArea.groupPosition.x !== groupPos.x || visualArea.groupPosition.y !== groupPos.y) {
            dispatch(setGroupPosition(groupPos));
        }
    }, [dispatch, visualArea, visualArea.groupPosition, visualArea.paintDimensions, visualArea.absBGDimensions,
            visualArea.beachLength, visualArea.scale, app.minMax, project.products]);

    useEffect(() => {
        if (visualArea.scale === null) {
            return;
        }
        setScale(visualArea.scale);
    }, [visualArea.scale]);

    useEffect(() => {
        let idr = true;
        if (visualArea.productDraggable !== null) {
            idr = false;
        }
        if (idr !== visualArea.groupDraggable) {
            dispatch(setGroupDraggable(idr));
        }
    }, [dispatch, visualArea.productDraggable, visualArea.groupDraggable]);

    const handleChangeDragMove = (data: any) => {
        if (data === null || data.getLayer() === null) {
            return;
        }
        if (!visualArea.groupDraggable) {
            if (visualArea.groupPosition !== null) {
                data.position(visualArea.groupPosition);
            }
            return;
        }
        let groupPos = checkVAGroupPos(visualArea, {x: data.x(), y: data.y()});
        if (visualArea.groupPosition === null ||
                visualArea.groupPosition.x !== groupPos.x || visualArea.groupPosition.y !== groupPos.y) {
            dispatch(setGroupPosition(groupPos));
        }
        data.position(groupPos);
    };

    const handleClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
        e.cancelBubble = true;
        e.evt.preventDefault();

        if (visualArea.productActive !== null) {
            dispatch(setProductActive(null));
        }
        if (visualArea.productDraggable !== null) {
            dispatch(setProductDraggable(null));
        }
    };

    if (!visualArea.groupDraggable) {
        return (
                <Group x={position.x} y={position.y}
                    ref={groupContainer}
                    onClick={handleClick}
                    onTouchstart={handleClick}
                    scaleX={scale}
                    scaleY={scale}>
                    <VisualAreaBackground />
                    <VisualAreaMeasurement />
                    <VisualAreaProducts />
                </Group>
                );
    }
    return (
            <Group x={position.x} y={position.y}
                draggable={visualArea.groupDraggable}
                onDragMove={e => {handleChangeDragMove(e.target)}}
                ref={groupContainer}
                onClick={handleClick}
                onTouchstart={handleClick}
                scaleX={scale}
                scaleY={scale}>
                <VisualAreaBackground />
                <VisualAreaMeasurement />
                <VisualAreaProducts />
            </Group>
            );
};

export default VisualAreaGroup;
