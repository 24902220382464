import {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../app/hooks";
import {MenuContainer} from "./Container/MenuContainer";
import {setMenuDimensions} from "../../features/app-slice";
import useRefDimensions, {UseRefDimensionsModel} from "../../utils/useRefDimensions";

import './Menu.scss';

export const Menu = () => {
    const dispatch = useDispatch();
    const {footerDimensions, small, menuDimensions} = useAppSelector((state) => state.app);
    const refDimensions: UseRefDimensionsModel = useRefDimensions();

    useEffect(() => {
        if (refDimensions.dimensions === undefined) {
            return;
        }
        if (refDimensions.dimensions.width === undefined || refDimensions.dimensions.width <= 0 ||
            refDimensions.dimensions.height === undefined || refDimensions.dimensions.height <= 0) {
            return;
        }
        dispatch(setMenuDimensions(refDimensions.dimensions));
    }, [dispatch, menuDimensions, refDimensions.dimensions]);
    
    return (
            <div ref={refDimensions.ref} className="ams-menu" style={{bottom: small ? undefined : footerDimensions.height}}>
                <MenuContainer />
            </div>
            );
};

export default Menu;
