import {CSSProperties, useState} from 'react';
import ReactPlayer from 'react-player'
import { useDispatch } from "react-redux";
import {afterStartMenu, setCurrentMenu} from "../../features/app-slice";

import videoStillPng from "../../assets/start/video_still.png";
import videoMp from "../../assets/start/istockphoto-1437928254-640_adpp_is.mp4";

import './Start.scss';

export const Start = () => {
    const dispatch = useDispatch();
    const [playerStarted, setPlayerStartet] = useState<boolean>(false);

    const handleBGClick = () => {
        if (playerStarted) {
            return;
        }
        setPlayerStartet(true);
    };

    const handleVideoEnded = () => {
        setTimeout(() => {
            dispatch(setCurrentMenu(afterStartMenu));
        }, 1000);
    };

    return (
            <div className="ams-stegplaner-start"
                    onClick={handleBGClick}
                    style={{
                        "--image-background": `url(${videoStillPng})`
                    } as CSSProperties}>
                {
                    playerStarted &&
                    <ReactPlayer url={videoMp} playing={true}
                            width={"100%"} height={"100%"}
                            onEnded={handleVideoEnded} />
                }
            </div>);
};

export default Start;
