import {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../app/hooks";
import { useDispatch } from "react-redux";
import { ProductsModel, ProductsVariantsModel } from "../../../../data/Products";
import {addProduct} from "../../../../features/project-slice";
import {setProductActive, setProductDraggable} from "../../../../features/visual-area-slice";
import {gridPoint} from "../../../../utils/global";
import useTranslate from "../../../../utils/useTranslate"
import Infos, {InfosProps} from "../Infos/Infos"
import ProductExampleBoats from "../../../shared/Products/ExampleBoats/ProductExampleBoats";
import { ProjectExampleBoatsModel } from "../../../../data/products/ExampleBoats";

import './MenuItemExampleBoats.scss';

export const MenuItemExampleBoats = () => {
    const {t} = useTranslate();
    const dispatch = useDispatch();
    const {visualArea, project} = useAppSelector((state) => state);
    const [addedProduct, setAddedProduct] = useState<number | null>(null);
    const [showInfoForProduct, setShowInfoForProduct] = useState<InfosProps | null>(null);

    useEffect(() => {
        if (addedProduct === null) {
            return;
        }
        dispatch(setProductActive(addedProduct));
        dispatch(setProductDraggable(addedProduct));
        setAddedProduct(null);
    }, [dispatch, addedProduct]);

    const addExampleBoats = (iExampleBoats: ProductsModel, isExampleBoats: ProductsVariantsModel) => {
        if (visualArea.groupPosition === null || visualArea.scale === null) {
            return;
        }

        let x = ((visualArea.paintDimensions.width / 2) - visualArea.groupPosition.x) / visualArea.scale;
        let y = ((visualArea.paintDimensions.height / 2) - visualArea.groupPosition.y) / visualArea.scale;
        let newProduct: ProjectExampleBoatsModel = {
            group: iExampleBoats.group,
            typ: iExampleBoats.typ,
            variant: isExampleBoats.typ,
            x: x,
            y: y,
            rotate: 0
        };
        gridPoint(newProduct, iExampleBoats.grid);

        dispatch(addProduct(newProduct));
        setAddedProduct(project.products.length);
    };

    return (
        <div className="ams-menu-item-example_boats">
            <div className="ams-menu-item-example_boats-title">
                {t('Beispielboote')}
            </div>
            {
                showInfoForProduct === null &&
                <>
                    <div className="ams-menu-item-example_boats-items">
                        <ProductExampleBoats selectProductVariant={addExampleBoats}  />
                    </div>
                </>
            }
            {
                showInfoForProduct !== null &&
                <Infos title={showInfoForProduct.title} info={showInfoForProduct.info} onClose={() => {setShowInfoForProduct(null);}} />
            }
        </div>
    );
};

export default MenuItemExampleBoats;
