import {useEffect, useState} from 'react';
import {getBoatLiftFromProduct} from "../../../../data/products/BoatLift";
import {ProductsModel, ProductsVariantsModel} from "../../../../data/Products";
import {Group, Image} from "react-konva";
import useImage from 'use-image';
import {vaProductProps} from "../VisualAreaProduct";
import VisualAreaProductEdit from "../VisualAreaProductEdit";

const vaBoatLiftLayer = 5;
const vaBoatLiftLayerActive = 8;

export const showVisualAreaBoatLift = (productLayer: number, isActive: boolean): boolean => {
    if (isActive) {
        if (productLayer !== vaBoatLiftLayerActive) {
            return false;
        }
        return true;
    }
    if (productLayer !== vaBoatLiftLayer) {
        return false;
    }
    return true;
};

export const VisualAreaBoatLift = ({product, productKey, productLayer, productUtils}: vaProductProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [slift, setSlift] = useState<ProductsModel | null>(null);
    const [svlift, setSvlift] = useState<ProductsVariantsModel | null>(null);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [image] = useImage(imageSrc);
    const [barLayer, setBarLayer] = useState<number>(vaBoatLiftLayer);
    const [rectX, setRectX] = useState<number>(0);
    const [rectY, setRectY] = useState<number>(0);
    const [rectWidth, setRectWidth] = useState<number>(0);
    const [rectHeight, setRectHeight] = useState<number>(0);

    useEffect(() => {
        let newBarLayer = productUtils.isActive ? vaBoatLiftLayerActive : vaBoatLiftLayer;
        if (barLayer !== newBarLayer) {
            setBarLayer(newBarLayer);
        }
    }, [productUtils.isActive, barLayer]);

    useEffect(() => {
        let newShow = (productLayer === barLayer);
        if (newShow !== show) {
            setShow(newShow);
        }
    }, [show, productLayer, barLayer]);

    useEffect(() => {
        if (!show) {
            return;
        }
        const stageLifts = getBoatLiftFromProduct(product);
        if (stageLifts === null) {
            setSlift(null);
            setSvlift(null);
            return;
        }
        const [nslift, nsvlift] = stageLifts;
        setSlift(nslift);
        setSvlift(nsvlift);
    }, [show, product, productKey]);

    useEffect(() => {
        if (!show || productLayer !== barLayer) {
            return;
        }
        if (svlift === null) {
            if (imageSrc !== '') {
                setImageSrc('');
            }
            return;
        }
        if (imageSrc !== svlift.imageVisual) {
            setImageSrc(svlift.imageVisual);
        }
    }, [show, svlift, imageSrc, barLayer, productLayer]);

    useEffect(() => {
        if (!show || slift === null) {
            return;
        }
        let newRectX = -slift.widthDraw / 2;
        if (newRectX !== rectX) {
            setRectX(newRectX);
        }
    }, [show, rectX, slift]);

    useEffect(() => {
        if (!show || slift === null) {
            return;
        }
        let newRectY = -slift.heightDraw / 2;
        if (newRectY !== rectY) {
            setRectY(newRectY);
        }
    }, [show, rectY, slift]);

    useEffect(() => {
        if (!show || slift === null) {
            return;
        }
        let newRectWidth = slift.widthDraw;
        if (newRectWidth !== rectWidth) {
            setRectWidth(newRectWidth);
        }
    }, [show, rectWidth, slift]);

    useEffect(() => {
        if (!show || slift === null) {
            return;
        }
        let newRectHeight = slift.heightDraw;
        if (newRectHeight !== rectHeight) {
            setRectHeight(newRectHeight);
        }
    }, [show, rectHeight, slift]);

    if (!show || slift === null || svlift === null) {
        return (null);
    }

    return (
            <>
                <Group x={0} y={0} rotation={productUtils.rotate}>
                    {
                        productLayer === barLayer &&
                        <Image
                            x={rectX} y={rectY}
                            width={rectWidth} height={rectHeight}
                            image={image} />
                    }
                </Group>
                {
                    productUtils.isActive &&
                    <VisualAreaProductEdit
                        productKey={productKey} product={product}
                        pmodel={slift} pvmodel={svlift} offset={70} />
                }
            </>
    );
};

export default VisualAreaBoatLift;
