import {useEffect, useState} from 'react';
import {useAppSelector} from "../../../app/hooks";
import {useDispatch} from "react-redux";
import {setModalProductVariant} from "../../../features/visual-area-slice";
import useTranslate from "../../../utils/useTranslate"
import {ProductGroupsTypes, ProjectProductsModel} from "../../../data/Products";
import Button from 'react-bootstrap/Button';
import VisualAreaModalVariantModule from "./Module/VisualAreaModalVariantModule";
import SPModal from "../../shared/SPModal/SPModal";

export const VisualAreaModalVariant = () => {
    const dispatch = useDispatch();
    const {products} = useAppSelector((state) => state.project);
    const {productActive, modalProductVariant} = useAppSelector((state) => state.visualArea);
    const [show, setShow] = useState<boolean>(false);
    const [product, setProduct] = useState<ProjectProductsModel | null>(null);
    const {t} = useTranslate();

    useEffect(() => {
        let newShow = product !== null && modalProductVariant;
        if (show !== newShow) {
            setShow(newShow);
        }
        if (!newShow && modalProductVariant) {
            dispatch(setModalProductVariant(false));
        }
    }, [show, product, modalProductVariant, dispatch]);

    useEffect(() => {
        if (productActive === null || products[productActive] === undefined) {
            if (product !== null) {
                setProduct(null);
            }
            return;
        }
        if (JSON.stringify(product) !== JSON.stringify(products[productActive])) {
            setProduct(products[productActive]);
        }
    }, [product, productActive, products]);

    const handleClose = () => {
        dispatch(setModalProductVariant(false));
    };

    const getCurrentProduct = (): JSX.Element | null => {
        if (product === null || productActive === null) {
            return (null);
        }
        switch (product.group) {
            case ProductGroupsTypes.TYP_MODULE:
                return <VisualAreaModalVariantModule productKey={productActive} product={product} close={handleClose} />;
        }
        return null;
    };

    return (
        <SPModal show={show} onHide={handleClose}
            header={t('Wählen Sie eine andere Variante.')}
            body={getCurrentProduct()}
            footer={
                <Button variant="primary" onClick={handleClose}>
                    {t('Schließen')}
                </Button>} />
    );
};

export default VisualAreaModalVariant;
