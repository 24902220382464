import {ProjectStateModel} from '../features/project-slice';
import encodeProject from '../app/encodeProject';
import decodeProject from '../app/decodeProject';

export const sessionStoreKey = 'amsStegplaner';

export const getSessionStoreValue = (): ProjectStateModel | null => {
    let storageValue: string | null = sessionStorage.getItem(sessionStoreKey);

    if(!storageValue) return null;

    return decodeProject(JSON.parse(storageValue));
};

export const setSessionStoreValue = (projectData: ProjectStateModel) => {
    sessionStorage.setItem(sessionStoreKey, JSON.stringify(encodeProject(projectData)));
};
