import {useEffect, useState} from 'react';
import {getExampleBoatsFromProduct, ExampleBoatsTypes} from "../../../../data/products/ExampleBoats";
import {ProductsModel, ProductsVariantsModel} from "../../../../data/Products";
import {Group, Image} from "react-konva";
import useImage from 'use-image';
import {vaProductProps} from "../VisualAreaProduct";
import VisualAreaProductEdit from "../VisualAreaProductEdit";

const vaExampleBoatsLayer = 7;
const vaExampleBoatsLayerActive = 8;

export const showVisualAreaExampleBoats = (productLayer: number, isActive: boolean): boolean => {
    if (isActive) {
        if (productLayer !== vaExampleBoatsLayerActive) {
            return false;
        }
        return true;
    }
    if (productLayer !== vaExampleBoatsLayer) {
        return false;
    }
    return true;
};

export const VisualAreaExampleBoats = ({product, productKey, productLayer, productUtils}: vaProductProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [sboat, setSboat] = useState<ProductsModel | null>(null);
    const [svboat, setSvboat] = useState<ProductsVariantsModel | null>(null);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [image] = useImage(imageSrc);
    const [barLayer, setBarLayer] = useState<number>(vaExampleBoatsLayer);
    const [rectX, setRectX] = useState<number>(0);
    const [rectY, setRectY] = useState<number>(0);
    const [rectWidth, setRectWidth] = useState<number>(0);
    const [rectHeight, setRectHeight] = useState<number>(0);
    const [rotate, setRotate] = useState<number>(productUtils.rotate);

    useEffect(() => {
        let newRotate = productUtils.rotate;
        if (product.typ === ExampleBoatsTypes.EB_SUP) {
            newRotate += 180.0;
        }
        if (newRotate !== rotate) {
            setRotate(newRotate);
        }
    }, [product.typ, productUtils.rotate, rotate]);

    useEffect(() => {
        let newBarLayer = productUtils.isActive ? vaExampleBoatsLayerActive : vaExampleBoatsLayer;
        if (barLayer !== newBarLayer) {
            setBarLayer(newBarLayer);
        }
    }, [productUtils.isActive, barLayer]);

    useEffect(() => {
        let newShow = (productLayer === barLayer);
        if (newShow !== show) {
            setShow(newShow);
        }
    }, [show, productLayer, barLayer]);

    useEffect(() => {
        if (!show) {
            return;
        }
        const exampleBoats = getExampleBoatsFromProduct(product);
        if (exampleBoats === null) {
            setSboat(null);
            setSvboat(null);
            return;
        }
        const [nsboat, nsvboat] = exampleBoats;
        setSboat(nsboat);
        setSvboat(nsvboat);
    }, [show, product, productKey]);

    useEffect(() => {
        if (!show || productLayer !== barLayer) {
            return;
        }
        if (svboat === null) {
            if (imageSrc !== '') {
                setImageSrc('');
            }
            return;
        }
        if (imageSrc !== svboat.imageVisual) {
            setImageSrc(svboat.imageVisual);
        }
    }, [show, svboat, imageSrc, barLayer, productLayer]);

    useEffect(() => {
        if (!show || sboat === null) {
            return;
        }
        let newRectX = -sboat.widthDraw / 2;
        if (newRectX !== rectX) {
            setRectX(newRectX);
        }
    }, [show, rectX, sboat]);

    useEffect(() => {
        if (!show || sboat === null) {
            return;
        }
        let newRectY = -sboat.heightDraw / 2;
        if (newRectY !== rectY) {
            setRectY(newRectY);
        }
    }, [show, rectY, sboat]);

    useEffect(() => {
        if (!show || sboat === null) {
            return;
        }
        let newRectWidth = sboat.widthDraw;
        if (newRectWidth !== rectWidth) {
            setRectWidth(newRectWidth);
        }
    }, [show, rectWidth, sboat]);

    useEffect(() => {
        if (!show || sboat === null) {
            return;
        }
        let newRectHeight = sboat.heightDraw;
        if (newRectHeight !== rectHeight) {
            setRectHeight(newRectHeight);
        }
    }, [show, rectHeight, sboat]);

    if (!show || sboat === null || svboat === null) {
        return (null);
    }

    return (
            <>
                <Group x={0} y={0} rotation={rotate}>
                    {
                        productLayer === barLayer &&
                        <Image
                            x={rectX} y={rectY}
                            width={rectWidth} height={rectHeight}
                            image={image} />
                    }
                </Group>
                {
                    productUtils.isActive &&
                    <VisualAreaProductEdit
                        productKey={productKey} product={product}
                        pmodel={sboat} pvmodel={svboat} offset={70} />
                }
            </>
    );
};

export default VisualAreaExampleBoats;
