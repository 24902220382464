import {CSSProperties, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../app/hooks";
import useTranslate from "../../../../utils/useTranslate"
import {setMenuRightDimensions} from "../../../../features/app-slice";
import useRefDimensions, {UseRefDimensionsModel} from "../../../../utils/useRefDimensions";
import MenuItems from "../../Items/MenuItems";

import logoPng from "../../../../assets/logo/logo.png";
import menuPanelPng from "../../../../assets/menu/panel.png";
//import menuPanelSchraubePng from "../../../../assets/menu/panel_schraube.png";

import './MenuBodyRight.scss';

export const MenuBodyRight = () => {
    const dispatch = useDispatch();
    const {t} = useTranslate();
    const {menuRightDimensions} = useAppSelector((state) => state.app);
    const refHeadDimensions: UseRefDimensionsModel = useRefDimensions();
    const refRightDimensions: UseRefDimensionsModel = useRefDimensions();
    const [offheight, setOffHeight] = useState<number>(10);

    useEffect(() => {
        if (refHeadDimensions.dimensions === undefined) {
            return;
        }
        if (refHeadDimensions.dimensions.width === undefined || refHeadDimensions.dimensions.width <= 0 ||
                refHeadDimensions.dimensions.height === undefined || refHeadDimensions.dimensions.height <= 0) {
            return;
        }
        let newOffheight = refHeadDimensions.dimensions.height + 20;
        if (newOffheight !== offheight) {
            setOffHeight(newOffheight);
        }
    }, [refHeadDimensions.dimensions, offheight]);

    useEffect(() => {
        if (refRightDimensions.dimensions === undefined) {
            return;
        }
        if (refRightDimensions.dimensions.width === undefined || refRightDimensions.dimensions.width <= 0 ||
                refRightDimensions.dimensions.height === undefined || refRightDimensions.dimensions.height <= 0) {
            return;
        }
        dispatch(setMenuRightDimensions(refRightDimensions.dimensions));
    }, [dispatch, refRightDimensions.dimensions, menuRightDimensions]);

    return (
            <div ref={refRightDimensions.ref} className="ams-menu-body-right">
                <div className="ams-menu-body-right-body"
                        style={{
                            "--image-background": `url(${menuPanelPng})`
                        } as CSSProperties}>
                    <div ref={refHeadDimensions.ref}>
                        <div className="ams-mbrb-head"
                                style={{
                                    "--image-background": `url(${logoPng})`
                                } as CSSProperties}>
                            <div className={"ams-mbrb-head-title"}>{t('Stegplaner')}</div>
                        </div>
                    </div>
                    <div className="ams-mbrb-body" style={{height: `calc(100% - ${offheight}px)`}}>
                        <div className={'ams-mbrb-body-container'}>
                            <MenuItems />
                        </div>
                    </div>
                </div>
            </div>
            );
};
//                        <img src={menuPanelSchraubePng} style={{position: 'absolute', top: 5, left: 10, width: 20, height: 20}} alt={t('Schraube')} />
//                        <img src={menuPanelSchraubePng} style={{position: 'absolute', top: 5, right: 10, width: 20, height: 20}} alt={t('Schraube')} />
//                        <img src={menuPanelSchraubePng} style={{position: 'absolute', bottom: 5, left: 10, width: 20, height: 20}} alt={t('Schraube')} />
//                        <img src={menuPanelSchraubePng} style={{position: 'absolute', bottom: 5, right: 10, width: 20, height: 20}} alt={t('Schraube')} />

export default MenuBodyRight;
