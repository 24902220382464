import {useState, useEffect} from 'react';
import useTranslate, {TranslateKeyType} from "../../../../utils/useTranslate";
import {InfoCircleFill as BSIconInfoCircle} from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';

import './Infos.scss';

export interface InfosProps {
    title: TranslateKeyType;
    info: TranslateKeyType;
    onClose?: () => void;
};

export const Infos = ({title, info, ...props}: InfosProps) => {
    const[haveOnClose, setHaveOnClose] = useState<boolean>(false);
    const {t} = useTranslate();

    useEffect(() => {
        let h = (props.onClose !== undefined);
        if (haveOnClose !== h) {
            setHaveOnClose(h);
        }
    }, [props, haveOnClose]);

    const handleClose = () => {
        if (props.onClose !== undefined) {
            props.onClose();
        }
    };

    return (
        <div className={'ams-menu-infos'}>
            <div className="ams-menu-infos-title">
                {t(title)}
                {
                    haveOnClose &&
                    <BSIconInfoCircle onClick={handleClose} />
                }
            </div>
            <div className="ams-menu-infos-info">
                <div dangerouslySetInnerHTML={{__html: t(info)}}></div>
            </div>
            <div className="ams-menu-infos-close">
                <Button variant="primary" onClick={handleClose}>
                    {t('Schließen')}
                </Button>
            </div>
        </div>
    );
};

export default Infos;
