import { configureStore } from '@reduxjs/toolkit';
import projectReducer from '../features/project-slice';
import appReducer from '../features/app-slice';
import visualAreaReducer from '../features/visual-area-slice';

export const store = configureStore({
    reducer: {
        project: projectReducer,
        app: appReducer,
        visualArea: visualAreaReducer
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
