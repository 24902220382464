import {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../app/hooks";
import {PointModel} from "../../../models/Global";
import {gridPoint} from "../../../utils/global";
import {changeProduct} from "../../../features/project-slice";
import {setProductActive, setProductDraggable} from "../../../features/visual-area-slice";
import {ProjectProductsModel, ProductsModel, ProductsVariantsModel} from "../../../data/Products";
import Konva from 'konva';
import {getModelFromProduct} from "../../../utils/global";

export interface VAProductsUtilsResultProps {
    isDraggable: boolean;
    isActive: boolean;
    handleChangeDragMove: (event: Konva.KonvaEventObject<MouseEvent>, grid: number) => void;
    handleClick: (event: Konva.KonvaEventObject<MouseEvent>) => void;
    handleTouchstart: (event: Konva.KonvaEventObject<TouchEvent>) => void;
    handleTouchEnd: (event: Konva.KonvaEventObject<TouchEvent>) => void;
    handleMouseDown: (event: Konva.KonvaEventObject<MouseEvent>) => void;
    handleMouseUp: (event: Konva.KonvaEventObject<MouseEvent>) => void;
    handleMouseenter: (data: any) => void;
    handleMouseleave: (data: any) => void;
    pModel: ProductsModel | null;
    pvModel: ProductsVariantsModel | null;
    rotate: number;
    position: PointModel;
};

export const VisualAreaProductUtils = (product: ProjectProductsModel, productKey: number): VAProductsUtilsResultProps => {
    const dispatch = useDispatch();
    const {productActive, productDraggable} = useAppSelector((state) => state.visualArea);
    const {isTouch} = useAppSelector((state) => state.app);
    const [isDraggable, setIsDraggable] = useState<boolean>(productDraggable === productKey);
    const [isActive, setIsActive] = useState<boolean>(productActive === productKey);
    const [pModel, setPmodule] = useState<ProductsModel | null>(null);
    const [pvModel, setPvmodule] = useState<ProductsVariantsModel | null>(null);
    const [rotate, setRotate] = useState<number>(0);
    const [position, setPosition] = useState<PointModel>({x: 0, y: 0});

    useEffect(() => {
        let newIsDraggable = (productKey === productDraggable || productDraggable === null);
        if (isTouch) {
            newIsDraggable = productKey === productDraggable;
        }
        if (newIsDraggable !== isDraggable) {
            setIsDraggable(newIsDraggable);
        }
    }, [isTouch, isDraggable, productDraggable, productKey]);

    useEffect(() => {
        let newIsActive = (productKey === productActive);
        if (newIsActive !== isActive) {
            setIsActive(newIsActive);
        }
    }, [isActive, productActive, productKey]);

    useEffect(() => {
        let newRotate = product.rotate === null ? 0 : product.rotate;
        if (newRotate !== rotate) {
            setRotate(newRotate);
        }
        const ppvModel = getModelFromProduct(product);
        if (ppvModel === null) {
            setPmodule(null);
            setPvmodule(null);
            return;
        }
        const [npModel, npvModel] = ppvModel;
        setPmodule(npModel);
        setPvmodule(npvModel);
    }, [product, productKey, rotate]);

    useEffect(() => {
        if (product === null) {
            return;
        }
        let itemPos = {x: product.x, y: product.y};
        if (itemPos.x !== position.x || itemPos.y !== position.y) {
            setPosition(itemPos);
        }
    }, [position, product]);

    const handleChangeDragMove = (event: Konva.KonvaEventObject<MouseEvent>, grid: number) => {
        const data: any = event.target;
        if (data === null || data.getLayer() === null) {
            return;
        }
        if (!isDraggable) {
            if (product !== null) {
                let newPos: PointModel = {x: product.x, y: product.y};
                data.position(newPos);
            }
            return;
        }

        let newPos: PointModel = {x: data.x(), y: data.y()};
        gridPoint(newPos, grid);
        data.position(newPos);

        if (product === null) {
            return;
        }
        let cProduct: ProjectProductsModel = Object.assign({}, product);
        cProduct.x = newPos.x;
        cProduct.y = newPos.y;
        dispatch(changeProduct({index: productKey, product: cProduct}));
    };
    const handleClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
        event.cancelBubble = true;
        event.evt.preventDefault();

        if (productActive !== productKey) {
            dispatch(setProductActive(productKey));
        }
        document.body.style.cursor = 'pointer';
    };
    const handleTouchstart = (event: Konva.KonvaEventObject<TouchEvent>) => {
        event.cancelBubble = true;
        event.evt.preventDefault();

        if (productActive !== productKey && productActive !== null) {
            dispatch(setProductActive(null));
        }
        if (productDraggable !== productKey) {
            dispatch(setProductDraggable(productKey));
        }
    };
    const handleTouchEnd = (event: Konva.KonvaEventObject<TouchEvent>) => {
        event.cancelBubble = true;
        event.evt.preventDefault();

        if (productActive !== productKey) {
            dispatch(setProductActive(productKey));
        }
        if (productDraggable !== productKey) {
            dispatch(setProductDraggable(productKey));
        }
    };
    const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
        event.cancelBubble = true;
        event.evt.preventDefault();

        if (productActive !== productKey && productActive !== null) {
            dispatch(setProductActive(null));
        }
    };
    const handleMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
        event.cancelBubble = true;
        event.evt.preventDefault();

        if (productActive !== productKey) {
            dispatch(setProductActive(productKey));
        }
    };
    const handleMouseenter = (data: any): void => {
        if (productDraggable !== productKey) {
            dispatch(setProductDraggable(productKey));
        }
        document.body.style.cursor = 'pointer';
    };
    const handleMouseleave = (data: any): void => {
        if (productDraggable !== null) {
            dispatch(setProductDraggable(null));
        }
        document.body.style.cursor = 'default';
    };

    return {
        isDraggable: isDraggable,
        isActive: isActive,
        handleChangeDragMove: handleChangeDragMove,
        handleClick: handleClick,
        handleTouchstart: handleTouchstart,
        handleTouchEnd: handleTouchEnd,
        handleMouseDown: handleMouseDown,
        handleMouseUp: handleMouseUp,
        handleMouseenter: handleMouseenter,
        handleMouseleave: handleMouseleave,
        pModel: pModel,
        pvModel: pvModel,
        rotate: rotate,
        position: position
    };
};

export default VisualAreaProductUtils;
