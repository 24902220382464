import {useEffect, useState} from "react";
import {useAppSelector} from "./app/hooks";
import {useDispatch} from "react-redux";
import {setDimensions, setSmall, MenuTypes} from "./features/app-slice";
import useRefDimensions, {UseRefDimensionsModel} from "./utils/useRefDimensions";
import UpdateApp from "./utils/UpdateApp";

import './i18n/i18n';

import VisualArea from './components/VisualArea/VisualArea';
import Menu from './components/Menu/Menu';
import Footer from './components/Footer/Footer';
import Start from './components/Start/Start';
import VisualArea3D from './components/VisualArea3D/VisualArea3D';

import './sass/App.scss';

const App = () => {
    const dispatch = useDispatch();
    const {small, currentMenu, isStart, dimensions} = useAppSelector((state) => state.app);
    const [className, setClassName] = useState<string>('ams-stegplaner-app');
    const refDimensions: UseRefDimensionsModel = useRefDimensions();

    useEffect(() => {
        if (refDimensions.dimensions === undefined) {
            return;
        }
        if (refDimensions.dimensions.width === undefined || refDimensions.dimensions.width <= 0 ||
            refDimensions.dimensions.height === undefined || refDimensions.dimensions.height <= 0) {
            return;
        }
        dispatch(setDimensions(refDimensions.dimensions));

        let newSmall = false;
        if (refDimensions.dimensions.width <= 1024) {
            newSmall = true;
        }
        if (newSmall !== small) {
            dispatch(setSmall(newSmall));
        }
    }, [dispatch, dimensions, refDimensions.dimensions, small]);

    useEffect(() => {
        let newClassName = 'ams-stegplaner-app';
        let cmstr = MenuTypes[currentMenu];
        if (typeof cmstr === 'string') {
            newClassName += ' ams-stegplaner-current-menu-' + cmstr.toLowerCase();
        }
        if (small) {
            newClassName += ' ams-stegplaner-app-small';
        }
        if (newClassName !== className) {
            setClassName(newClassName);
        }
    }, [small, className, currentMenu]);

    if (isStart) {
        return (
                <div ref={refDimensions.ref} className={className}>
                    <Menu />
                    {
                        small &&
                        <Start />
                    }
                    <Footer />
                    {
                        !small &&
                        <Start />
                    }
                    <UpdateApp />
                </div>
                );
    }

    if (currentMenu === MenuTypes.VIEW_3D) {
        return (
                <div ref={refDimensions.ref} className={className}>
                    <VisualArea3D />
                    <UpdateApp />
                </div>
                );
    }

    return (
            <div ref={refDimensions.ref} className={className}>
                <Menu />
                <VisualArea />
                <Footer />
                <UpdateApp />
            </div>
            );
};

export default App;
