import {ProductGroupsTypes, ProductsModel, ProjectProductsModel, ProductsVariantsModel} from "../Products";
import {getCoordinates} from "../../utils/Products";

import boarding_aid from "../../assets/products/boarding_aid/boarding_aid.png";

export enum BoardingAidTypes {
    BA_STANDARD
};
export enum SubBoardingAidTypes {
    SBA_STANDARD
};

export interface ProjectBoardingAidModel extends ProjectProductsModel {
    group: ProductGroupsTypes;
};

let BoardingAidsShortInfoDe = '<div>';
BoardingAidsShortInfoDe += '<p>';
BoardingAidsShortInfoDe += 'Keine Probleme, sondern Aufgaben.';
BoardingAidsShortInfoDe += '</p>';
BoardingAidsShortInfoDe += '<p>';
BoardingAidsShortInfoDe += 'Unser Team liebt Herausforderungen und so entstand auf Kundenwunsch diese stabile Einstiegshilfe für Kajaks und Kanus passend zu unserem Steganlagensystem.';
BoardingAidsShortInfoDe += '</p>';
BoardingAidsShortInfoDe += '</div>';

let BoardingAidsInfoDe = '<div>';
BoardingAidsInfoDe += '<p>';
BoardingAidsInfoDe += 'Keine Probleme, sondern Aufgaben.';
BoardingAidsInfoDe += '</p>';
BoardingAidsInfoDe += '<p>';
BoardingAidsInfoDe += 'Unser Team liebt Herausforderungen und so entstand auf Kundenwunsch diese stabile Einstiegshilfe für Kajaks und Kanus passend zu unserem Steganlagensystem.';
BoardingAidsInfoDe += '</p>';
BoardingAidsInfoDe += '</div>';

export const BoardingAids: ProductsModel[] = [
    {
        group: ProductGroupsTypes.TYP_BOARDING_AID,
        typ: BoardingAidTypes.BA_STANDARD,
        title: {
            de: 'SUP & Kajak Einstiegshilfe'
        },
        info: {
            de: BoardingAidsInfoDe
        },
        width: 764,
        widthDraw: 1500 * 764 / 1100,
        height: 1100,
        heightDraw: 1500,
        grid: 125,
        rotate: 90,
        variants: [
            {
                typ: SubBoardingAidTypes.SBA_STANDARD,
                title: {
                    de: ''
                },
                shortInfo: BoardingAidsShortInfoDe,
                imageMenu: boarding_aid,
                imageVisual: boarding_aid
            },
        ],
        getCoordinates: getCoordinates
    }
];

export const getBoardingAidFromProduct = (product: ProjectProductsModel): [ProductsModel, ProductsVariantsModel] | null => {
    let result: [ProductsModel, ProductsVariantsModel] | null = null;

    BoardingAids.forEach(function (said: ProductsModel) {
        if (result !== null || said.typ !== product.typ) {
            return;
        }
        said.variants.forEach(function (svaid: ProductsVariantsModel) {
            if (result !== null || svaid.typ !== product.variant) {
                return;
            }
            result = [said, svaid];
        });
    });

    return result;
};