import {useEffect} from "react";
import {useAppSelector} from "../app/hooks";
import {useDispatch} from "react-redux";
import {updateIsTouch} from "../features/app-slice";
import SessionStoreValue from "./UpdateApp/SessionStoreValue";
import ProductActiveDraggable from "./UpdateApp/ProductActiveDraggable";
import MinMax from "./UpdateApp/MinMax";
import CheckVersionApp from "./UpdateApp/CheckVersionApp";

const UpdateApp = () => {
    const dispatch = useDispatch();
    const {app} = useAppSelector((state) => state);

    SessionStoreValue();
    ProductActiveDraggable();
    MinMax();
    CheckVersionApp();

    useEffect(() => {
        dispatch(updateIsTouch());
    }, [dispatch, app.dimensions]);

    return (null);
};

export default UpdateApp;
