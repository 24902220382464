import {PointModel} from "../models/Global";
import {ProjectProductsModel, ProductsModel, ProductsVariantsModel, ProductGroupsTypes} from "../data/Products";
import {getStageModulesFromProduct} from "../data/products/StageModule";
import {getBoatLiftFromProduct} from "../data/products/BoatLift";
import {getBathingLadderFromProduct} from "../data/products/BathingLadder";
import {getExampleBoatsFromProduct} from "../data/products/ExampleBoats";
import {getBoardingAidFromProduct} from "../data/products/BoardingAid";

export const getModelFromProduct = (product: ProjectProductsModel): [ProductsModel, ProductsVariantsModel | null] | null => {
    switch (product.group) {
        case ProductGroupsTypes.TYP_MODULE:
            return getStageModulesFromProduct(product);
        case ProductGroupsTypes.TYP_BOAT_LIFT:
            return getBoatLiftFromProduct(product);
        case ProductGroupsTypes.TYP_BATHING_LADDER:
            return getBathingLadderFromProduct(product);
        case ProductGroupsTypes.TYP_EXAMPLE_BOATS:
            return getExampleBoatsFromProduct(product);
        case ProductGroupsTypes.TYP_BOARDING_AID:
            return getBoardingAidFromProduct(product);
    }
    return null;
};

export const gridPoint = (p: PointModel, grid: number) => {
    p.x = Math.round(p.x / grid) * grid;
    p.y = Math.round(p.y / grid) * grid;
};

export const rotateVector = (vector: PointModel, angleGrad: number): void => {
    let angleBD: number = angleDegreesToBowDegrees(angleGrad);
    rotateVectorBD(vector, angleBD);
};

export const angleDegreesToBowDegrees = (angleG: number): number => {
    let angleBM: number = angleG * Math.PI / 180;
    return angleBM;
};

export const rotateVectorBD = (vector: PointModel, angleBD: number): void => {
    if (!isVector(vector)) {
        return;
    }

    let x: number = vector.x * Math.cos(angleBD) + vector.y * Math.sin(angleBD);
    let y: number = vector.y * Math.cos(angleBD) - vector.x * Math.sin(angleBD);

    vector.x = x;
    vector.y = y;
};

export const isVector = (vector: PointModel): boolean => {
    return isPoint(vector);
};
export const isPoint = (point: PointModel): boolean => {
    if (point === undefined || point === null || typeof point !== 'object') {
        return false;
    }
    if (typeof point.x !== 'number' || typeof point.y !== 'number') {
        return false;
    }
    return true;
};

export const rotateAngle = (angle: number, rotation: number = 180.0): number => {
    if (rotation === undefined) {
        rotation = 180.0;
    }
    angle += rotation;
    while (angle >= 360.0) {
        angle -= 360.0;
    }
    while (angle < 0.0) {
        angle += 360.0;
    }
    return angle;
};

export const isTouchDevice = ():boolean => {
    const win:any = window;
    const nav:any = navigator;
    return (
        !!(typeof win !== 'undefined' &&
            ('ontouchstart' in win ||
                (win.DocumentTouch &&
                    typeof document !== 'undefined' &&
                    document instanceof win.DocumentTouch))) ||
        !!(typeof nav !== 'undefined' &&
            (nav.maxTouchPoints || nav.msMaxTouchPoints))
    );
}