import {StageModuleTypes, StageSubModuleTypes} from "./products/StageModule";
import {BoatLiftTypes, SubBoatLiftTypes} from "./products/BoatLift";
import {ExampleBoatsTypes, SubExampleBoatsTypes} from "./products/ExampleBoats";
import {BathingLadderTypes, SubBathingLadderTypes} from "./products/BathingLadder";
import {BoardingAidTypes, SubBoardingAidTypes} from "./products/BoardingAid";
import {PointModel} from "../models/Global";
import {TranslateKeyType} from "../utils/useTranslate";

type ProjectProductsTypModel = StageModuleTypes | BoatLiftTypes | ExampleBoatsTypes | BathingLadderTypes | BoardingAidTypes;
type ProjectProductsVariantModel = StageSubModuleTypes | SubBoatLiftTypes | SubExampleBoatsTypes | SubBathingLadderTypes | SubBoardingAidTypes;

export enum ProductGroupsTypes {
    TYP_MODULE,
    TYP_BOAT_LIFT,
    TYP_BATHING_LADDER,
    TYP_EXAMPLE_BOATS,
    TYP_BOARDING_AID
};

export interface ProjectProductsExportModel extends PointModel {
    group: string;
    typ: string;
    variant: string | null;
    rotate: number | null;
};

export interface ProjectProductsModel extends PointModel {
    group: ProductGroupsTypes;
    typ: ProjectProductsTypModel;
    variant: ProjectProductsVariantModel | null;
    rotate: number | null;
};

export interface ProductsVariantsModel {
    typ: ProjectProductsVariantModel;
    title: TranslateKeyType;
    shortInfo?: TranslateKeyType;
    imageMenu: string;
    imageVisual: string;
};

export type ProductsModelGetCoordinates = (product: ProjectProductsModel, absolute?: boolean) => PointModel[];

export interface ProductsModel {
    group: ProductGroupsTypes;
    typ: ProjectProductsTypModel;
    title: TranslateKeyType;
    info: TranslateKeyType;
    shortInfo?: TranslateKeyType;
    width: number;
    widthDraw: number;
    height: number;
    heightDraw: number;
    grid: number;
    rotate: number | null;
    variants: ProductsVariantsModel[];
    getCoordinates: ProductsModelGetCoordinates;
};

export const emptyGetCoordinates = (product: ProjectProductsModel): PointModel[] => {
    return [{x: 0, y: 0}];
};