import {useEffect, useState} from 'react';
import {getBoardingAidFromProduct} from "../../../../data/products/BoardingAid";
import {ProductsModel, ProductsVariantsModel} from "../../../../data/Products";
import {Group, Image} from "react-konva";
import useImage from 'use-image';
import {vaProductProps} from "../VisualAreaProduct";
import VisualAreaProductEdit from "../VisualAreaProductEdit";

const vaBoardingAidLayer = 6;
const vaBoardingAidLayerActive = 8;

export const showVisualAreaBoardingAid = (productLayer: number, isActive: boolean): boolean => {
    if (isActive) {
        if (productLayer !== vaBoardingAidLayerActive) {
            return false;
        }
        return true;
    }
    if (productLayer !== vaBoardingAidLayer) {
        return false;
    }
    return true;
};

export const VisualAreaBoardingAid = ({product, productKey, productLayer, productUtils}: vaProductProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [said, setSaid] = useState<ProductsModel | null>(null);
    const [svaid, setSvaid] = useState<ProductsVariantsModel | null>(null);
    const [imageSrc, setImageSrc] = useState<string>('');
    const [image] = useImage(imageSrc);
    const [barLayer, setBarLayer] = useState<number>(vaBoardingAidLayer);
    const [rectX, setRectX] = useState<number>(0);
    const [rectY, setRectY] = useState<number>(0);
    const [rectWidth, setRectWidth] = useState<number>(0);
    const [rectHeight, setRectHeight] = useState<number>(0);

    useEffect(() => {
        let newBarLayer = productUtils.isActive ? vaBoardingAidLayerActive : vaBoardingAidLayer;
        if (barLayer !== newBarLayer) {
            setBarLayer(newBarLayer);
        }
    }, [productUtils.isActive, barLayer]);

    useEffect(() => {
        let newShow = (productLayer === barLayer);
        if (newShow !== show) {
            setShow(newShow);
        }
    }, [show, productLayer, barLayer]);

    useEffect(() => {
        if (!show) {
            return;
        }
        const boardingAids = getBoardingAidFromProduct(product);
        if (boardingAids === null) {
            setSaid(null);
            setSvaid(null);
            return;
        }
        const [nsaid, nsvaid] = boardingAids;
        setSaid(nsaid);
        setSvaid(nsvaid);
    }, [show, product, productKey]);

    useEffect(() => {
        if (!show || productLayer !== barLayer) {
            return;
        }
        if (svaid === null) {
            if (imageSrc !== '') {
                setImageSrc('');
            }
            return;
        }
        if (imageSrc !== svaid.imageVisual) {
            setImageSrc(svaid.imageVisual);
        }
    }, [show, svaid, imageSrc, barLayer, productLayer]);

    useEffect(() => {
        if (!show || said === null) {
            return;
        }
        let newRectX = -said.widthDraw / 2;
        if (newRectX !== rectX) {
            setRectX(newRectX);
        }
    }, [show, rectX, said]);

    useEffect(() => {
        if (!show || said === null) {
            return;
        }
        let newRectY = -said.heightDraw / 2;
        if (newRectY !== rectY) {
            setRectY(newRectY);
        }
    }, [show, rectY, said]);

    useEffect(() => {
        if (!show || said === null) {
            return;
        }
        let newRectWidth = said.widthDraw;
        if (newRectWidth !== rectWidth) {
            setRectWidth(newRectWidth);
        }
    }, [show, rectWidth, said]);

    useEffect(() => {
        if (!show || said === null) {
            return;
        }
        let newRectHeight = said.heightDraw;
        if (newRectHeight !== rectHeight) {
            setRectHeight(newRectHeight);
        }
    }, [show, rectHeight, said]);

    if (!show || said === null || svaid === null) {
        return (null);
    }

    return (
            <>
                <Group x={0} y={0} rotation={productUtils.rotate}>
                    {
                        productLayer === barLayer &&
                        <Image
                            x={rectX} y={rectY}
                            width={rectWidth} height={rectHeight}
                            image={image} />
                    }
                </Group>
                {
                    productUtils.isActive &&
                    <VisualAreaProductEdit
                        productKey={productKey} product={product}
                        pmodel={said} pvmodel={svaid} offset={70} />
                }
            </>
    );
};

export default VisualAreaBoardingAid;
