import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../app/hooks";
import {Group, Rect} from "react-konva";
import {setAbsBGDimensions} from "../../../features/visual-area-slice";

export const VisualAreaBackground = () => {
    const dispatch = useDispatch();
    const {app, visualArea} = useAppSelector((state) => state);
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        let h = visualArea.waterLength + visualArea.beachLength + visualArea.landLength;
        let w = h * 16.0 / 9.0;

        w = Math.round(w);
        h = Math.round(h);
        if (visualArea.absBGDimensions.width !== w || visualArea.absBGDimensions.height !== h) {
            dispatch(setAbsBGDimensions({width: w, height: h}));
        }
    }, [dispatch, visualArea.absBGDimensions, visualArea.waterLength, visualArea.beachLength, visualArea.landLength, app.small]);

    useEffect(() => {
        if (visualArea.scale === null) {
            return;
        }
        let newWidth = Math.ceil(visualArea.absBGDimensions.width + (visualArea.paintOffset.width / visualArea.scale));
        if (width !== newWidth) {
            setWidth(newWidth);
        }
    }, [width, visualArea.paintOffset.width, visualArea.scale, visualArea.absBGDimensions.width]);

    return (
            <Group x={0} y={-visualArea.waterLength}>
                <Rect x={0} y={0}
                    width={width}
                    height={visualArea.waterLength}
                    fill={'#A8C6D1'} />
                <Rect x={0} y={visualArea.waterLength}
                    width={width}
                    height={visualArea.beachLength}
                    fill={'yellow'} />
                <Rect x={0} y={visualArea.waterLength + visualArea.beachLength}
                    width={width}
                    height={visualArea.landLength}
                    fill={'#E1D9CE'} />
            </Group>
            );
};

export default VisualAreaBackground;
