import React, {useState, useEffect} from "react";
import {DimensionsModel} from "../models/App";
import {useResizeDetector} from 'react-resize-detector';

export interface UseRefDimensionsModel { ref: React.MutableRefObject<any>, dimensions: DimensionsModel };

export const useRefDimensions = (initialValue?: any): UseRefDimensionsModel  => {
    const [dimensions, setDimensions] = useState<DimensionsModel>({width: -1, height: -1});
    const { width, height, ref } = useResizeDetector(initialValue);

    useEffect(() => {
        if (typeof width !== 'number' || typeof height !== 'number') {
            return;
        }
        let w = Math.round(width);
        let h = Math.round(height);
        if (dimensions.width !== w || dimensions.height !== h) {
            setDimensions({width: w, height: h});
        }
    }, [width, height, dimensions]);

    return {ref: ref, dimensions: dimensions};
};

export default useRefDimensions;