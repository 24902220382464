import {ProductGroupsTypes, ProductsModel, ProductsVariantsModel, ProjectProductsModel} from "../Products";
import {PointModel} from "../../models/Global";
import {rotateVector} from "../../utils/global";

import diele_quadrat_braun from "../../assets/products/module/menu/diele_quadrat_braun.png";
import diele_quadrat_grau from "../../assets/products/module/menu/diele_quadrat_grau.png";
//import diele_quadrat_sand from "../../assets/products/module/menu/diele_quadrat_sand.png";
import menu_quadratisch_alu_silbergrau from "../../assets/products/module/menu/menu_quadratisch_alu-silbergrau.png";
import menu_quadratisch_graubraun from "../../assets/products/module/menu/menu_quadratisch_graubraun.png";
import menu_quadratisch_schokobraun from "../../assets/products/module/menu/menu_quadratisch_schokobraun.png";

import diele_rechteck_braun from "../../assets/products/module/menu/diele_rechteck_braun.png";
import diele_rechteck_grau from "../../assets/products/module/menu/diele_rechteck_grau.png";
//import diele_rechteck_sand from "../../assets/products/module/menu/diele_rechteck_sand.png";
import menu_rechteck_alu_silbergrau from "../../assets/products/module/menu/menu_rechteck_alu-silbergrau.png";
import menu_rechteck_graubraun from "../../assets/products/module/menu/menu_rechteck_graubraun.png";
import menu_rechteck_schokobraun from "../../assets/products/module/menu/menu_rechteck_schokobraun.png";

import steg_quadrat_braun from "../../assets/products/module/visual/steg_quadrat_braun.png";
import steg_quadrat_grau from "../../assets/products/module/visual/steg_quadrat_grau.png";
//import steg_quadrat_sand from "../../assets/products/module/visual/steg_quadrat_sand.png";
import steg_quadrat_alu_silbergrau from "../../assets/products/module/visual/steg_quadrat_alu-silbergrau.png";
import steg_quadrat_graubraun from "../../assets/products/module/visual/steg_quadrat_graubraun.png";
import steg_quadrat_schokobraun from "../../assets/products/module/visual/steg_quadrat_schokobraun.png";

import steg_rechteck_braun from "../../assets/products/module/visual/steg_rechteck_braun.png";
import steg_rechteck_grau from "../../assets/products/module/visual/steg_rechteck_grau.png";
//import steg_rechteck_sand from "../../assets/products/module/visual/steg_rechteck_sand.png";
import steg_rechteck_alu_silbergrau from "../../assets/products/module/visual/steg_rechteck_alu-silbergrau.png";
import steg_rechteck_graubraun from "../../assets/products/module/visual/steg_rechteck_graubraun.png";
import steg_rechteck_schokobraun from "../../assets/products/module/visual/steg_rechteck_schokobraun.png";

import steg_pfosten from "../../assets/products/module/visual/steg_pfosten.png";

export enum StageSubModuleTypes {
//    SSMT_SAND,
    SSMT_BROWN,
    SSMT_GRAY,
    SSMT_ALU_SILVER_GRAY,
    SSMT_GRAY_BROWN,
    SSMT_CHOCO_BROWN
};

export enum StageModuleTypes {
    SMT_I_MODULE, SMT_T_MODULE
};

export interface ProjectStageModuleModel extends ProjectProductsModel {
    group: ProductGroupsTypes;
    typ: StageModuleTypes;
    variant: StageSubModuleTypes;
};

export interface StageModuleVariantsModel extends ProductsVariantsModel {
    typ: StageSubModuleTypes;
    imagePoleVisual: string;
};

export interface StageModuleModel extends ProductsModel {
    typ: StageModuleTypes;
    widthPole: number;
    heightPole: number;
    variants: StageModuleVariantsModel[];
};

const poleWidth: number = 100;
const poleHeight: number = 100;

const getStageModulesCoordinates = (product: ProjectProductsModel, width: number, height: number, absolute: boolean): PointModel[] => {
    const widthHalf = width / 2;
    const heightHalf = height / 2;
    const multis = [[-1, -1], [1, -1], [1, 1], [-1, 1]];
    let newPoints: PointModel[] = [];
    let newFinPoints: PointModel[] = [];
    let topLeftPoint: number | null = null;
    let topLeftPointFin: number = 0;

    multis.forEach((multi) => {
        const [xmulti, ymulti] = multi;
        let newPoint: PointModel = {
            x: widthHalf,
            y: heightHalf,
        };
        newPoint.x *= xmulti;
        newPoint.y *= ymulti;

        if (product.rotate !== null) {
            rotateVector(newPoint, product.rotate);
        }

        if (newPoint.x <= 0) {
            if (topLeftPoint === null || newPoints[topLeftPoint].y > newPoint.y) {
                topLeftPoint = newPoints.length;
            }
        }

        newPoints.push(newPoint);
    });

    topLeftPointFin = topLeftPoint !== null ? topLeftPoint : 0;
    newPoints.forEach((point, key) => {
        let newKey = (key - topLeftPointFin ?? 0) % newPoints.length;
        while (newKey < 0) {
            newKey += newPoints.length;
        }
        if (absolute) {
            point.x += product.x;
            point.y += product.y;
        }
        point.x = Math.round(point.x);
        point.y = Math.round(point.y);
        newFinPoints[newKey] = point;
    });

    return newFinPoints;
};

export const StageModules: StageModuleModel[] = [
    {
        group: ProductGroupsTypes.TYP_MODULE,
        typ: StageModuleTypes.SMT_I_MODULE,
        title: {
            de: 'Element 1245 x 2490'
        },
        info: {
            de: '• für gerade Strecken<br />• beliebig verlängerbar<br />• witterungsbeständiger Kovalex-Belag<br />• in vielen Farben<br /><br />'
        },
        width: 1245,
        widthDraw: 1250,
        height: 2490,
        heightDraw: 2500,
        widthPole: poleWidth,
        heightPole: poleHeight,
        grid: 125,
        rotate: 90,
        variants: [
//            {
//                typ: StageSubModuleTypes.SSMT_SAND,
//                title: {
//                    de: 'Sand'
//                },
//                imageMenu: diele_rechteck_sand,
//                imageVisual: steg_rechteck_sand,
//                imagePoleVisual: steg_pfosten
//            },
            {
                typ: StageSubModuleTypes.SSMT_BROWN,
                title: {
                    de: 'braun'
                },
                imageMenu: diele_rechteck_braun,
                imageVisual: steg_rechteck_braun,
                imagePoleVisual: steg_pfosten
            },
            {
                typ: StageSubModuleTypes.SSMT_GRAY,
                title: {
                    de: 'grau'
                },
                imageMenu: diele_rechteck_grau,
                imageVisual: steg_rechteck_grau,
                imagePoleVisual: steg_pfosten
            },
            {
                typ: StageSubModuleTypes.SSMT_ALU_SILVER_GRAY,
                title: {
                    de: 'Alu silbergrau'
                },
                imageMenu: menu_rechteck_alu_silbergrau,
                imageVisual: steg_rechteck_alu_silbergrau,
                imagePoleVisual: steg_pfosten
            },
            {
                typ: StageSubModuleTypes.SSMT_GRAY_BROWN,
                title: {
                    de: 'graubraun'
                },
                imageMenu: menu_rechteck_graubraun,
                imageVisual: steg_rechteck_graubraun,
                imagePoleVisual: steg_pfosten
            },
            {
                typ: StageSubModuleTypes.SSMT_CHOCO_BROWN,
                title: {
                    de: 'schokobraun'
                },
                imageMenu: menu_rechteck_schokobraun,
                imageVisual: steg_rechteck_schokobraun,
                imagePoleVisual: steg_pfosten
            }
        ],
        getCoordinates: (product: ProjectProductsModel, absolute: boolean = true): PointModel[] => {
            return getStageModulesCoordinates(product, 1250, 2500, absolute);
        }
    },
    {
        group: ProductGroupsTypes.TYP_MODULE,
        typ: StageModuleTypes.SMT_T_MODULE,
        title: {
            de: 'Element 1245 x 1245'
        },
        info: {
            de: '• für gerade Strecken und rechte Winkel<br />• beliebig erweiterbar<br />• an jede Pfostenverbindung ansetzbar<br />• witterungsbeständiger Kovalex-Belag<br />• in vielen Farben<br /><br />'
        },
        width: 1245,
        widthDraw: 1250,
        height: 1245,
        heightDraw: 1250,
        widthPole: poleWidth,
        heightPole: poleHeight,
        grid: 125,
        rotate: 90,
        variants: [
//            {
//                typ: StageSubModuleTypes.SSMT_SAND,
//                title: {
//                    de: 'Sand'
//                },
//                imageMenu: diele_quadrat_sand,
//                imageVisual: steg_quadrat_sand,
//                imagePoleVisual: steg_pfosten
//            },
            {
                typ: StageSubModuleTypes.SSMT_BROWN,
                title: {
                    de: 'braun'
                },
                imageMenu: diele_quadrat_braun,
                imageVisual: steg_quadrat_braun,
                imagePoleVisual: steg_pfosten
            },
            {
                typ: StageSubModuleTypes.SSMT_GRAY,
                title: {
                    de: 'grau'
                },
                imageMenu: diele_quadrat_grau,
                imageVisual: steg_quadrat_grau,
                imagePoleVisual: steg_pfosten
            },
            {
                typ: StageSubModuleTypes.SSMT_ALU_SILVER_GRAY,
                title: {
                    de: 'Alu silbergrau'
                },
                imageMenu: menu_quadratisch_alu_silbergrau,
                imageVisual: steg_quadrat_alu_silbergrau,
                imagePoleVisual: steg_pfosten
            },
            {
                typ: StageSubModuleTypes.SSMT_GRAY_BROWN,
                title: {
                    de: 'graubraun'
                },
                imageMenu: menu_quadratisch_graubraun,
                imageVisual: steg_quadrat_graubraun,
                imagePoleVisual: steg_pfosten
            },
            {
                typ: StageSubModuleTypes.SSMT_CHOCO_BROWN,
                title: {
                    de: 'schokobraun'
                },
                imageMenu: menu_quadratisch_schokobraun,
                imageVisual: steg_quadrat_schokobraun,
                imagePoleVisual: steg_pfosten
            }
        ],
        getCoordinates: (product: ProjectProductsModel, absolute: boolean = true): PointModel[] => {
            return getStageModulesCoordinates(product, 1250, 1250, absolute);
        }
    }
];


export const getStageModulesFromProduct = (product: ProjectProductsModel): [StageModuleModel, StageModuleVariantsModel] | null => {
    let result: [StageModuleModel, StageModuleVariantsModel] | null = null;

    StageModules.forEach(function (smodule: StageModuleModel) {
        if (result !== null || smodule.typ !== product.typ) {
            return;
        }
        smodule.variants.forEach(function (svmodule: StageModuleVariantsModel) {
            if (result !== null || svmodule.typ !== product.variant) {
                return;
            }
            result = [smodule, svmodule];
        });
    });

    return result;
};
