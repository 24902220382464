import {useEffect, useState} from 'react';
import {Group} from "react-konva";
import {ProductGroupsTypes, ProjectProductsModel, ProductsModel} from "../../../data/Products";
import VisualAreaModule, {showVisualAreaModule} from "./Module/VisualAreaModule";
import {vaProductsProps} from "./VisualAreaProducts";
import VisualAreaProductUtils, {VAProductsUtilsResultProps} from "./VisualAreaProductUtils";
import VisualAreaBoatLift, {showVisualAreaBoatLift} from "./BoatLift/VisualAreaBoatLift";
import VisualAreaBathingLadder, {showVisualAreaBathingLadder} from "./BathingLadder/VisualAreaBathingLadder";
import VisualAreaExampleBoats, {showVisualAreaExampleBoats} from "./ExampleBoats/VisualAreaExampleBoats";
import VisualAreaBoardingAid, {showVisualAreaBoardingAid} from "./BoardingAid/VisualAreaBoardingAid";

export interface vaProductProps {
    product: ProjectProductsModel;
    productKey: number;
    productLayer: number;
    productUtils: VAProductsUtilsResultProps;
};

export const VisualAreaProduct = ({product, productKey, productLayer}: vaProductsProps) => {
    const [pModel, setPModel] = useState<ProductsModel | null>(null);
    const [isDraggable, setIsDraggable] = useState<boolean>(true);

    const productUtils = VisualAreaProductUtils(product, productKey);

    useEffect(() => {
        if (isDraggable === productUtils.isDraggable) {
            return;
        }
        setIsDraggable(productUtils.isDraggable);
    }, [isDraggable, productUtils.isDraggable]);

    useEffect(() => {
        setPModel(productUtils.pModel);
    }, [productUtils.pModel]);

    const searchProduct = (): JSX.Element | null => {
        if (pModel === null) {
            return (null);
        }

        let el: JSX.Element | null = null;

        switch (product.group) {
            case ProductGroupsTypes.TYP_MODULE:
                if (!showVisualAreaModule(productLayer, productUtils.isActive)) {
                    break;
                }
                el = <VisualAreaModule
                    key={"VisualAreaProduct-" + productKey}
                    product={product}
                    productKey={productKey} productLayer={productLayer}
                    productUtils={productUtils} />;
                break;
            case ProductGroupsTypes.TYP_BOAT_LIFT:
                if (!showVisualAreaBoatLift(productLayer, productUtils.isActive)) {
                    break;
                }
                el = <VisualAreaBoatLift
                    key={"VisualAreaProduct-" + productKey}
                    product={product}
                    productKey={productKey} productLayer={productLayer}
                    productUtils={productUtils} />;
                break;
            case ProductGroupsTypes.TYP_BATHING_LADDER:
                if (!showVisualAreaBathingLadder(productLayer, productUtils.isActive)) {
                    break;
                }
                el = <VisualAreaBathingLadder
                    key={"VisualAreaProduct-" + productKey}
                    product={product}
                    productKey={productKey} productLayer={productLayer}
                    productUtils={productUtils} />;
                break;
            case ProductGroupsTypes.TYP_EXAMPLE_BOATS:
                if (!showVisualAreaExampleBoats(productLayer, productUtils.isActive)) {
                    break;
                }
                el = <VisualAreaExampleBoats
                    key={"VisualAreaProduct-" + productKey}
                    product={product}
                    productKey={productKey} productLayer={productLayer}
                    productUtils={productUtils} />;
                break;
            case ProductGroupsTypes.TYP_BOARDING_AID:
                if (!showVisualAreaBoardingAid(productLayer, productUtils.isActive)) {
                    break;
                }
                el = <VisualAreaBoardingAid
                    key={"VisualAreaProduct-" + productKey}
                    product={product}
                    productKey={productKey} productLayer={productLayer}
                    productUtils={productUtils} />;
                break;
        }
        if (el === null) {
            return (null);
        }
        return (
            <Group x={productUtils.position.x} y={productUtils.position.y}
                    draggable={isDraggable}
                    onClick={productUtils.handleClick}
                    onTouchstart={productUtils.handleTouchstart}
                    onTouchEnd={productUtils.handleTouchEnd}
                    onMouseDown={productUtils.handleMouseDown}
                    onMouseUp={productUtils.handleMouseUp}
                    onMouseEnter={productUtils.handleMouseenter}
                    onMouseLeave={productUtils.handleMouseleave}
                    onDragEnd={e => {

                    }}
                    dragBoundFunc={(pos) => {
                        return pos;
                    }}
                    onDragMove={e => {productUtils.handleChangeDragMove(e, pModel.grid)}}
                    >
                { el }
            </Group>
        );
    };

    return (searchProduct());
};

export default VisualAreaProduct;
