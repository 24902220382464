import {useEffect, useState, CSSProperties} from "react";
import useRefDimensions, {UseRefDimensionsModel} from "../../../utils/useRefDimensions";
import MenuBody from "../Body/MenuBody";
import MenuFooter from "../Footer/MenuFooter";
import {useAppSelector} from "../../../app/hooks";
import {ImprintDataProtect} from "../../shared/ImprintDataProtect/ImprintDataProtect"

import './MenuContainer.scss';

const MenuImprintDataProtect = () => {
    const refDimensions: UseRefDimensionsModel = useRefDimensions();
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        if (refDimensions.dimensions === undefined) {
            return;
        }
        if (refDimensions.dimensions.width === undefined || refDimensions.dimensions.width <= 0 ||
            refDimensions.dimensions.height === undefined || refDimensions.dimensions.height <= 0) {
            return;
        }
        if (refDimensions.dimensions.height !== height) {
            setHeight(refDimensions.dimensions.height);
        }
    }, [refDimensions.dimensions, height]);

    return (
        <div ref={refDimensions.ref}>
            <div className="ams-menu-imprint-data-protect"
                    style={{"--object-height": height + "px"} as CSSProperties}>
                <div>
                    <ImprintDataProtect />
                </div>
            </div>
        </div>
    );
};

export const MenuContainer = () => {
    const {small} = useAppSelector((state) => state.app);

    return (
            <div className="ams-menu-container">
                {
                    !small &&
                    <MenuImprintDataProtect />
                }
                <MenuBody />
                <MenuFooter />
            </div>
            );
};

export default MenuContainer;
