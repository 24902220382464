import {CSSProperties} from 'react';
import Modal from 'react-bootstrap/Modal';
import menuPanelPng from "../../../assets/menu/panel.png";
//import menuPanelSchraubePng from "../../../assets/menu/panel_schraube.png";
//import useTranslate from "../../../utils/useTranslate"

import './SPModal.scss';

export interface SPModalProps {
    show: boolean | undefined;
    onHide: () => void;
    header?: JSX.Element | string | null;
    body: JSX.Element | string | null;
    footer?: JSX.Element | string | null;
    className?: string;
};

export const SPModal = ({show, onHide, body, ...props}: SPModalProps) => {
//    const {t} = useTranslate();
    
    return (
        <Modal show={show} onHide={onHide} className={'ams-sp-modal' + (props.className ? ' ' + props.className : '')}
                style={{
                    "--image-background": `url(${menuPanelPng})`
                } as CSSProperties}>
            {
                props.header &&
                <Modal.Header>
                    {props.header}
                </Modal.Header>
            }
            <Modal.Body>
                {body}
            </Modal.Body>
            {
                props.footer &&
                <Modal.Footer>
                    {props.footer}
                </Modal.Footer>
            }
        </Modal>
    );
};
//                <img src={menuPanelSchraubePng} style={{position: 'absolute', top: 5, left: 10, width: 20, height: 20}} alt={t('Schraube')} />
//                <img src={menuPanelSchraubePng} style={{position: 'absolute', top: 5, right: 10, width: 20, height: 20}} alt={t('Schraube')} />
//                <img src={menuPanelSchraubePng} style={{position: 'absolute', bottom: 5, left: 10, width: 20, height: 20}} alt={t('Schraube')} />
//                <img src={menuPanelSchraubePng} style={{position: 'absolute', bottom: 5, right: 10, width: 20, height: 20}} alt={t('Schraube')} />

export default SPModal;
