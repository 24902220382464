import {useEffect, useRef} from 'react';
import {PlusCircleFill} from "react-bootstrap-icons";

import './ProductsPlus.scss';

export const ProductsPlus = () => {
    const plusRef = useRef<any>(null);
    const addOffsetIcon = 1;

    useEffect(() => {
        if (plusRef.current === null) {
            return;
        }
        let iconEl = plusRef.current.querySelector(".ams-products-plus-icon");
        if (!iconEl) {
            return;
        }
        let viewBox = iconEl.getAttribute('viewBox');
        if (!viewBox || typeof viewBox !== 'string') {
            return;
        }
        let vbv = viewBox.split(' ');
        if (!Array.isArray(vbv) || vbv.length !== 4) {
            return;
        }
        let error = false;
        let vbvi: number[] = [];
        vbv.forEach(function (v, i) {
            let vi = parseInt(v);
            if (isNaN(vi) || vi.toString() !== v) {
                error = true;
                return;
            }
            if (i < 2) {
                if (vi !== 0) {
                    error = true;
                    return;
                }
                vi -= addOffsetIcon;
            } else {
                vi += 2 * addOffsetIcon;
            }
            vbvi.push(vi);
        });
        if (error) {
            return;
        }
        let nVb = vbvi.join(' ');
        iconEl.setAttribute('viewBox', nVb);
    }, [plusRef]);

    return (
            <div className="ams-products-plus" ref={plusRef}>
                <PlusCircleFill size="24px" className="ams-products-plus-icon" />
            </div>
            );
};

export default ProductsPlus;
