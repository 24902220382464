import {useAppSelector} from "../../../app/hooks";
import MenuItemStart from "./Start/MenuItemStart";
import MenuItemModule from "./Module/MenuItemModule";
import MenuItemBoatLift from "./BoatLift/MenuItemBoatLift";
import MenuItemAccessories from "./Accessories/MenuItemAccessories";
import MenuItemSend from "./Send/MenuItemSend";
import {MenuTypes, menuActive} from "../../../features/app-slice";

import './MenuItems.scss';

export const MenuItems = () => {
    const {currentMenu} = useAppSelector((state) => state.app);
    const {project} = useAppSelector((state) => state);

    const getCurrentMenuItem = (): JSX.Element | null => {
        if (!menuActive(currentMenu, project)) {
            return null;
        }
        switch (currentMenu) {
            case MenuTypes.START:
                return <MenuItemStart />;
            case MenuTypes.MODULE:
                return <MenuItemModule />;
            case MenuTypes.BOAT_LIFT:
                return <MenuItemBoatLift />;
            case MenuTypes.ACCESSORIES://Accessories
                return <MenuItemAccessories />;
            case MenuTypes.SEND:
                return <MenuItemSend />;
        };
        return null;
    };

    return (
            <div className={'ams-menu-items'}>
                {getCurrentMenuItem()}
            </div>
            );
};

export default MenuItems;
