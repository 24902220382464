import {useTranslation} from "react-i18next";

export type TranslateKeyObjectType = {[key: string] : string};
export type TranslateKeyType = TranslateKeyObjectType | string;
export type TranslateDefType = TranslateKeyType | number | never;
type TranslateType = (key: TranslateKeyType, def?: TranslateDefType) => string;

export type TranslateReturnType = {
    translate: TranslateType,
    t: TranslateType,
    numberFormat: (n: number) => string,
};

export const useTranslate = (): TranslateReturnType => {
    const [trans, i18n] = useTranslation();

    const tr = (key: string, def: string | number): string | number => {
        let value = trans(key);
        if (typeof value === 'string' || typeof value === 'number') {
            return value;
        }
        return def;
    };
    const tl = (keys: TranslateKeyObjectType): string => {
        let value: string = '';
        Object.keys(keys).forEach(function(key) {
            if (i18n.language.indexOf(key) === 0) {
                value = keys[key];
            }
        });
        return value;
    };

    const translate = (key: TranslateKeyType, def?: TranslateDefType): string => {
        let keyToTr: string = '';
        if (typeof key === 'string') {
            keyToTr = key;
        } else {
            keyToTr = tl(key);
        }

        let defToTr: string | number = '';
        if (def === undefined) {
            defToTr = keyToTr;
        } else if (typeof def === 'string' || typeof def === 'number') {
            defToTr = def;
        } else {
            defToTr = tl(def);
        }

        let value = tr(keyToTr, defToTr);
        if (typeof value === 'number') {
            return value + '';
        }
        return value;
    };

    const t = translate;

    const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
    const numberFormatTS = (str: string): string => {
        let thousandSeparator = trans('Tausendertrennzeichen');
        let index = str.search(/[1-9]/);
        index = index === -1 ? str.length : index;

        let res = str.substring(0, index) +
                str.substring(index, str.length).replace(thousandsGroupRegex, '$1' + thousandSeparator);

        return res;
    }

    const numberFormat = (n: number): string => {
        let na = (n + '').split('.');
        let result = numberFormatTS(na[0]);
        if (na.length > 1) {
            result += trans('Dezimaltrennzeichen');
            result += na[1];
        }
        return result;
    };

    return {translate, t, numberFormat};
};

export default useTranslate;
