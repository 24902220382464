import {ProductGroupsTypes, ProductsModel, ProjectProductsModel, ProductsVariantsModel} from "../Products";
import {getCoordinates} from "../../utils/Products";

import eb_sup from "../../assets/products/example_boats/sup.png";
import eb_motorboot from "../../assets/products/example_boats/motorboot_1.png";
import eb_ruderboot from "../../assets/products/example_boats/Ruderboot.png";

export enum ExampleBoatsTypes {
    EB_SUP,
    EB_MOTORBOOT,
    EB_RUDERBOOT
};
export enum SubExampleBoatsTypes {
    SEB_STANDARD
};

export interface ProjectExampleBoatsModel extends ProjectProductsModel {
    group: ProductGroupsTypes;
};

export const ExampleBoats: ProductsModel[] = [
    {
        group: ProductGroupsTypes.TYP_EXAMPLE_BOATS,
        typ: ExampleBoatsTypes.EB_SUP,
        title: {
            de: ''
        },
        info: {
            de: '3,2 m'
        },
        width: 207,
        widthDraw: 814.760147601476,
        height: 813,
        heightDraw: 3200,
        grid: 125,
        rotate: 90,
        variants: [
            {
                typ: SubExampleBoatsTypes.SEB_STANDARD,
                title: {
                    de: ''
                },
                imageMenu: eb_sup,
                imageVisual: eb_sup
            },
        ],
        getCoordinates: getCoordinates
    },
    {
        group: ProductGroupsTypes.TYP_EXAMPLE_BOATS,
        typ: ExampleBoatsTypes.EB_RUDERBOOT,
        title: {
            de: ''
        },
        info: {
            de: '4,5 m'
        },
        width: 312,
        widthDraw: 1504.8231511254,
        height: 933,
        heightDraw: 4500,
        grid: 125,
        rotate: 90,
        variants: [
            {
                typ: SubExampleBoatsTypes.SEB_STANDARD,
                title: {
                    de: ''
                },
                imageMenu: eb_ruderboot,
                imageVisual: eb_ruderboot
            },
        ],
        getCoordinates: getCoordinates
    },
    {
        group: ProductGroupsTypes.TYP_EXAMPLE_BOATS,
        typ: ExampleBoatsTypes.EB_MOTORBOOT,
        title: {
            de: ''
        },
        info: {
            de: '6,0 m'
        },
        width: 356,
        widthDraw: 2092.3593618808,
        height: 1191,
        heightDraw: 7000,
        grid: 125,
        rotate: 90,
        variants: [
            {
                typ: SubExampleBoatsTypes.SEB_STANDARD,
                title: {
                    de: ''
                },
                imageMenu: eb_motorboot,
                imageVisual: eb_motorboot
            },
        ],
        getCoordinates: getCoordinates
    }
];

export const getExampleBoatsFromProduct = (product: ProjectProductsModel): [ProductsModel, ProductsVariantsModel] | null => {
    let result: [ProductsModel, ProductsVariantsModel] | null = null;

    ExampleBoats.forEach(function (slift: ProductsModel) {
        if (result !== null || slift.typ !== product.typ) {
            return;
        }
        slift.variants.forEach(function (svlift: ProductsVariantsModel) {
            if (result !== null || svlift.typ !== product.variant) {
                return;
            }
            result = [slift, svlift];
        });
    });

    return result;
};