import {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../app/hooks";
import { useDispatch } from "react-redux";
import {
    StageModuleModel,
    StageModuleVariantsModel,
    ProjectStageModuleModel
} from "../../../../data/products/StageModule";
import {addProduct} from "../../../../features/project-slice";
import {setProductActive, setProductDraggable} from "../../../../features/visual-area-slice";
import {gridPoint} from "../../../../utils/global";
import ProductModules from "../../../shared/Products/Module/ProductModules";

import './MenuItemModule.scss';

export const MenuItemModule = () => {
    const dispatch = useDispatch();
    const {visualArea, project} = useAppSelector((state) => state);
    const [addedProduct, setAddedProduct] = useState<number | null>(null);

    useEffect(() => {
        if (addedProduct === null) {
            return;
        }
        dispatch(setProductActive(addedProduct));
        dispatch(setProductDraggable(addedProduct));
        setAddedProduct(null);
    }, [dispatch, addedProduct]);

    const addModlue = (imodule: StageModuleModel, ismodule: StageModuleVariantsModel) => {
        if (visualArea.groupPosition === null || visualArea.scale === null) {
            return;
        }
        let x = ((visualArea.paintDimensions.width / 2) - visualArea.groupPosition.x) / visualArea.scale;
        let y = ((visualArea.paintDimensions.height / 2) - visualArea.groupPosition.y) / visualArea.scale;
        let newProduct: ProjectStageModuleModel = {
            group: imodule.group,
            typ: imodule.typ,
            variant: ismodule.typ,
            x: x,
            y: y,
            rotate: 0
        };
        gridPoint(newProduct, imodule.grid);

        dispatch(addProduct(newProduct));
        setAddedProduct(project.products.length);
    };

    return (<ProductModules selectProductVariant={addModlue} />);
};

export default MenuItemModule;
