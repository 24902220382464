import {useState, useEffect} from "react";
import {useAppSelector} from "../../../app/hooks";
import {MinMaxProps} from "../../../models/App";
import VisualAreaMeasurementHeight from "./VisualAreaMeasurementHeight";
import VisualAreaMeasurementWidth from "./VisualAreaMeasurementWidth";

type VisualAreaMeasurementMinMayType = MinMaxProps | null;
export interface VisualAreaMeasurementProps {
    minMaxMeasure: VisualAreaMeasurementMinMayType;
};
export interface VisualAreaMeasurementConstProps {
    offset: number;
    width: number;
    widthRect: number;
    arrowLength: number;
    arrowWidth: number;
    arrowInnerOffset: number;
    textBoxWidth: number;
    textBoxFontFamily: string;
    textBoxFontSize: number;
    textBoxOffset: number;
    textBoxStroke: number;
    multistart: number;
    multiMin: number;
};
export const VisualAreaMeasurementConst: VisualAreaMeasurementConstProps = {
    offset: 50,
    width: 20,
    widthRect: 5,
    arrowLength: 30,
    arrowWidth: 20,
    arrowInnerOffset: 5,
    textBoxWidth: 500,
    textBoxFontFamily: 'Roboto, sans-serif',
    textBoxFontSize: 16,
    textBoxOffset: 10,
    textBoxStroke: 1,
    multistart: 200,
    multiMin: 0.5
};

export const VisualAreaMeasurement = () => {
    const {minMaxModule} = useAppSelector((state) => state.app);
    const [minMaxMeasure, setMinMaxMeasure] = useState<VisualAreaMeasurementMinMayType>(null);

    useEffect(() => {
        if (minMaxModule === null) {
            if (minMaxMeasure !== null) {
                setMinMaxMeasure(null);
            }
            return;
        }
        if (JSON.stringify(minMaxModule) !== JSON.stringify(minMaxMeasure)) {
            setMinMaxMeasure(minMaxModule);
        }
    }, [minMaxModule, minMaxMeasure]);

    if (minMaxMeasure === null) {
        return (null);
    }
    return (
            <>
                <VisualAreaMeasurementHeight minMaxMeasure={minMaxMeasure} />
                <VisualAreaMeasurementWidth minMaxMeasure={minMaxMeasure} />
            </>
            );
};

export default VisualAreaMeasurement;
