import {ProjectProductsModel} from "../data/Products";
import {PointModel} from "../models/Global";
import {rotateVector, getModelFromProduct} from "../utils/global";

export const getCoordinates = (product: ProjectProductsModel, absolute: boolean = true): PointModel[] => {
    let mfpr = getModelFromProduct(product);
    if (mfpr === null) {
        return [];
    }
    const [pr] = mfpr;
    return getProductsCoordinates(product, pr.widthDraw, pr.heightDraw, absolute);
};
export const getProductsCoordinates = (product: ProjectProductsModel, width: number, height: number, absolute: boolean): PointModel[] => {
    const widthHalf = width / 2;
    const heightHalf = height / 2;
    const multis = [[-1, -1], [1, -1], [1, 1], [-1, 1]];
    let newPoints: PointModel[] = [];
    let newFinPoints: PointModel[] = [];
    let topLeftPoint: number | null = null;
    let topLeftPointFin: number = 0;

    multis.forEach((multi) => {
        const [xmulti, ymulti] = multi;
        let newPoint: PointModel = {
            x: widthHalf,
            y: heightHalf,
        };
        newPoint.x *= xmulti;
        newPoint.y *= ymulti;

        if (product.rotate !== null) {
            rotateVector(newPoint, product.rotate);
        }

        if (newPoint.x <= 0) {
            if (topLeftPoint === null || newPoints[topLeftPoint].y > newPoint.y) {
                topLeftPoint = newPoints.length;
            }
        }

        newPoints.push(newPoint);
    });

    topLeftPointFin = topLeftPoint !== null ? topLeftPoint : 0;
    newPoints.forEach((point, key) => {
        let newKey = (key - topLeftPointFin ?? 0) % newPoints.length;
        while (newKey < 0) {
            newKey += newPoints.length;
        }
        if (absolute) {
            point.x += product.x;
            point.y += product.y;
        }
        point.x = Math.round(point.x);
        point.y = Math.round(point.y);
        newFinPoints[newKey] = point;
    });

    return newFinPoints;
};
