import {useState, useEffect} from 'react';
import useTranslate from "../../../../utils/useTranslate"
import {
    StageModules,
    StageModuleModel,
    StageModuleVariantsModel
} from "../../../../data/products/StageModule";
import {InfoCircle as BSIconInfoCircle} from "react-bootstrap-icons";
import ProductsPlus from "../ProductsPlus";

import './ProductModules.scss';

export interface ProductModulesProps {
    selectProductVariant: (pmodel: StageModuleModel, pvmodel: StageModuleVariantsModel) => void;
    infoForProduct?: (pmodel: StageModuleModel) => void;
    checkProduct?: (pmodel: StageModuleModel) => boolean;
    checkProductVariant?: (pmodel: StageModuleModel, pvmodel: StageModuleVariantsModel) => boolean;
};

export const ProductModules = ({selectProductVariant, ...props}: ProductModulesProps) => {
    const[haveInfoForProduct, setHaveInfoForProduct] = useState<boolean>(false);
    const {t} = useTranslate();

    useEffect(() => {
        let h = (props.infoForProduct !== undefined);
        if (haveInfoForProduct !== h) {
            setHaveInfoForProduct(h);
        }
    }, [props, haveInfoForProduct]);

    return (
            <div className="ams-product-item-modules">
                {
                    StageModules.map((imodule, iindex) => {
                        if (props.checkProduct !== undefined && !props.checkProduct(imodule)) {
                            return (null);
                        }
                        let title = t(imodule.title);
                        let info = t(imodule.info);
                        return (
                            <div key={"ams-product-item-module-key-" + iindex}>
                                <div className="ams-product-item-module-title">
                                    <span>{title}</span>
                                    {
                                        haveInfoForProduct &&
                                        <BSIconInfoCircle onClick={() => {
                                            if (props.infoForProduct !== undefined) {
                                                props.infoForProduct(imodule);
                                            }
                                        }} />
                                    }
                                </div>
                                {
                                    !haveInfoForProduct &&
                                    <div className="ams-product-item-module-info">
                                        <div dangerouslySetInnerHTML={{__html: info}}></div>
                                    </div>
                                }
                                <div className="ams-product-item-module-messures">{imodule.width} x {imodule.height} {t('mm')}</div>
                                <div className="ams-product-item-module-subs">
                                    {
                                        imodule.variants.map((ismodule, isindex) => {
                                            if (props.checkProductVariant !== undefined && !props.checkProductVariant(imodule, ismodule)) {
                                                return (null);
                                            }
                                            let stitle = t(ismodule.title);
                                            return (
                                                <div key={"ams-product-item-module-key-" + iindex + "-" + isindex}
                                                        className="ams-product-item-module-sub"
                                                        onClick={() => {selectProductVariant(imodule, ismodule);}}>
                                                    <div className="ams-product-item-module-sub-image">
                                                        <img src={ismodule.imageMenu} alt={title + " - " + stitle} title={title + " - " + stitle} />
                                                        {
                                                            props.checkProduct === undefined && props.checkProductVariant === undefined &&
                                                            <ProductsPlus />
                                                        }
                                                    </div>
                                                    <div className="ams-product-item-module-sub-title">{stitle}</div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            );
};

export default ProductModules;
