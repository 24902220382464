import {useState, useEffect} from 'react';
import useTranslate from "../../../../utils/useTranslate"
import {
    BathingLadders
} from "../../../../data/products/BathingLadder";
import {ProductsProps} from "../Products";
import {InfoCircle as BSIconInfoCircle} from "react-bootstrap-icons";
import Button from 'react-bootstrap/Button';
import ProductsPlus from "../ProductsPlus";

import './ProductBathingLadder.scss';

export const ProductBathingLadder = ({selectProductVariant, ...props}: ProductsProps) => {
    const[haveInfoForProduct, setHaveInfoForProduct] = useState<boolean>(false);
    const {t} = useTranslate();

    useEffect(() => {
        let h = (props.infoForProduct !== undefined);
        if (haveInfoForProduct !== h) {
            setHaveInfoForProduct(h);
        }
    }, [props, haveInfoForProduct]);

    return (
            <div className="ams-product-item-bathing_ladders">
                {
                    BathingLadders.map((iBathingLadder, iindex) => {
                        if (props.checkProduct !== undefined && !props.checkProduct(iBathingLadder)) {
                            return (null);
                        }
                        let title = t(iBathingLadder.title);
                        let info = t(iBathingLadder.info);
                        return (
                            <div key={"ams-product-item-bathing_ladder-key-" + iindex}>
                                <div className="ams-product-item-bathing_ladder-title">
                                    <span>{title}</span>
                                    {
                                        haveInfoForProduct &&
                                        <BSIconInfoCircle onClick={() => {
                                            if (props.infoForProduct !== undefined) {
                                                props.infoForProduct(iBathingLadder);
                                            }
                                        }} />
                                    }
                                </div>
                                {
                                    !haveInfoForProduct &&
                                    <div className="ams-product-item-bathing_ladder-info">
                                        <div dangerouslySetInnerHTML={{__html: info}}></div>
                                    </div>
                                }
                                <div className="ams-product-item-bathing_ladder-messures">{iBathingLadder.width} x {iBathingLadder.height} {t('mm')}</div>
                                <div className="ams-product-item-bathing_ladder-subs">
                                    {
                                        iBathingLadder.variants.map((isBathingLadder, isindex) => {
                                            if (props.checkProductVariant !== undefined && !props.checkProductVariant(iBathingLadder, isBathingLadder)) {
                                                return (null);
                                            }
                                            let stitle = t(isBathingLadder.title);
                                            let shortInfo: string | null = isBathingLadder.shortInfo ? t(isBathingLadder.shortInfo) : null;
                                            return (
                                                <div key={"ams-product-item-bathing_ladder-key-" + iindex + "-" + isindex}
                                                        className="ams-product-item-bathing_ladder-sub">
                                                    <div onClick={() => {selectProductVariant(iBathingLadder, isBathingLadder);}}
                                                            className="ams-product-item-bathing_ladder-image">
                                                        <img src={isBathingLadder.imageMenu} alt={title + " - " + stitle} title={title + " - " + stitle} />
                                                        {
                                                            !!stitle &&
                                                            <div className="ams-product-item-bathing_ladder-sub-title">{stitle}</div>
                                                        }
                                                        {
                                                            props.checkProduct === undefined && props.checkProductVariant === undefined &&
                                                            <ProductsPlus />
                                                        }
                                                    </div>
                                                    {
                                                        !!shortInfo &&
                                                        <div className="ams-product-item-bathing_ladder-sub-short-info">
                                                            <div dangerouslySetInnerHTML={{__html: shortInfo}}></div>
                                                            <div>
                                                                {
                                                                    haveInfoForProduct &&
                                                                    <Button variant="primary" onClick={() => {
                                                                            if (props.infoForProduct !== undefined) {
                                                                                props.infoForProduct(iBathingLadder);
                                                                            }
                                                                        }}>
                                                                        {t('Details')}
                                                                    </Button>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            );
};

export default ProductBathingLadder;
