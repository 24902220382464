import {useDispatch} from "react-redux";
import {ProjectProductsModel} from "../../../../data/Products";
import {
    StageModuleModel,
    StageModuleVariantsModel
} from "../../../../data/products/StageModule";
import ProductModules from "../../../shared/Products/Module/ProductModules";
import {changeProduct} from "../../../../features/project-slice";

import './VisualAreaModalVariantModule.scss';

export interface vamvModuleProps {
    productKey: number;
    product: ProjectProductsModel;
    close: () => void;
};

export const VisualAreaModalVariantModule = ({productKey, product, close}: vamvModuleProps) => {
    const dispatch = useDispatch();

    const changeVariant = (imodule: StageModuleModel, ismodule: StageModuleVariantsModel) => {
        let cProduct: ProjectProductsModel = Object.assign({}, product);
        cProduct.variant = ismodule.typ;
        dispatch(changeProduct({index: productKey, product: cProduct}));
        close();
    };
    const checkProduct = (imodule: StageModuleModel) => {
        return imodule.typ === product.typ;
    };
    const checkProductVariant = (imodule: StageModuleModel, ismodule: StageModuleVariantsModel): boolean => {
        return imodule.typ === product.typ && ismodule.typ !== product.variant;
    };

    return (<ProductModules checkProduct={checkProduct} checkProductVariant={checkProductVariant} selectProductVariant={changeVariant} />);
};

export default VisualAreaModalVariantModule;
