import {ProductGroupsTypes, ProductsModel, ProjectProductsModel, ProductsVariantsModel} from "../Products";
import {getCoordinates} from "../../utils/Products";

import bathing_ladder from "../../assets/products/bathing_ladder/bathing_ladder.png";

export enum BathingLadderTypes {
    BLT_STANDARD
};
export enum SubBathingLadderTypes {
    SBLT_STANDARD
};

export interface ProjectBathingLadderModel extends ProjectProductsModel {
    group: ProductGroupsTypes;
};

let BathingLaddersShortInfoDe = '<div>';
BathingLaddersShortInfoDe += '<p>';
BathingLaddersShortInfoDe += 'Bei unseren hochwertigen Badeleitern ging es uns neben dem passenden Design vor allem um den günstigsten Einstiegswinkel ins Wasser.';
BathingLaddersShortInfoDe += '</p>';
BathingLaddersShortInfoDe += '</div>';

let BathingLaddersInfoDe = '<div>';
BathingLaddersInfoDe += '<p>';
BathingLaddersInfoDe += 'Bei unseren hochwertigen Badeleitern ging es uns neben dem passenden Design vor allem um den günstigsten Einstiegswinkel ins Wasser.';
BathingLaddersInfoDe += '</p>';
BathingLaddersInfoDe += '<p>';
BathingLaddersInfoDe += 'Außerdem war die Rutschhemmung des Stufenmaterials selbst bei nassen Stufen wichtiges Kriterium für die Entwicklung unserer Badeleiter.';
BathingLaddersInfoDe += '</p>';
BathingLaddersInfoDe += '<p>';
BathingLaddersInfoDe += 'Da wir unser Zubehör vollständig im eigenen Betrieb konstruieren und produzieren, können wir mit maximaler Flexibilität auf Ihre Anforderungen und Wünsche eingehen z. B. bei der für Sie perfekten Stufenanzahl.';
BathingLaddersInfoDe += '</p>';
BathingLaddersInfoDe += '<p>';
BathingLaddersInfoDe += 'Unser eigener Qualitätsanspruch verlangte nach einer soliden klappbaren Badeleiter-Variante. So wird Algenbewuchs auf den Stufen verhindert. ';
BathingLaddersInfoDe += '</p>';
BathingLaddersInfoDe += '</div>';

export const BathingLadders: ProductsModel[] = [
    {
        group: ProductGroupsTypes.TYP_BATHING_LADDER,
        typ: BathingLadderTypes.BLT_STANDARD,
        title: {
            de: 'Badeleiter'
        },
        info: {
            de: BathingLaddersInfoDe
        },
        width: 664,
        widthDraw: 664,
        height: 688,
        heightDraw: 688,
        grid: 125,
        rotate: 90,
        variants: [
            {
                typ: SubBathingLadderTypes.SBLT_STANDARD,
                title: {
                    de: ''
                },
                shortInfo: BathingLaddersShortInfoDe,
                imageMenu: bathing_ladder,
                imageVisual: bathing_ladder
            },
        ],
        getCoordinates: getCoordinates
    }
];

export const getBathingLadderFromProduct = (product: ProjectProductsModel): [ProductsModel, ProductsVariantsModel] | null => {
    let result: [ProductsModel, ProductsVariantsModel] | null = null;

    BathingLadders.forEach(function (slift: ProductsModel) {
        if (result !== null || slift.typ !== product.typ) {
            return;
        }
        slift.variants.forEach(function (svlift: ProductsVariantsModel) {
            if (result !== null || svlift.typ !== product.variant) {
                return;
            }
            result = [slift, svlift];
        });
    });

    return result;
};